import {
    Button,
    Checkbox,
    Collapse,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { GridFilterListIcon } from '@material-ui/data-grid';
import {
    horseCategories,
    LotGridFilterMenuItem,
    horseSexes,
    getLotsFilterOptions
} from '../../utls/dataTableHelper';

interface LotsGridFilterPanelProps {
    Catalogues: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        gridFilterPanel: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            padding: '20px',
            marginTop: '5px',
            borderRadius: '5px',
            flexGrow: 1
        },
        filterSelectControl: {
            height: '45px',
            marginBottom: '10px',
            width: '400px'
        },
        filterActionButton: {
            width: '150px',
            marginLeft: '30px'
        },
        filterPanelCloseIcon: {
            position: 'absolute',
            right: '10px',
            top: '10px'
        },
        searchIconStyle: {
            cursor: 'pointer',
            color: theme.palette.grey[800]
        },
        searchBarGrid: {
            marginTop: '30px',
            [theme.breakpoints.down('md')]: {
                margin: '30px'
            }
        }
    })
);

export const LotsGridFilerPanelComponent = (
    props: LotsGridFilterPanelProps
) => {
    const classes = useStyles();
    const { Catalogues: catalogues } = props;
    const { horses, sires, dams, vendors, locations, inFoalTos } =
        getLotsFilterOptions(catalogues);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [category, setCategory] = useState<string>('');
    const [sex, setSex] = useState<string>('');
    const [horse, setHorse] = useState<string>('');
    const [sire, setSire] = useState<string>('');
    const [dam, setDam] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [vendor, setVendor] = useState<string>('');
    const [inFoalTo, setInFoalTo] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');

    const handleFilterPanelVisibility = () => {
        setShowFilterPanel(!showFilterPanel);
    };

    const hideFilterPanel = () => {
        setShowFilterPanel(false);
    };

    const handleCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setCategory(event.target.value as string);
    };

    const handleSexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSex(event.target.value as string);
    };

    const handleHorseChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setHorse(event.target.value as string);
    };

    const handleDamChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDam(event.target.value as string);
    };

    const handleSireChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSire(event.target.value as string);
    };

    const handleLocationChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setLocation(event.target.value as string);
    };

    const handleVendorChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setVendor(event.target.value as string);
    };

    const handleInFoalToChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setInFoalTo(event.target.value as string);
    };

    const handleResetFilterOptions = () => {
        setCategory('');
        setSex('');
    };

    const searchCatalogue = () => {
        return '';
    };
    return (
        <div>
            <Grid container>
                <Grid
                    item
                    xs={4}
                    md={4}
                    className={classes.searchBarGrid}
                    justifyContent="flex-start"
                >
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={handleFilterPanelVisibility}
                        startIcon={<GridFilterListIcon />}
                    >
                        Filter
                    </Button>
                    <Select
                        value={0}
                        variant="outlined"
                        style={{
                            width: '150px',
                            marginLeft: '20px',
                            height: '40px'
                        }}
                    >
                        <MenuItem value={0}>All Sessions</MenuItem>
                    </Select>
                </Grid>
                <Grid
                    item
                    xs={8}
                    md={8}
                    className={classes.searchBarGrid}
                    justifyContent="flex-end"
                    container
                >
                    <FormGroup>
                        <FormControl>
                            <TextField
                                id="catalogue-search-field"
                                label="Search Lots"
                                variant="outlined"
                                size="small"
                                style={{ backgroundColor: '#FFFFFF' }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        searchCatalogue();
                                    }
                                }}
                                onChange={(
                                    event: React.ChangeEvent<{ value: string }>
                                ) => {
                                    setSearchText(event.target.value);
                                    searchCatalogue();
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <SearchIcon
                                            className={classes.searchIconStyle}
                                            onClick={() => searchCatalogue()}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>
            <Collapse in={showFilterPanel}>
                <div className={classes.gridFilterPanel}>
                    <IconButton
                        color="primary"
                        className={classes.filterPanelCloseIcon}
                        onClick={() => hideFilterPanel()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container direction="row">
                        <Grid item container xs={6} md={4}>
                            <Grid item container direction="row">
                                <Grid item>
                                    <FormControl variant="outlined">
                                        <InputLabel id="category-label">
                                            Category
                                        </InputLabel>
                                        <Select
                                            labelId="category-label"
                                            label="Category"
                                            value={category}
                                            onChange={handleCategoryChange}
                                            className={
                                                classes.filterSelectControl
                                            }
                                            style={{
                                                width: '185px',
                                                marginRight: '30px'
                                            }}
                                        >
                                            {horseCategories.map(
                                                (
                                                    horseCategory: LotGridFilterMenuItem
                                                ) => (
                                                    <MenuItem
                                                        value={
                                                            horseCategory.value
                                                        }
                                                    >
                                                        {horseCategory.text}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined">
                                        <InputLabel id="sex-label">
                                            Sex
                                        </InputLabel>
                                        <Select
                                            label="Sex"
                                            labelId="sex-label"
                                            value={sex}
                                            onChange={handleSexChange}
                                            className={
                                                classes.filterSelectControl
                                            }
                                            style={{ width: '185px' }}
                                        >
                                            {horseSexes.map(
                                                (
                                                    horseSex: LotGridFilterMenuItem
                                                ) => (
                                                    <MenuItem
                                                        value={horseSex.value}
                                                    >
                                                        {horseSex.text}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} direction="column">
                                <FormControl variant="outlined">
                                    <InputLabel id="horse-label">
                                        Horse
                                    </InputLabel>
                                    <Select
                                        labelId="horse-label"
                                        label="Horse"
                                        value={horse}
                                        onChange={handleHorseChange}
                                        className={classes.filterSelectControl}
                                    >
                                        {horses.map((h: string) => (
                                            <MenuItem value={h}>{h}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel id="sire-label">
                                        Sire
                                    </InputLabel>
                                    <Select
                                        label="Sire"
                                        labelId="sire-label"
                                        value={sire}
                                        onChange={handleSireChange}
                                        className={classes.filterSelectControl}
                                    >
                                        {sires.map((s: string) => (
                                            <MenuItem value={s}>{s}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel id="dam-label">Dam</InputLabel>
                                    <Select
                                        label="Dam"
                                        labelId="dam-label"
                                        value={dam}
                                        onChange={handleDamChange}
                                        className={classes.filterSelectControl}
                                    >
                                        {dams.map((d: string) => (
                                            <MenuItem value={d}>{d}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel id="sire-in-sire-line-label">
                                        Sire in sire line
                                    </InputLabel>
                                    <Select
                                        label="Sire in sire line"
                                        labelId="sire-in-sire-line-label"
                                        className={classes.filterSelectControl}
                                    >
                                        <MenuItem value="C">C</MenuItem>
                                        <MenuItem value="F">F</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel id="vendor-label">
                                        Vendor
                                    </InputLabel>
                                    <Select
                                        label="Sex"
                                        labelId="vendor-label"
                                        value={vendor}
                                        onChange={handleVendorChange}
                                        className={classes.filterSelectControl}
                                    >
                                        {vendors.map((v: string) => (
                                            <MenuItem value={v}>{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel id="location-label">
                                        Location
                                    </InputLabel>
                                    <Select
                                        label="Location"
                                        labelId="location-label"
                                        value={location}
                                        onChange={handleLocationChange}
                                        className={classes.filterSelectControl}
                                    >
                                        {locations.map((l: string) => (
                                            <MenuItem value={l}>{l}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} md={4} direction="column">
                            <FormControl variant="outlined">
                                <InputLabel id="best-performance-label">
                                    Best performance
                                </InputLabel>
                                <Select
                                    label="Best performance"
                                    labelId="best-performance-label"
                                    className={classes.filterSelectControl}
                                >
                                    <MenuItem value="Best">Best</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined">
                                <InputLabel id="dam-best-performance-label">
                                    Dam&apos;s Best Performance
                                </InputLabel>
                                <Select
                                    label="Damn's Best Performance"
                                    labelId="dam-best-performance-label"
                                    className={classes.filterSelectControl}
                                >
                                    <MenuItem value="Best">Best</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined">
                                <InputLabel id="sibling-best-performance-label">
                                    Sibling&apos;s best performance
                                </InputLabel>
                                <Select
                                    label="Sibling's best performance"
                                    labelId="sibling-best-performance-label"
                                    className={classes.filterSelectControl}
                                    value="Best"
                                >
                                    <MenuItem value="Best">Best</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="X Rays Available"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Foal at foot"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="In foal"
                                labelPlacement="end"
                            />
                            <FormControl variant="outlined">
                                <InputLabel id="in-foal-to-label">
                                    In foal to
                                </InputLabel>
                                <Select
                                    label="In foal to"
                                    labelId="in-foal-to-label"
                                    value={inFoalTo}
                                    onChange={handleInFoalToChange}
                                    className={classes.filterSelectControl}
                                >
                                    {inFoalTos.map((i: string) => (
                                        <MenuItem value={i}>{i}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container xs={12} justifyContent="flex-end">
                        <Button
                            className={classes.filterActionButton}
                            variant="outlined"
                            onClick={handleResetFilterOptions}
                        >
                            Reset
                        </Button>
                        <Button
                            className={classes.filterActionButton}
                            variant="contained"
                            color="primary"
                        >
                            Refine
                        </Button>
                    </Grid>
                </div>
            </Collapse>
        </div>
    );
};
