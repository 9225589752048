import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, TextField, MenuItem } from '@material-ui/core/';
import { PAST_SALE_YEAR_MIN } from '../../utls/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saleYearSelector: {
            width: '250px',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        saleYearForm: {
            width: '100%'
        }
    })
);

type SaleYearProps = {
    yearSales: (value: string) => void;
};

export const SaleYearSelector = (props: SaleYearProps) => {
    const { yearSales } = props;
    const classes = useStyles();
    const [selectedYear, setSelectedYear] = useState<string>(
        new Date().getFullYear().toString()
    );
    const [yearOptions, setYearOptions] = useState<any>([]);

    useEffect(() => {
        yearChoices();
    }, []);

    const selectYear = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        event.currentTarget.blur();
        setSelectedYear(event.target.value);
        yearSales(event.target.value);
    };

    const yearChoices = () => {
        const yearList = [];
        const currentYear = new Date().getFullYear();
        for (let i = PAST_SALE_YEAR_MIN; i <= currentYear; i += 1) {
            yearList.push(i);
        }
        setYearOptions(
            yearList.sort((a: any, b: any) => {
                return b - a;
            })
        );
    };

    return (
        <div id="sale-year-selector" className={classes.saleYearSelector}>
            <FormControl className={classes.saleYearForm}>
                <TextField
                    value={selectedYear}
                    label="Year"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                        selectYear(event)
                    }
                    variant="outlined"
                    select
                    size="small"
                >
                    {yearOptions.map((item: any) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </TextField>
            </FormControl>
        </div>
    );
};
