// UI CONSTANTS
export const HEADER_HEIGHT = 60;
export const FOOTER_HEIGHT = 30;
export const DRAWER_WIDTH = 250;
export const INGLIS_PHYSICAL_PEDIGREE_URL =
    'https://content.inglis.com.au/images/pedigrees/';
export const ARDEXCLOUD_IMAGE_BASEURL =
    'https://s3-ap-southeast-2.amazonaws.com/ardex.inglis.com.au/ArdexPremierCloudStorage/';
export const INGLIS_VIDEO_BASEURL = 'https://content.inglis.com.au/videos/';
export const INGLIS_IMAGE_BASEURL =
    'https://content.inglis.com.au/images/uploaded/vendors/images/';
export const DEFAULT_SALETILE_IMAGE =
    'https://s3.ap-southeast-2.amazonaws.com/iis.sydney/images/catalogues/default_cover.jpg';
export const INGLIS_SCROLL_CONST = 0.3;
export const PAST_SALE_YEAR_MIN = 2009;
export const GA_TRACKING_ID = 'G-2XYD9S4GD3';
