import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, Snackbar } from '@material-ui/core/';
import { Auth } from 'aws-amplify';
import { Amplify } from '@aws-amplify/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { globalConfig } from '../../globalConfig';

const validationSchema = yup.object({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
        .string()
        .required('New Password is required')
        // this is the current password policy in cognito
        .min(8, 'Password must be at least 8 characters long.'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Confirm Password does not match')
        .required('Confirm password is required')
});

Amplify.configure(globalConfig.amplify);

export const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            // uses aws-amplify Auth to change the password of current Authenticated user
            const user = await Auth.currentAuthenticatedUser();
            const changePasswordResult = await Auth.changePassword(
                user,
                oldPassword,
                newPassword
            );
            // TODO: handle authentication error
            // invalid old password for an example
            if (changePasswordResult === 'SUCCESS') {
                setIsPasswordChanged(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        }
    });
    return (
        <div style={{ marginTop: '30px', marginLeft: '30px' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={6} md={3} lg={2}>
                        <Typography variant="body1">Old Password</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <TextField
                            id="oldPassword"
                            name="oldPassword"
                            label="Old Password"
                            variant="outlined"
                            size="small"
                            type="password"
                            value={formik.values.oldPassword}
                            fullWidth
                            error={
                                formik.touched.oldPassword &&
                                Boolean(formik.touched.oldPassword)
                            }
                            onChange={(
                                event: React.ChangeEvent<{ value: string }>
                            ) => {
                                formik.values.oldPassword = event.target.value;
                                setOldPassword(event.target.value);
                            }}
                            helperText={
                                formik.touched.oldPassword &&
                                formik.errors.oldPassword
                            }
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ marginTop: '20px' }}
                >
                    <Grid item xs={6} md={3} lg={2}>
                        <Typography variant="body1">New Password</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <TextField
                            id="newPassword"
                            name="newPassword"
                            label="New Password"
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            value={formik.values.newPassword}
                            onChange={(
                                event: React.ChangeEvent<{ value: string }>
                            ) => {
                                formik.values.newPassword = event.target.value;
                                setNewPassword(event.target.value);
                            }}
                            error={
                                formik.touched.newPassword &&
                                Boolean(formik.touched.newPassword)
                            }
                            helperText={
                                formik.touched.newPassword &&
                                formik.errors.newPassword
                            }
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ marginTop: '20px' }}
                >
                    <Grid item xs={6} md={3} lg={2}>
                        <Typography variant="body1">
                            Confirm Password
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                            value={formik.values.confirmPassword}
                            onChange={(
                                event: React.ChangeEvent<{ value: string }>
                            ) => {
                                formik.values.confirmPassword =
                                    event.target.value;
                                setConfirmPassword(event.target.value);
                            }}
                            error={
                                formik.touched.confirmPassword &&
                                Boolean(formik.touched.confirmPassword)
                            }
                            helperText={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
                <Snackbar
                    open={isPasswordChanged}
                    message="Password has been changed successfully."
                />
            </form>
        </div>
    );
};
