import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, TextField, Button } from '@material-ui/core/';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saleOverviewContentContainer: {
            marginTop: theme.spacing(4),
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(0)
            }
        },
        overviewContentZone: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(3, 0),
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        vidFrame: {
            width: '100%',
            height: '525px',
            [theme.breakpoints.down('sm')]: {
                height: '250px'
            }
        },
        overviewTextZone: {
            paddingRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0)
            }
        },
        overviewContentRight: {
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '& div': {
                    '& p': {
                        width: '100%'
                    }
                }
            }
        },
        eventContent: {
            width: '50%',
            '& div': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '& div': {
                    '& p': {
                        width: '50%'
                    },
                    '& p:last-child': {
                        display: 'flex',
                        justifyContent: 'center'
                    }
                }
            }
        },
        lotSelection: {
            '& p': {
                width: '20%'
            }
        },
        buttonZone: {
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                order: '1',
                paddingLeft: '0px'
            }
        },
        jumpToLotArea: {
            display: 'flex',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            alignItems: 'center',
            padding: theme.spacing(2),
            borderRadius: theme.spacing(0.5),
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.grey[50]
        },
        salesOverviewLinkZones: {
            display: 'flex',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            padding: theme.spacing(2),
            borderRadius: theme.spacing(0.5),
            flexDirection: 'column',
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.grey[50],
            '& p': {
                marginBottom: theme.spacing(2)
            },
            '& button': {
                marginBottom: theme.spacing(2)
            }
        },
        lotJumpField: {
            marginLeft: theme.spacing(2),
            width: '80px'
        }
    })
);

export const SaleOverviewContent = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.saleOverviewContentContainer}>
            <Grid item xs={12} md={8} className={classes.overviewTextZone}>
                <iframe
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/592548884?h=2946ce31aa"
                    frameBorder="0"
                    allowFullScreen
                    className={classes.vidFrame}
                />
                <div className={classes.overviewContentZone}>
                    <Typography variant="h3" color="secondary">
                        Sessions
                    </Typography>
                    <div className={classes.eventContent}>
                        <div>
                            <Typography variant="body1">
                                10am, Day 1, Select Wearlings, 4th July 2021
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: 600,
                                    textDecoration: 'underline'
                                }}
                                color="primary"
                            >
                                Lots 1-280
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.overviewContentZone}>
                    <Typography variant="h3" color="secondary">
                        COVID Update
                    </Typography>
                    <div className={classes.overviewContentRight}>
                        <Typography variant="body1">
                            The Inglis Great Southern Sale at Oaklands,
                            Melbourne on Sunday July 4 and Monday July 5, will
                            proceed as a live auction as scheduled. Travel
                            restrictions are in place, only those with a
                            Victoria Border Entry Permit or a Specific Worker
                            Permit are eligible to enter Victoria from affected
                            areas. Given the fluidity of the situation, all
                            Great Southern Sale participants are urged to keep
                            up to date on the current travel status via the
                            Victorian State Government website. For your
                            convenience please refer to Interstate Border and
                            Travel Restricitons.
                        </Typography>
                    </div>
                </div>
                <div className={classes.overviewContentZone}>
                    <Typography variant="h3" color="secondary">
                        About the sale
                    </Typography>
                    <div className={classes.overviewContentRight}>
                        <Typography variant="body1">
                            Following a record-breaking round of sales in 2021,
                            entries are now open for the 2022 Inglis Yearling
                            Sales Series. The series will again consist of five
                            primary yearling sales – Classic, Premier, Easter,
                            Gold and HTBA May. A total of 2188 yearlings were
                            sold through an Inglis sale ring in 2021 at a
                            market-leading clearance rate of 88.8% (4.1% higher
                            than the nearest competitor). In a series of telling
                            statistics: Inglis-sold yearlings averaged $125,850
                            in 2021, 8.8% more than yearlings sold by the
                            nearest Australian competitor There were 24 lots
                            sell for $1m or more through Inglis in 2021, double
                            that of the nearest Australian competitor Inglis
                            sold 95 yearlings for $500,000 or more and 846
                            yearlings for $100,000 or more The growth of Inglis
                            auctions in 2021 was remarkable, with the gross at
                            Classic (+23.5%) and Premier (+25.9%) in particular
                            showing staggering growth on the 2020 renewals,
                            despite a slight decrease in the number of lots
                            offered. Despite international - and at-times
                            domestic - border closures and regular travel
                            interruptions, the buying bench at Inglis sales came
                            from every Australian State and Territory as well as
                            the likes of Hong Kong, New Zealand, China, Japan,
                            USA, Europe, Singapore, Malaysia, Thailand etc in
                            2021. Inglis’ General Manager of Bloodstock Sales
                            and Marketing Sebastian Hutch is already looking
                            forward to the 2022 Yearling Sales Series. “It has
                            been a challenging 12 months in terms of the hurdles
                            posed by the pandemic, but we were determined to put
                            together the best markets that we possibly could at
                            each of our sales and so it has been extremely
                            satisfying seeing so many of our clients enjoy such
                            wonderful results,’’ Hutch said. “We pride ourselves
                            on offering the highest standard of service across
                            all of our sales and while the results this year
                            have been fantastic, we are excited at the prospect
                            of improving further in 2022. “Breeders and vendors
                            can have confidence in our settled sales structure,
                            with auctions conducted in excellent facilities at
                            Riverside and Oaklands which provide each and every
                            horse a fair opportunity to showcase its quality.
                            “We work exceptionally hard right throughout the
                            year to maintain existing relationships with buyers
                            or develop new clients and it is reflected in the
                            ratio of buyers to horses sold at each of our sales,
                            which is unmatched. “We are grateful for the
                            opportunities afforded to us by breeders and
                            vendors, which is not something we take lightly,
                            hence our absolute determination to work to achieve
                            the best results for all of our vendors.” To discuss
                            the sales placement of your yearlings for 2022,
                            contact a member of the Inglis bloodstock team in
                            Sydney on 02 9399 7999 or Melbourne on 03 9333 1422.
                        </Typography>
                    </div>
                </div>
                <div className={classes.overviewContentZone}>
                    <Typography variant="h3" color="secondary">
                        Calendar of events
                    </Typography>
                    <div className={classes.eventContent}>
                        <div>
                            <Typography variant="body1">10 Sep 2021</Typography>
                            <Typography variant="body1">
                                Vic Breeze Up (Syemour Synthetic)
                            </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.buttonZone}>
                <div>
                    <div
                        className={`${classes.jumpToLotArea}  ${classes.lotSelection}`}
                    >
                        <Typography>Jump To Lot</Typography>
                        <TextField
                            className={classes.lotJumpField}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                </div>
                <div className={classes.salesOverviewLinkZones}>
                    <Typography variant="body2">
                        Sale Links - Open for entries
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Bid Now
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Download entry form
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Appointment of agent
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Upload videos and photos
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        New buyer form
                    </Button>
                </div>
                <div className={classes.salesOverviewLinkZones}>
                    <Typography variant="body2">
                        Sale Links - Current
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Bid Live
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Watch live
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Appointment of agent
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Upload videos and photos
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        New buyer form
                    </Button>
                </div>
                <div className={classes.salesOverviewLinkZones}>
                    <Typography variant="body2">Bus schedules</Typography>
                    <Button variant="outlined" color="primary" disableElevation>
                        Promenade schedule
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Crown Plaza schedule
                    </Button>
                    <Button variant="outlined" color="primary" disableElevation>
                        Quay West schedule
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};
