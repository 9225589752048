import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Grid, Typography } from '@material-ui/core';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: 'auto',
            maxHeight: '640px',
            margin: '0 auto',
            paddingTop: '48px',
            backgroundColor: theme.palette.grey[500],
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        main: {
            height: 'auto',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '12px',
                paddingRight: '12px'
            }
        },
        imageGallery: {
            backgroundColor: '#F7F7F7',
            width: '100%',
            maxWidth: '1920px',
            cursor: 'pointer',
            position: 'relative',
            objectFit: 'cover',
            backgroundSize: 'cover',
            margin: '0 auto',
            '& img.image-gallery-image': {
                height: '560px',
                objectFit: 'cover',
                objectPosition: 'top'
            },
            zIndex: 1,
            marginTop: '-164',
            '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg':
                {
                    height: '60px !important'
                },
            left: '50%',
            transform: 'translate(-50%, 0)',
            [theme.breakpoints.down('sm')]: {
                transform: 'translate(-50%, 0)',
                '& img.image-gallery-image': {
                    height: 'auto'
                }
            }
        },
        box: {
            position: 'relative',
            zIndex: 2,
            height: '130px',
            width: '600px',
            display: 'table',
            left: '50%',
            transform: 'translate(-50%, -180px)',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '124px',
                transform: 'translate(-50%, 0)',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginBottom: '48px'
            }
        },
        title: {
            color: theme.palette.grey[700],
            marginLeft: '16px',
            marginTop: '16px'
        },
        horseName: {
            color: theme.palette.secondary.main,
            marginLeft: '16px',
            marginTop: '0px'
        },
        link: {
            marginLeft: '16px',
            marginTop: '8px',
            color: theme.palette.grey[700],
            fontWeight: 400,
            '& a': {
                color: theme.palette.primary.main
            }
        },
        margin: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    })
);

type WinnerData = {
    image: string;
    order: number;
    groupText: string;
    altText: string;
    horseName: string;
    raceLinkHTML: string;
};

export const GroupWinners = () => {
    const classes = useStyles();
    const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
    const [data, setData] = useState<[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const getWinners = () => {
            try {
                API.get('cms-api', `get-group-race-winners?limit=3`, {
                    response: true,
                    responseType: 'text'
                })
                    .then((response) => {
                        const responseData = response.data.data.sort(
                            (a: WinnerData, b: WinnerData) => {
                                return a.order > b.order ? 1 : -1;
                            }
                        );
                        const imagesList = responseData.map((r: WinnerData) => {
                            return {
                                original: r.image,
                                originalAlt: r.altText
                            };
                        });
                        setImages(imagesList);
                        setData(responseData);
                    })
                    .catch((error) => {
                        console.log(
                            'error in getting homepage group 1 winners list from CMS',
                            error
                        );
                    });
            } catch (error) {
                console.log(
                    'error in getting homepage group 1 winners list from CMS',
                    error
                );
            }
        };

        getWinners();
    }, []);

    const onSlideHandler = (index: number) => {
        setCurrentIndex(index);
    };

    const current = data ? (data[currentIndex] as WinnerData) : null;

    return images && images.length > 0 ? (
        <Grid
            data-testid="groupWinner-images"
            container
            className={classes.container}
        >
            <Grid xs={1} md={2} className={classes.margin} />
            <Grid xs={12} md={8} className={classes.main}>
                <div className={classes.imageGallery}>
                    {images && (
                        <ImageGallery
                            items={images}
                            showBullets
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showThumbnails={false}
                            useBrowserFullscreen={false}
                            onBeforeSlide={onSlideHandler}
                        />
                    )}
                </div>
                {current && (
                    <div className={classes.box} data-testid="linkbox">
                        <Typography
                            className={classes.title}
                            variant="body1"
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {current.groupText}
                        </Typography>
                        <Typography
                            className={classes.horseName}
                            variant="h2"
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {current.horseName}
                        </Typography>
                        {current.raceLinkHTML && (
                            <Typography
                                className={classes.link}
                                variant="body1"
                                style={{
                                    fontWeight: 600
                                }}
                            >
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: current.raceLinkHTML
                                    }}
                                />
                            </Typography>
                        )}
                    </div>
                )}
            </Grid>
            <Grid xs={1} md={2} className={classes.margin} />
        </Grid>
    ) : (
        <div data-testid="no-image" />
    );
};
