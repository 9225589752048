import {
    Button,
    createStyles,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import { ausDollarFormatter } from '../../utls/formatHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeaderHeading: {
            color: theme.palette.secondary.main,
            marginBottom: '10px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px',
                paddingLeft: theme.spacing(2)
            }
        },
        biddingLimitContainer: {
            padding: '20px',
            backgroundColor: theme.palette.grey[50]
        },
        biddingLimitValues: {
            marginTop: '15px',
            '& tr': {
                '& td:last-child': {
                    textAlign: 'right'
                }
            }
        },
        lineBreak: {
            borderTop: `1px solid ${theme.palette.grey[100]}`
        }
    })
);

interface BiddingLimitDashboardComponentProps {
    bidLimitBar: number;
    currentFundsAvailable: number;
    userBiddingLimit: any;
    goToBiddingRequestApproval: ()=> void;
}

export const BiddingLimitDashboardComponent = (
    props: BiddingLimitDashboardComponentProps
) => {
    const classes = useStyles();
    const {
        bidLimitBar,
        currentFundsAvailable,
        userBiddingLimit,
        goToBiddingRequestApproval
    } = props;
    return (
        <div>
            <Typography variant="h3" className={classes.subHeaderHeading}>
                Bidding Limit
            </Typography>
            <div className={classes.biddingLimitContainer}>
                <LinearProgress
                    variant="determinate"
                    value={bidLimitBar}
                    style={{ height: '8px', borderRadius: '4px' }}
                />
                <table className={classes.biddingLimitValues}>
                    <tr>
                        <td>
                            <Typography
                                variant="body2"
                                style={{ fontSize: '20px' }}
                            >
                                Available
                            </Typography>
                        </td>
                        <td>
                            <Typography
                                variant="body2"
                                style={{ fontSize: '20px' }}
                            >
                                {ausDollarFormatter(currentFundsAvailable)}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="body1">Committed</Typography>
                        </td>
                        <td>
                            <Typography variant="body1">
                                {ausDollarFormatter(userBiddingLimit.commited)}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="body1">Spent</Typography>
                        </td>
                        <td>
                            <Typography variant="body1">
                                {ausDollarFormatter(userBiddingLimit.spent)}
                            </Typography>
                        </td>
                    </tr>
                </table>
                <hr className={classes.lineBreak} />
                <table className={classes.biddingLimitValues}>
                    <tr>
                        <td>
                            <Typography variant="body1">
                                Current Bidding Limit
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="body1">
                                {ausDollarFormatter(
                                    userBiddingLimit.credit_limit
                                )}
                            </Typography>
                        </td>
                    </tr>
                </table>
                <Button
                    style={{ marginTop: '15px' }}
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        goToBiddingRequestApproval();
                    }}
                >
                    Increase Bidding Limit
                </Button>
            </div>
        </div>
    );
};
