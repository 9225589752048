import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Popover, Typography } from '@material-ui/core';

import { getLotStatusQuery, getBidIncrement } from '../../queries/gqlQueries';
import { LotBiddingInfo, BidIncrement } from '../../types/inglisTypes';
import { BidNowPopUp } from './bidPopUp';

type BidPopProps = {
    saleId: string;
    saleCode: string;
    horseName: string;
    handleMutatedData: (data: any) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bidPopZone: {
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        },
        bidNowButton: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        bidNowText: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        bidPopOver: {
            '& .MuiPopover-paper': {
                width: '345px !important'
            }
        },
        popAreaTop: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            margin: '25px 0px'
        },
        popAreaBottom: {
            display: 'flex',
            justifyContent: 'center',
            margin: '25px 0',
            '& button': {
                width: '85%'
            }
        }
    })
);

export const BidNowPopOver = (props: BidPopProps) => {
    const history = useHistory();
    const [bidNowAnchor, setBidNowAnchorEl] =
        useState<HTMLButtonElement | null>(null);
    const [addedHorseData, setAddedHorsedata] = useState<LotBiddingInfo[]>([]);
    const [lotBidIncrement, setBidIncrement] = useState<BidIncrement[]>([]);
    const { saleId, saleCode, horseName, handleMutatedData } = props;
    const classes = useStyles();

    const handlePopLaunch = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBidNowAnchorEl(event.currentTarget);
    };

    const handlePopClose = () => {
        setBidNowAnchorEl(null);
    };

    const goToDashboard = () => {
        history.push('/dashboard');
    };

    useQuery(getBidIncrement(), {
        onCompleted: (data) => {
            const result = data?.getIncrements;
            setBidIncrement(result);
        }
    });

    useQuery(getLotStatusQuery(saleCode, saleId), {
        onCompleted: (data) => {
            const result = data?.getBiddingInfo;
            if (result) {
                const lotAddedData = result.filter((i: any) => {
                    return i.lot_no === `${saleId}`;
                });
                setAddedHorsedata(lotAddedData);
            }
        }
    });

    const open = Boolean(bidNowAnchor);
    const bidNowButtonPopId = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.bidPopZone}>
            <Button
                aria-describedby={bidNowButtonPopId}
                onClick={handlePopLaunch}
                variant="outlined"
                color="primary"
                disableElevation
                className={classes.bidNowButton}
            >
                Bid Now
            </Button>
            <Typography
                aria-describedby={bidNowButtonPopId}
                variant="body1"
                style={{
                    fontWeight: 600
                }}
                onClick={handlePopLaunch}
                color="primary"
                className={classes.bidNowText}
            >
                Bid Now
            </Typography>
            <Popover
                id={bidNowButtonPopId}
                open={open}
                anchorEl={bidNowAnchor}
                onClose={handlePopClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                className={classes.bidPopOver}
            >
                <BidNowPopUp
                    horseData={addedHorseData}
                    saleId={saleId}
                    saleCode={saleCode}
                    horseName={horseName}
                    bidIncrementValues={lotBidIncrement}
                    handleDataMutation={handleMutatedData}
                    handlePopClose={handlePopClose}
                    goToDashboard={goToDashboard}
                />
            </Popover>
        </div>
    );
};
