import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography, IconButton } from '@material-ui/core/';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import { DEFAULT_SALETILE_IMAGE } from '../../utls/constants';
import {
    saleUrlFormatter,
    saleOverviewUrlFormatter
} from '../../utls/formatHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saleTile: {
            display: 'flex',
            flexDirection: 'column',
            width: '232px',
            height: '308px',
            justifyContent: 'space-between',
            border: `1px solid ${theme.palette.grey[600]}`,
            borderRadius: '4px',
            padding: theme.spacing(3),
            backgroundColor: theme.palette.grey[50],
            marginRight: theme.spacing(2),
            'flex-shrink': '0',
            position: 'relative'
        },
        digitalLabel: {
            position: 'absolute',
            left: '28%',
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.grey[50],
            padding: theme.spacing(0, 2),
            width: '90px',
            textAlign: 'center',
            borderRadius: theme.spacing(3),
            border: `1px solid ${theme.palette.grey[50]}`,
            top: '10px',
            lineHeight: 1
        },
        saleTileIcon: {
            height: '18px'
        },
        firstTextZone: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            '& img': {
                margin: 'auto'
            },
            '& p': {
                marginTop: theme.spacing(2)
            }
        },
        secondaryTextZone: {
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[600]}`,
            borderBottom: `1px solid ${theme.palette.grey[600]}`,
            padding: theme.spacing(1, 0)
        },
        saleTileSecondaryText: {
            display: 'flex',
            alignItems: 'center',
            color: '#656562',
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap'
            }
        }
    })
);

type CatalogueTileProps = {
    saleCode: string;
    saleTitle: string;
    saleStartDate: string;
    saleEndDate: string;
    saleLocation: string;
    saleImage: string;
    tileType: string;
};

export const CatalogueTiles = (props: CatalogueTileProps) => {
    const {
        saleCode,
        saleTitle,
        saleStartDate,
        saleEndDate,
        saleLocation,
        saleImage,
        tileType
    } = props;
    const classes = useStyles();
    const history = useHistory();

    /**
     * Redirects users to the Lots tab in the Sales Overview Page upon clicking the button.
     * saleUrlFormatter is responsible for this
     */
    const salesRedirect = () => {
        const startDate = new Date(saleStartDate);
        const endDate = new Date(saleEndDate);
        const saleUrl =
            saleLocation === 'Inglis Digital'
                ? saleUrlFormatter(
                      saleCode,
                      saleTitle,
                      startDate,
                      endDate,
                      'digital'
                  )
                : saleUrlFormatter(saleCode, saleTitle, startDate, endDate);
        history.push(saleUrl);
    };

    /**
     * Redirects users to the Overview tab in the Sales Overview Page upon clicking the
     * catalogue image. saleOverviewUrlFormatter is responsible for this
     */
    const catImageRedirect = () => {
        const saleOverviewUrl =
            saleLocation === 'Inglis Digital'
                ? saleOverviewUrlFormatter(saleCode, saleTitle, 'digital')
                : saleOverviewUrlFormatter(saleCode, saleTitle);
        history.push(saleOverviewUrl);
    };

    return (
        <div id="sale-tile" className={classes.saleTile}>
            {saleLocation === 'Inglis Digital' ? (
                <div className={classes.digitalLabel}>
                    <Typography variant="body1">Digital</Typography>
                </div>
            ) : (
                ''
            )}
            <div className={classes.firstTextZone}>
                <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    style={{ backgroundColor: 'transparent' }}
                    onClick={catImageRedirect}
                >
                    <div>
                        {saleImage ? (
                            <img
                                src={saleImage}
                                alt="Sales Poster"
                                height="150px"
                                width="104px"
                            />
                        ) : (
                            <img
                                src={DEFAULT_SALETILE_IMAGE}
                                alt="Sales Poster"
                                height="150px"
                                width="104px"
                            />
                        )}
                    </div>
                </IconButton>
                <Typography variant="body2">{saleTitle}</Typography>
            </div>
            <div className={classes.secondaryTextZone}>
                <Typography
                    className={classes.saleTileSecondaryText}
                    variant="body1"
                >
                    <EventIcon className={classes.saleTileIcon} />
                    {moment(saleStartDate).format('DD MMM YYYY')}
                </Typography>
                <Typography
                    className={classes.saleTileSecondaryText}
                    variant="body1"
                >
                    <LocationOnOutlinedIcon className={classes.saleTileIcon} />
                    {saleLocation}
                </Typography>
            </div>
            {tileType === 'CurrentUpcoming' ? (
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={salesRedirect}
                >
                    View Catalogue
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={salesRedirect}
                >
                    View Results
                </Button>
            )}
        </div>
    );
};
