import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Switch, Typography } from '@material-ui/core/';
import { API } from 'aws-amplify';
import { CatalogueTiles } from '../contentComponents/catalogueTiles';
import { SaleYearSelector } from '../contentComponents/saleYearSelector';
import { PageBanner } from '../helperComponents/pageBanner';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pastSalesContainer: {
            padding: theme.spacing(0, 5),
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 2)
            }
        },
        saleControls: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: theme.spacing(15, 0, 2, 0),
            [theme.breakpoints.down('md')]: {
                'flex-direction': 'column',
                margin: theme.spacing(12, 0, 2, 0)
            }
        },
        digitalSwitcher: {
            display: 'flex',
            alignItems: 'center'
        },
        pastSalesGrid: {
            '& #sale-tile': {
                marginBottom: theme.spacing(3),
                [theme.breakpoints.down('md')]: {
                    margin: `${theme.spacing(2)}px auto`
                }
            }
        }
    })
);

export const PastSalesPage = () => {
    const [pastSales, setPastSales] = useState<any>([]);
    const [filteredSales, setFilteredSales] = useState<any>([]);
    const [switchSales, setSalesSwitch] = useState<boolean>(true);
    const classes = useStyles();
    const siteState = sessionStorage.getItem('siteState');
    useEffect(() => {
        getPastSales(new Date().getFullYear().toString());
    }, []);

    const getPastSales = (year: string) => {
        const today = new Date();
        API.get('rest-api', `/catalogue/years/${year}`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                const sortedResponse = response.data
                    .filter((sale: any) => {
                        return new Date(sale.saleEndDate) < today;
                }).sort((a: any, b: any) => {
                    return b.biddingCloses.localeCompare(a.biddingCloses);
                });
                if (siteState === 'inglis') {
                    setPastSales(sortedResponse);
                    setFilteredSales(sortedResponse);
                } else {
                    const digitalPastSales = sortedResponse.filter((i: any) => {
                        return i.saleVenue === 'Inglis Digital';
                    });
                    setPastSales(digitalPastSales);
                    setFilteredSales(digitalPastSales);
                }
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    alert('Cannot find current catalogue');
                }
            });
    };

    // Filters sales when using the digital switcher
    const filterSales = () => {
        setSalesSwitch(!switchSales);
        if (switchSales) {
            const digitalSales = pastSales.filter((i: any) => {
                return i.saleVenue !== 'Inglis Digital';
            });
            setFilteredSales(digitalSales);
        } else {
            setFilteredSales(pastSales);
        }
    };

    return (
        <div className={classes.pastSalesContainer}>
            <Grid container>
                <Grid item xs={12}>
                    <PageBanner titleText="Past Sales" />
                </Grid>
                <Grid item xs={12} className={classes.saleControls}>
                    <SaleYearSelector yearSales={getPastSales} />
                    {siteState === 'inglis' ? (
                        <div className={classes.digitalSwitcher}>
                            <Typography variant="body1">
                                Show Digital Sales
                            </Typography>

                            <Switch
                                checked={switchSales}
                                onChange={filterSales}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
            </Grid>
            <Grid container>
                {filteredSales.length > 0 ? (
                    filteredSales.map((item: any) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                className={classes.pastSalesGrid}
                            >
                                <CatalogueTiles
                                    saleCode={item.saleCode}
                                    saleTitle={item.saleName}
                                    saleStartDate={item.saleStartDate}
                                    saleEndDate={item.saleEndDate}
                                    saleLocation={item.saleVenue}
                                    saleImage={item.catalogueImage}
                                    tileType="PastSale"
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        className={classes.pastSalesGrid}
                    >
                        <Typography variant="body2">
                            No Results available. Please try a different year.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
