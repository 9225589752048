import { gql } from '@apollo/client';

export const getSaleLotStatusesQuery = (params: string) => gql`
    query getSaleLotStatusesQuery {
        getBiddingInfo(sale_code: "${params}") {
            sale_code
            lot_no
            lot_reserve_met
            lot_status
            lot_current_bid
            lot_bid_count
            lot_closing_time
            bidder_result
        }
    }
`;

export const getMySaleLotStatusesQueery = (saleCode: string, debtorId: string) => gql `
    query getMySaleLotStatusesQuery {
        getMyBiddingInfo(sale_code: "${saleCode}", debtor_id: "${debtorId}"){
            sale_code
            lot_no
            lot_reserve_met
            lot_status
            lot_current_bid
            lot_bid_count
            lot_closing_time
            bidder_result
        }
    }
`;

export const getSaleLotsPublicStatusesQuery = (params: string)=> gql`
    query getSaleLotPublicStatusesQuery {
        getBiddingPublicInfo(sale_code: "${params}") {
            sale_code
            lot_no
            lot_closing_time
        }
    }
`;

export const getLotStatusQuery = (saleCode: string, lotNumber?: string) => gql`
    query getLotStatusesQuery {
        getBiddingInfo(sale_code: "${saleCode}", lot_no: "${lotNumber}") {
            sale_code
            lot_no
            lot_reserve_met
            lot_status
            lot_current_bid
            lot_bid_count
            lot_closing_time
            bidder_result
        }
    }
`;

export const getBidIncrement = () => gql`
    query getBidIncrement {
        getIncrements {
            increment
            from
        }
    }
`;

export const getBiddingLimit = (saleCode: string) => gql`
    query getBidLimit {
        getBiddingLimit(sale_code: "${saleCode}") {
            bidding_approved
            commited
            credit_limit
            sale_code
            spent
        }
    }
`;

export const placeBidMutation = (
    bidAmount: number,
    lotNumber: string,
    saleCode: string
) => gql`
    mutation placeBidMutation {
        placeBid( bid_amount: ${bidAmount}, lot_no: "${lotNumber}", sale_code: "${saleCode}") {
            lot_bid_count
            lot_bidders
            lot_closing_time
            lot_current_bid
            lot_leading_bidder
            lot_no
            lot_reserve_met
            lot_status
            sale_code
        }
    }
`;

export const onBidPlacedQuery = (saleCode: string, lotNumber?: string) => gql`
    subscription onBidPlacedQuery {
        onBidPlaced(sale_code: "${saleCode}", lot_no: "${lotNumber}") {
            sale_code
            lot_no
            lot_reserve_met
            lot_status
            lot_current_bid
            lot_bid_count
            lot_closing_time
            lot_leading_bidder
            lot_bidders
        }
    }
`;
