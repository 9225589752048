import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        openEntriesBar: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.grey[50],
            height: '113px',
            padding: '0px 20px',
            alignItems: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: 'auto',
                padding: '15px 20px',
                '& button': {
                    width: '100%'
                }
            }
        },
        enterHorseButton: {
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        openForEntriesTitleText: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginBottom: '15px',
                justifyContent: 'flex-start'
            }
        },
        entriesDate: {
            color: theme.palette.secondary.light
        }
    })
);
export const OpenForEntryDashboardComponent = (props: any) => {
    const classes = useStyles();
    const saleOpenForEntry = props;
    return (
        <div className={classes.openEntriesBar}>
            <div className={classes.openForEntriesTitleText}>
                <img
                    src="https://content.inglis.com.au/images/uploaded/news/medium/1630194048.fb0db70f92.jpg"
                    alt="Sales Poster"
                    width="53px"
                    height="76px"
                />
                <div style={{ marginLeft: '20px' }}>
                    <Typography variant="body2">
                        {saleOpenForEntry.name}
                    </Typography>
                    <Typography variant="body1">
                        {`${moment(saleOpenForEntry.startDate).format('DD')} - ${moment(
                            saleOpenForEntry.endDate
                        ).format('DD MMM YYYY')}`}
                        {`, ${saleOpenForEntry.saleVenue}` }
                    </Typography>
                    <Typography variant="body2" className={classes.entriesDate}>
                        Entries Close{' '}
                        {moment(saleOpenForEntry.closingDate).format(
                            'DD MMM YYYY'
                        )}
                    </Typography>
                </div>
            </div>
            <Button
                variant="outlined"
                color="primary"
                disableElevation
                className={classes.enterHorseButton}
            >
                Enter Horse
            </Button>
        </div>
    );
};
