import React, { useState, useEffect } from 'react';
import {
    Typography,
    alpha} from '@material-ui/core/';
import {
    DataGrid,
    GridRowData,
    GridSortModel,
    GridSortDirection,
    GridCellParams,
    GridValueGetterParams,
    GridColDef,
} from '@material-ui/data-grid';
import _ from 'lodash';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as HorsePlaceHolder } from '../../icons/horsePlaceholder.svg';
import { ARDEXCLOUD_IMAGE_BASEURL } from '../../utls/constants';
import { getSaleLotsColumns } from '../../utls/dataTableHelper';
import { ausDollarFormatter } from '../../utls/formatHelper';
import { LotsGridFilerPanelComponent } from '../helperComponents/lotsGridFilterPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiDataGrid-root': {
                border: 'none',
                borderTop: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer'
            },
            '& .MuiDataGrid-columnsContainer': {
                borderBottom: `1px solid ${theme.palette.grey[600]}`
            },
            '& .catalogue-header': {
                fontSize: '14px',
                padding: '0px',
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontFamily: 'MetropolisRegular',
                    fontWeight: 700,
                    color: theme.palette.secondary.main
                }
            },
            '& .catalogue-table-rows': {
                fontSize: '14px',
                color: theme.palette.grey[900],
                borderBottom: `1px solid ${alpha(
                    theme.palette.grey[600],
                    0.4
                )}`,
                '& .MuiDataGrid-cell': {
                    border: 'none',
                    textAlign: 'left',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 400
                }
            },
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        searchIconStyle: {
            cursor: 'pointer',
            color: theme.palette.grey[800]
        },
        searchBarGrid: {
            marginTop: '30px',
            [theme.breakpoints.down('md')]: {
                margin: '30px'
            }
        },
        defaultHorseThumbnail: {
            height: '40px',
            width: '40px',
            margin: 'auto',
            '& svg': {
                height: '40px',
                width: '40px'
            }
        },
        mobileCatalogue: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                backgroundColor: theme.palette.grey[50],
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                marginBottom: '0px',
                paddingTop: theme.spacing(2),
                paddingBottom: '15px',
                justifyContent: 'space-evenly',
                fontSize: '14px',
                '& div': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }
            }
        },
        mobilePaginator: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                justifyContent: 'space-evenly',
                height: '56px',
                alignItems: 'center',
                position: 'sticky',
                bottom: '0',
                'z-index': '999',
                backgroundColor: theme.palette.grey[50],
                width: '100%',
                '& div': {
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            }
        },
        mobilePaginatorShadow: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                width: '100%',
                bottom: '58px',
                position: 'sticky',
                boxShadow: '0px 0.5px 15px 1px rgb(0 0 0 / 50%)'
            }
        },
        mobilePaginatorCover: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                width: '100%',
                height: '20px',
                position: 'absolute',
                background: 'white',
                bottom: '688px',
                zIndex: 10,
                backgroundColor: theme.palette.grey[50]
            }
        },
        genderGridCellFormat: {
            backgroundColor: '#FFFFFF',
            border: '1px solid',
            borderColor: theme.palette.grey[600],
            padding: '2px 8px 2px 8px'
        }
    })
);

type CatalogueProps = {
    catalogueResults: any;
    saleCode: string;
};

type MobileCatalogueProps = {
    catalogueResults: any;
    saleCode: string;
    pageSize: number;
    currentPage: number;
};

export const MainCataloguePage = (props: CatalogueProps) => {
    const { catalogueResults, saleCode } = props;
    useEffect(() => {
        sessionStorage.setItem('catalogueLength', catalogueResults.length);
    });

    return catalogueResults.length > 0 ? (
        <CataloguePage
            catalogueResults={catalogueResults}
            saleCode={saleCode}
        />
    ) : (
        <div />
    );
};

const CataloguePage = (props: CatalogueProps) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { catalogueResults, saleCode } = props;
    const [updatedHorseCollection, updateHorseCollection] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [pageLimit, setPageLimit] = useState<number>(100);
    const [pageStart, setPageStart] = useState<number>(0);
    const [pageEnd, setPageEnd] = useState<number>(100);
    const [selectedUserPage, setUserSelectedPage] = useState<number>(0);
    const [selectedUserPageSize, setUserSelectedPageSize] =
        useState<number>(100);
    const [selectedSortBy, setSelectedSortBy] = useState<string>('');
    const [selectedSortOrder, setSelectedSortOrder] = useState<string>('');
    const [urlSearchParams, setSearchParams] = useState<URLSearchParams>(
        new URLSearchParams(location.search)
    );
    const [lotSortModel, setLotSortModel] = useState<GridSortModel>([]);


    const allColumns = getSaleLotsColumns();
    const columns = allColumns.map((col: GridColDef) => {
        if (col.field === '') {
            return {
                ...col,
                renderHeader: () => {
                    return <FavoriteBorderIcon color="primary" />;
                },
                renderCell: () => {
                    return <FavoriteBorderIcon color="primary" />;
                }
            };
        }
        if (col.field === 'images') {
            return {
                ...col,
                renderCell: (params: GridCellParams) => {
                    const horseImageCollection: any = params;
                    let horseThumbnail: any = (
                        <div className={classes.defaultHorseThumbnail}>
                            <HorsePlaceHolder />
                        </div>
                    );
                    if (horseImageCollection.value) {
                        if (horseImageCollection.value.length > 0) {
                            horseThumbnail = (
                                <div
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        margin: 'auto',
                                        backgroundImage: `url("${ARDEXCLOUD_IMAGE_BASEURL}${horseImageCollection.value[0].objectName}")`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />
                            );
                        }
                    }
                    return horseThumbnail;
                }
            };
        }
        if (col.field === 'sex') {
            return {
                ...col,
                renderCell: (params: GridCellParams) => {
                    const { value } = params;
                    return (
                        <Typography className={classes.genderGridCellFormat}>
                            {value}
                        </Typography>
                    );
                }
            };
        }
        if (col.field === 'purchaser') {
            return {
                ...col,
                valueGetter: (params: GridValueGetterParams) => {
                    const idValue: any = params;
                    let colValue = idValue.value;
                    if (colValue === undefined && idValue.row.price > 0) {
                        colValue = `Passed In - Reserve ${ausDollarFormatter(
                            idValue.row.price
                        )}`;
                    }
                    return colValue;
                }
            };
        }
        if (col.field === 'purchaserLocation') {
            return {
                ...col,
                valueGetter: (params: GridValueGetterParams) => {
                    const idValue: any = params;
                    let colValue = '';
                    if (idValue.row.purchaserCountry) {
                        colValue = idValue.row.purchaserCountry;
                    } else if (idValue.row.purchaserState) {
                        colValue = idValue.row.purchaserState;
                    }
                    return colValue;
                }
            };
        }
        if (col.field === 'price') {
            return {
                ...col,
                renderCell: (params: GridCellParams) => {
                    if (!params.value) {
                        return '';
                    }
                    if (params.value === 0) {
                        return (
                            <Typography variant="body1" color="error">
                                Withdrawn
                            </Typography>
                        );
                    }
                    let valueFormatted = null;
                    if (
                        params.row.purchaser === undefined &&
                        params.row.price > 0
                    ) {
                        valueFormatted = '';
                    } else {
                        valueFormatted = (
                            <Typography variant="body1">
                                {ausDollarFormatter(
                                    Number(params.value)
                                ).toString()}
                            </Typography>
                        );

                        ausDollarFormatter(Number(params.value)).toString();
                    }
                    return valueFormatted;
                }
            };
        }
        return col;
    });

    useEffect(() => {
        const currentCatalogueSaleCode =
            sessionStorage.getItem('currentSaleCode');
        if (location.search) {
            const selectedPage =
                parseInt(urlSearchParams!.get('currentpage')!, 10) - 1;
            const selectedPageSize = parseInt(
                urlSearchParams!.get('pagesize')!,
                10
            );
            const sortedBy = urlSearchParams!.get('sortby')!;
            const sortedOrder = urlSearchParams!.get('sortorder')!;
            setUserSelectedPage(selectedPage);
            setUserSelectedPageSize(selectedPageSize);
            setPageLimit(selectedPageSize);
            setPageStart(selectedPage * selectedPageSize);
            if (selectedPage > 0) {
                setPageEnd(selectedPage * selectedPageSize + selectedPageSize);
            } else {
                setPageEnd(selectedPage + selectedPageSize);
            }
            if (sortedBy && sortedOrder) {
                setSelectedSortBy(sortedBy);
                setSelectedSortOrder(sortedOrder);
                sessionStorage.setItem(
                    'digitalCatalogueSelectedSortBy',
                    sortedBy
                );
                sessionStorage.setItem(
                    'digitalCatalogueSelectedSortOrder',
                    sortedOrder
                );
                setLotSortModel([
                    {
                        field: sortedBy,
                        sort: sortedOrder as GridSortDirection
                    }
                ]);
            }
            sessionStorage.setItem(
                'catalogueSelectedPage',
                selectedPage.toString()
            );
            sessionStorage.setItem(
                'catalogueSelectedPageSize',
                selectedPageSize.toString()
            );
            generateQuery(
                selectedPageSize.toString(),
                selectedPage.toString(),
                sortedBy,
                sortedOrder
            );
        }
        if (currentCatalogueSaleCode && saleCode === currentCatalogueSaleCode) {
            let selectedCataloguePage = sessionStorage.getItem(
                'digitalCatalogueSelectedPage'
            );
            let selectedCataloguePageSize = sessionStorage.getItem(
                'digitalCatalogueSelectedPageSize'
            );
            let selectedCatalogueSortBy = sessionStorage.getItem(
                'digitalCatalogueSelectedSortBy'
            );
            let selectedCatalogueSortOrder = sessionStorage.getItem(
                'digitalCatalogueSelectedSortOrder'
            );
            if (window.innerWidth < 1200) {
                selectedCataloguePageSize = sessionStorage.getItem(
                    'digitalMobileCatalogueSelectedPageSize'
                );
                const mobileSelPageStart = parseInt(
                    sessionStorage.getItem('digitalMobileCatalogueStart')!,
                    10
                );
                const mobileSelPageEnd = parseInt(
                    sessionStorage.getItem('digitalMobileCatalogueEnd')!,
                    10
                );

                selectedCataloguePage = Math.ceil(
                    mobileSelPageEnd /
                        parseInt(selectedCataloguePageSize!, 10) -
                        1
                ).toString();
                selectedCatalogueSortBy = '';
                selectedCatalogueSortOrder = '';
                setPageLimit(parseInt(selectedCataloguePageSize!, 10));
                setPageStart(mobileSelPageStart);
                setPageEnd(mobileSelPageEnd);
            }
            if (selectedCataloguePage) {
                setUserSelectedPage(parseInt(selectedCataloguePage, 10));
            } else {
                setUserSelectedPage(0);
            }
            if (selectedCataloguePageSize) {
                setUserSelectedPageSize(
                    parseInt(selectedCataloguePageSize, 10)
                );
            } else {
                setUserSelectedPage(100);
            }
            if (selectedCatalogueSortBy && selectedCatalogueSortOrder) {
                setSelectedSortBy(selectedCatalogueSortBy);
                setSelectedSortOrder(selectedCatalogueSortOrder);
                setLotSortModel([
                    {
                        field: selectedCatalogueSortBy,
                        sort: selectedCatalogueSortOrder as GridSortDirection
                    }
                ]);
            }
            generateQuery(
                selectedCataloguePageSize!.toString(),
                selectedCataloguePage!.toString(),
                selectedCatalogueSortBy!,
                selectedCatalogueSortOrder!
            );
        } else if (
            !currentCatalogueSaleCode ||
            saleCode !== currentCatalogueSaleCode
        ) {
            if (!location.search) {
                sessionStorage.setItem('currentSaleCode', saleCode);
                sessionStorage.setItem('digitalCatalogueSelectedPage', '0');
                sessionStorage.setItem(
                    'digitalCatalogueSelectedPageSize',
                    '100'
                );
                sessionStorage.setItem(
                    'digitalMobileCatalogueSelectedPageSize',
                    '100'
                );
                sessionStorage.setItem('digitalMobileCatalogueStart', '0');
                sessionStorage.setItem('digitalMobileCatalogueEnd', '100');
                generateQuery('100', '0', '', '');
            }
        }
    }, []);

    useEffect(() => {
        const selectedPage =
            parseInt(urlSearchParams!.get('currentpage')!, 10) - 1;
        const selectedPageSize = parseInt(
            urlSearchParams!.get('pagesize')!,
            10
        );
        if (selectedPage && selectedPageSize) {
            setUserSelectedPage(selectedPage);
            setUserSelectedPageSize(selectedPageSize);
        }
    }, [urlSearchParams]);

    // generates query string for lots table
    const generateQuery = (
        pageSize: string,
        currentPage: string,
        sortTerm: string,
        sortOrder: string
    ) => {
        let query = `pagesize=${pageSize}&currentpage=${
            parseInt(currentPage, 10) + 1
        }`;
        if (sortTerm && sortOrder) {
            query += `&sortby=${sortTerm}&sortorder=${sortOrder}`;
        }
        const searchParam = new URLSearchParams(query);
        setSearchParams(searchParam);
        history.push({ search: searchParam.toString() });
    };

    // searches the catalogue
    const searchCatalogue = () => {
        let newHorseCollection = catalogueResults;
        if (!searchValue) {
            updateHorseCollection(newHorseCollection);
        } else {
            newHorseCollection = newHorseCollection.filter((o: any) => {
                return Object.keys(o).some((k) => {
                    return (
                        typeof o[k] === 'string' &&
                        o[k].toLowerCase().includes(searchValue.toLowerCase())
                    );
                });
            });
        }
        updateHorseCollection(newHorseCollection);
    };

    // sends users to lot detail page upon selecting a lot in the catalogue
    const rowClickAction = (params: any) => {
        history.push(`/digital/${saleCode}/lots/${params.row.id}`, {
            indexPageSize: selectedUserPageSize,
            indexPage: selectedUserPage,
            indexSortBy: selectedSortBy,
            indexSortOrder: selectedSortOrder
        });
    };

    // sets the catalogue size for the page. Can either be 25, 50 or 100 for the mobile view of the catalogue
    const setCataloguePageLimit = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        event.preventDefault();
        const pageValue = parseInt(event.target.value, 10);
        generateQuery(
            pageValue.toString(),
            selectedUserPage.toString(),
            selectedSortBy,
            selectedSortOrder
        );
        sessionStorage.setItem(
            'digitalMobileCatalogueSelectedPageSize',
            pageValue.toString()
        );
        setPageLimit(pageValue);
        setPageEnd(pageStart + pageValue);
    };

    // paginates the catalogue on mobile devices
    const paginateCatalogue = (pageDirection: string) => {
        const catalogueLength = Object.keys(catalogueResults).length;
        let currentPageStart = pageStart;
        let currentPageEnd = pageEnd;
        if (pageDirection === 'forwards' && pageEnd < catalogueLength) {
            currentPageStart += pageLimit;
            currentPageEnd += pageLimit;
            setPageStart(currentPageStart);
            setPageEnd(currentPageEnd);
        } else if (pageDirection === 'backwards' && pageStart >= pageLimit) {
            currentPageStart -= pageLimit;
            currentPageEnd -= pageLimit;
            setPageStart(currentPageStart);
            setPageEnd(currentPageEnd);
        } else if (pageDirection === 'backwards' && pageStart < pageLimit) {
            currentPageStart = 0;
            currentPageEnd = pageLimit;
            setPageStart(currentPageStart);
            setPageEnd(currentPageEnd);
        }
        sessionStorage.setItem(
            'digitalMobileCatalogueStart',
            currentPageStart.toString()
        );
        sessionStorage.setItem(
            'digitalMobileCatalogueEnd',
            currentPageEnd.toString()
        );
        generateQuery(
            pageLimit.toString(),
            (Math.ceil(currentPageEnd / pageLimit) - 1).toString(),
            selectedSortBy,
            selectedSortOrder
        );
        window.scrollTo(0, 0);
    };

    // skips to the start and end of the catalogue for mobile devices
    const catalogueSkip = (pageDirection: string) => {
        const catalogueLength = Object.keys(catalogueResults).length;
        let skipPageStart = catalogueLength - pageLimit;
        let skipPageEnd = catalogueLength;
        if (pageDirection === 'forwards') {
            setPageStart(skipPageStart);
            setPageEnd(skipPageEnd);
        } else if (pageDirection === 'backwards') {
            skipPageStart = 0;
            skipPageEnd = pageLimit;
            setPageStart(skipPageStart);
            setPageEnd(skipPageEnd);
        }
        generateQuery(
            pageLimit.toString(),
            (Math.ceil(skipPageEnd / pageLimit) - 1).toString(),
            selectedSortBy,
            selectedSortOrder
        );
    };

    // sets the page number on page change and generates query string with the data
    const setPageChange = (pageValue: number) => {
        sessionStorage.setItem(
            'digitalCatalogueSelectedPage',
            pageValue.toString()
        );
        generateQuery(
            selectedUserPageSize.toString(),
            pageValue.toString(),
            selectedSortBy,
            selectedSortOrder
        );
        setUserSelectedPage(pageValue);
    };

    // sets the page number on page change and generates query string with the data
    const setPageSize = (pageSize: number) => {
        sessionStorage.setItem(
            'digitalCatalogueSelectedPageSize',
            pageSize.toString()
        );
        generateQuery(
            pageSize.toString(),
            selectedUserPage.toString(),
            selectedSortBy,
            selectedSortOrder
        );
        setUserSelectedPageSize(pageSize);
    };

    // Sets the sorted by and sort order query string with the data
    const setPageSort = (model: GridSortModel) => {
        setLotSortModel(model);
        if (model.length > 0) {
            setSelectedSortBy(model[0].field);
            setSelectedSortOrder(model[0].sort!);
            sessionStorage.setItem(
                'digitalCatalogueSelectedSortBy',
                model[0].field
            );
            sessionStorage.setItem(
                'digitalCatalogueSelectedSortOrder',
                model[0].sort!
            );
            generateQuery(
                selectedUserPageSize.toString(),
                selectedUserPage.toString(),
                model[0].field,
                model[0].sort!
            );
        } else {
            setSelectedSortBy('');
            setSelectedSortOrder('');
            sessionStorage.setItem('digitalCatalogueSelectedSortBy', '');
            sessionStorage.setItem('digitalCatalogueSelectedSortOrder', '');
            generateQuery(
                selectedUserPageSize.toString(),
                selectedUserPage.toString(),
                '',
                ''
            );
        }
    };

    return (
        <Typography variant="body1">
            <LotsGridFilerPanelComponent Catalogues={catalogueResults} />

            <div
                style={{ width: '100%', marginTop: '30px' }}
                className={classes.root}
            >
                {Object.keys(catalogueResults).length > 0 ? (
                    <DataGrid
                        page={selectedUserPage}
                        pageSize={selectedUserPageSize}
                        rows={catalogueResults}
                        columns={columns}
                        getRowClassName={() => 'catalogue-table-rows'}
                        onRowClick={(params: GridRowData) =>
                            rowClickAction(params)
                        }
                        autoHeight
                        disableColumnMenu
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageChange={(page: number) => setPageChange(page)}
                        onPageSizeChange={(pageSize: number) =>
                            setPageSize(pageSize)
                        }
                        sortModel={lotSortModel}
                        onSortModelChange={(model) => {
                            if (!_.isEqual(model, lotSortModel)) {
                                setPageSort(model);
                            }
                        }}
                        rowHeight={80}
                    />
                ) : (
                    <div />
                )}
            </div>
            {Object.keys(catalogueResults).length > 0 ? (
                catalogueResults
                    .slice(pageStart, pageEnd)
                    .map((item: any) => {
                        return (
                            <MobileCatalogueList
                                catalogueResults={item}
                                saleCode={saleCode}
                                pageSize={pageLimit}
                                currentPage={pageEnd}
                            />
                        );
                    })
            ) : (
                <div />
            )}
            <div className={classes.mobilePaginatorShadow} />
            <div className={classes.mobilePaginator}>
                <div style={{ width: '45%' }}>
                    <div>Rows Per Page</div>
                    <select
                        onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                        ) => setCataloguePageLimit(event)}
                        style={{ border: 'none', fontSize: '16px' }}
                        defaultValue={100}
                    >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div style={{ width: '35%' }}>
                    <FirstPageIcon
                        color="action"
                        onClick={() => catalogueSkip('backwards')}
                    />
                    <NavigateBeforeIcon
                        color="action"
                        onClick={() => paginateCatalogue('backwards')}
                    />
                    <NavigateNextIcon
                        color="action"
                        onClick={() => paginateCatalogue('forwards')}
                    />
                    <LastPageIcon
                        color="action"
                        onClick={() => catalogueSkip('forwards')}
                    />
                </div>
            </div>
        </Typography>
    );
};

const MobileCatalogueList = (props: MobileCatalogueProps) => {
    const classes = useStyles();
    const { catalogueResults, saleCode, pageSize, currentPage } = props;
    const history = useHistory();
    const rowClickAction = (id: any) => {
        history.push(`/digital/${saleCode}/lots/${id}`, {
            indexPageSize: pageSize,
            indexPage: (Math.ceil(currentPage / pageSize) - 1).toString(),
            indexSortBy: '',
            indexSortOrder: ''
        });
    };
    return (
        <div
            className={classes.mobileCatalogue}
            aria-hidden="true"
            onClick={() => rowClickAction(catalogueResults.id)}
        >
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 600
                    }}
                >
                    {catalogueResults.id}
                </Typography>
                {catalogueResults.images ? (
                    <div
                        style={{
                            height: '48px',
                            width: '48px',
                            backgroundImage: `url("${ARDEXCLOUD_IMAGE_BASEURL}${catalogueResults.images[0].objectName}")`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                ) : (
                    <div
                        style={{
                            height: '48px',
                            width: '48px',
                            margin: 'auto'
                        }}
                    >
                        <HorsePlaceHolder />
                    </div>
                )}
            </div>
            <div
                style={{
                    minWidth: '220px',
                    maxWidth: '220px'
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 600
                    }}
                >
                    {catalogueResults.name}
                </Typography>
                <div>
                    <Typography variant="body1">
                        Sire: {catalogueResults.sire}
                    </Typography>
                </div>
            </div>
            <div
                style={{
                    textAlign: 'right',
                    alignItems: 'flex-end',
                    flex: '0.5'
                }}
            >
                <FavoriteBorderIcon color="primary" />
                <div>
                    <div
                        style={{
                            border: '1px solid #C2C2BA',
                            width: '25px',
                            height: '20px',
                            textAlign: 'center'
                        }}
                    >
                        {catalogueResults.sex}
                    </div>
                </div>
            </div>
            <div className={classes.mobilePaginatorCover} />
        </div>
    );
};
