import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& div': {
                margin: '0 auto'
            },
            height: 'auto',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto'
            },
            backgroundColor: '#FFFFFF'
        },
        title: {
            paddingTop: '24px',
            height: '82px',
            fontWeight: 600,
            color: theme.palette.secondary.main
        },
        partnersTileContainer: {
            '& div': {
                margin: '0 auto'
            },
            margin: '0 auto',
            marginBottom: '24px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto'
            }
        },
        tile: {
            padding: '32px',
            '& img': {
                maxWidth: '100%'
            }
        },
        tiles: {
            maxWidth: '1228px'
        }
    })
);

type PartnerData = {
    image: string;
    order: number;
    altText: string;
    link: string;
};

export const Partners = () => {
    const classes = useStyles();
    const [partners, setPartners] = useState<PartnerData[]>([]);

    useEffect(() => {
        const getImages = () => {
            API.get('cms-api', `get-partners`, {
                response: true,
                responseType: 'text'
            })
                .then((response) => {
                    const responseData = response.data.data.sort(
                        (a: PartnerData, b: PartnerData) => {
                            return a.order > b.order ? 1 : -1;
                        }
                    );
                    setPartners(responseData);
                })
                .catch((error) => {
                    console.log(
                        'error in getting homepage partners list from CMS',
                        error
                    );
                });
        };

        getImages();
    }, []);

    const PartnerTile = (props: { data: PartnerData }) => {
        const { data } = props;

        return (
            <Grid container className={classes.partnersTileContainer}>
                <Grid item xs={12} md={12}>
                    <Grid container justifyContent="center">
                        <Link href={data.link}>
                            <img
                                src={data.image}
                                alt={data.altText}
                                data-testid={`img-${data.image}`}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    if (partners)
        return (
            <Grid container className={classes.container}>
                <Grid item xs={12} md={12} className={classes.title}>
                    <Typography variant="h2" align="center" data-testid="title">
                        Partners
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.tiles}>
                    <Grid container>
                        {partners.map((n) => (
                            <Grid
                                item
                                xs={6}
                                md={2}
                                key={n.order}
                                className={classes.tile}
                            >
                                <PartnerTile data={n} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    return <div />;
};
