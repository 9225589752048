import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core/';

const useStyles = makeStyles(() =>
    createStyles({
        tcList: {
            textAlign: 'left'
        }
    })
);

export default function TermsAndConditions() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid xs={10} item>
                <ol className={classes.tcList}>
                    <Typography variant="body1">
                        <li>
                            <Typography variant="h4">THE SITE</Typography>
                            <ol>
                                <li>
                                    All bidders wishing to bid on any auction of
                                    a Lot (the Bidder) and any vendors wishing
                                    to submit a Lot for sale by auction (the
                                    Vendor) must register as a user of the Site
                                    (a User).
                                </li>
                                <li>
                                    By registering as a User, you agree to these
                                    terms and conditions, the Site Terms and
                                    Conditions and Inglis Privacy Policy.
                                </li>
                                <li>
                                    You must:
                                    <ol type="a">
                                        <li>
                                            keep up to date all personal
                                            information of your account; and
                                        </li>
                                        <li>
                                            protect your account, including your
                                            username and password.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    By registering as a User you consent to
                                    Inglis handling your personal information in
                                    accordance with the Inglis Privacy Policy
                                    (which can be found&nbsp;
                                    <a href="https://inglis.com.au/privacy/">
                                        here
                                    </a>
                                    )
                                </li>
                                <li>
                                    As a User, you represent and warrant that:
                                    <ol type="a">
                                        <li>you are at least 18 years old;</li>
                                        <li>
                                            if required, you hold all
                                            Authorisations to bid on or sell
                                            Lots;
                                        </li>
                                        <li>
                                            all information you provide in the
                                            Entry Details is not false,
                                            misleading, inaccurate or offensive
                                        </li>
                                        <li>
                                            all information you provide in the
                                            Entry Details is complete and in
                                            compliance with your Authorisation;
                                        </li>
                                        <li>
                                            you will not manipulate the price of
                                            any Lot or another Users&apos;
                                            listing including by placing fake
                                            bids, hindering another Bidder or
                                            disrupting an Auction in any way;
                                        </li>
                                        <li>
                                            you will not bid on any Lot unless
                                            you are financially able to pay the
                                            full bid price made for the Lot;
                                        </li>
                                        <li>
                                            you will not transfer or assign your
                                            account to another party without our
                                            consent;
                                        </li>
                                        <li>
                                            you will not infringe the copyright
                                            or other intellectual property
                                            rights of any other person;
                                        </li>
                                        <li>
                                            you will not engage in spamming or
                                            other conduct involving the sending
                                            of bulk electronic or unsolicited
                                            communications, chain letters or
                                            pyramid schemes;
                                        </li>
                                        <li>
                                            you will not use any automated
                                            systems (including any site crawler,
                                            spider or robot) to harvest, access
                                            or analyse any information or
                                            content from the Site;
                                        </li>
                                        <li>
                                            you will not distribute viruses or
                                            any other technology that may harm
                                            the Site or other Users;
                                        </li>
                                        <li>
                                            you will not copy, reproduce, modify
                                            or create derivative works of the
                                            Site without our prior written
                                            consent;
                                        </li>
                                        <li>
                                            you will not interfere or attempt to
                                            interfere with the orderly working
                                            of the Site;
                                        </li>
                                        <li>
                                            you will comply with all applicable
                                            laws; and
                                        </li>
                                        <li>
                                            you will not copy, modify or
                                            distribute rights or content from
                                            the Site or Inglis trademarks and
                                            copyright.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Inglis may in its reasonable discretion
                                    <ol type="a">
                                        <li>
                                            restrict or temporarily or
                                            indefinitely suspend your account;
                                        </li>
                                        <li>terminate your account;</li>
                                        <li>
                                            remove your Lot from the Catalogue,
                                            <br />
                                            where, in Inglis&apos; reasonable
                                            opinion:
                                        </li>
                                        <li>
                                            you have breached these terms and
                                            conditions;
                                        </li>
                                        <li>
                                            your actions in using the Site may
                                            cause loss or damage to other Users,
                                            third parties, us or you;
                                        </li>
                                        <li>
                                            any information in the Entry Details
                                            is inappropriate or offensive; or
                                        </li>
                                        <li>
                                            Inglis is unable to verify your
                                            identity or any information you have
                                            provided Inglis.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Bidder and Vendor Contact
                                    <ol type="a">
                                        <li>
                                            Bidders may contact Vendors directly
                                            to arrange for pre-bidding
                                            inspections, veterinary
                                            examinations, transportation
                                            arrangements and other such
                                            reasonable enquires.
                                        </li>
                                        <li>
                                            A Bidder may not contact a Vendor
                                            for the purposes of purchasing a Lot
                                            from a Vendor outside an Auction
                                            other than in accordance with clause
                                            6.
                                        </li>
                                        <li>
                                            To arrange transport and delivery of
                                            any purchased lot, once purchase has
                                            been confirmed by Inglis.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                AUCTION CONDITIONS
                            </Typography>
                            <ol>
                                <li>
                                    A Vendor listing a Lot on the Site
                                    authorises Inglis to take the Lot on
                                    consignment and to act as the Vendor&apos;s
                                    exclusive agent to sell the Lot by auction
                                    and, in the case of a passed in Lot, by
                                    private treaty.
                                </li>
                                <li>
                                    All auctions shall be conducted subject to
                                    the following conditions:
                                    <ol type="a">
                                        <li>
                                            If a bid is disputed or there is any
                                            other problem in relation to a sale,
                                            Inglis is the sole arbiter, and may
                                            settle any dispute or problem on
                                            such terms as Inglis thinks fit.
                                            Inglis&apos; decision shall be final
                                            and binding on all parties.
                                        </li>
                                        <li>
                                            Inglis may, in case of a dispute or
                                            any other problem with a sale, elect
                                            to re-offer the Lot for sale.
                                        </li>
                                        <li>
                                            Inglis can refuse to accept any bid
                                            which in Inglis&apos; opinion is not
                                            in the best interests of the Vendor.
                                            Inglis does not have to give a
                                            reason for refusal.
                                        </li>
                                        <li>
                                            If a Vendor wishes to impose a
                                            reserve price, the Vendor must
                                            specify this at the time of listing
                                            the Lot.
                                        </li>
                                        <li>
                                            A Vendor will not be entitled to
                                            remove or vary the reserve price of
                                            a Lot after its listing without
                                            written consent from Inglis.
                                        </li>
                                        <li>
                                            Inglis may withdraw any Lot from
                                            sale without being obliged to give a
                                            reason for doing so.
                                        </li>
                                        <li>
                                            Inglis may make any announcement in
                                            connection with a Lot on the day of
                                            the sale by noting this on a
                                            listing, which will take precedence
                                            over:
                                            <ol type="i">
                                                <li>
                                                    These terms and conditions;
                                                    and
                                                </li>
                                                <li>
                                                    any statements in the
                                                    listing.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Inglis, in its absolute discretion,
                                            reserves the right to exclude any
                                            person/s from an Auction.
                                        </li>
                                        <li>
                                            A vendor must have provided any
                                            Personal Information (including any
                                            documents evidencing such Personal
                                            Information) reasonably requested,
                                            or as is required by Inglis so that
                                            Inglis may comply with any legal or
                                            regulatory obligation, including
                                            complying with the RNSW Code Of
                                            Practice.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                AUCTION CATALOGUE, SCHEDULE AND FEES
                            </Typography>
                            <ol>
                                <li>
                                    The catalogue will be published on the day
                                    of the auction
                                </li>
                                <li>
                                    Auctions will be scheduled to start at 15:00
                                    AEST/AEDT on the days nominated on our sales
                                    calendar found at&nbsp;
                                    <a href="https://inglis.com.au/sales-calendar">
                                        inglis.com.au/sales-calendar
                                    </a>
                                    .
                                </li>
                                <li>
                                    The conclusion of the Auction will start 5
                                    days after its commencement with the first
                                    Lot in the Catalogue at 12.00 (midday)
                                    AEST/AEDT and each Lot proceeding by one (1)
                                    minute intervals (Auction End).
                                </li>
                                <li>
                                    A schedule of listing fees and other charges
                                    is available at&nbsp;
                                    <a href="https://inglisdigital.com/default.aspx?pageaction=faq">
                                        www.inglisdigital.com.au
                                    </a>
                                </li>
                                <li>
                                    A Lot will not be listed until the listing
                                    fee for the Lot has been paid by the Vendor
                                    to Inglis in full.
                                </li>
                                <li>All listing fees are non-refundable</li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                BIDDING PROCESS
                            </Typography>
                            <ol>
                                <li>
                                    Only Users may place a bid on a listing.
                                </li>
                                <li>
                                    Once you place a bid, it cannot be
                                    withdrawn.
                                </li>
                                <li>
                                    Before you place a bid, you acknowledge
                                    that:
                                    <ol type="a">
                                        <li>
                                            you have had the opportunity to
                                            inspect the Lot prior to the sale;
                                        </li>
                                        <li>
                                            if the Vendor has lodged any
                                            material relating to the Lot online,
                                            you have had the opportunity to
                                            inspect such material prior to
                                            bidding;
                                        </li>
                                        <li>
                                            your bid is made solely in reliance
                                            on your own enquiries and
                                            inspections;
                                        </li>
                                        <li>
                                            you have not relied on any statement
                                            or representation made by or on
                                            behalf of the Vendor or Inglis,
                                            other than the Vendor&apos;s
                                            warranties expressed in clause 13
                                            and the Entry Details (and any
                                            announcements by Inglis made under
                                            condition 2.2(h);
                                        </li>
                                        <li>
                                            all Lots are sold in their present
                                            condition, subject to all faults,
                                            imperfections or other defects
                                            whether latent or patent; and
                                        </li>
                                        <li>
                                            no compensation is payable by either
                                            the Vendor or Inglis for any faults,
                                            imperfections or other defects save
                                            as required by law.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Before you place a bid, Inglis recommends
                                    that you obtain independent professional
                                    advice, including but not limited to
                                    veterinary, financial and legal advice which
                                    takes in account your particular
                                    circumstances.
                                </li>
                                <li>
                                    A Bidder is taken to be a principal and
                                    shall be personally liable as such.
                                </li>
                                <li>
                                    All bids made for a Lot will be visible on
                                    the listing to other Users.
                                </li>
                                <li>
                                    Bids will only be accepted prior to the
                                    Auction End of the listing.
                                </li>
                                <li>
                                    If a bid is placed on a Lot within the final
                                    five (5) minutes prior to its Auction End,
                                    the Auction will automatically be extended
                                    for a further five (5) minutes period until
                                    a period of a full five (5) minutes period
                                    has passed without a bid being placed by any
                                    Bidder.
                                </li>
                                <li>
                                    Inglis does not:
                                    <ol type="a">
                                        <li>
                                            make any warranties or
                                            representations (either express,
                                            implied or inferred) relating to the
                                            quality, standard or suitability of
                                            a Lot;
                                        </li>
                                        <li>
                                            recommend or endorse any Vendor;
                                        </li>
                                        <li>
                                            have any obligation to verify or
                                            authenticate any information in a
                                            listing of a Lot made by a the
                                            Vendor or a third party about any
                                            Lot offered for Auction.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">AUCTION END</Typography>
                            <ol>
                                <li>
                                    Subject to any reserve price:
                                    <ol type="a">
                                        <li>
                                            the highest Bidder on a Lot at the
                                            Auction End shall be the purchaser
                                            (the Purchaser); and
                                        </li>
                                        <li>
                                            the highest bid will be Purchase
                                            Price.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Where the Vendor has specified a reserve
                                    price, a Lot will be deemed to have been
                                    passed in where the highest bid at the
                                    Auction End does not meet or exceed the
                                    reserve price (Passed In).
                                </li>
                                <li>
                                    At Auction End, the Purchaser:
                                    <ol type="a">
                                        <li>
                                            will be deemed to have entered into
                                            an unconditional contract for sale
                                            of the Lot;
                                        </li>
                                        <li>
                                            will not have a cooling-off period;
                                        </li>
                                        <li>
                                            must pay the full Purchase Price to
                                            Inglis immediately, unless other
                                            arrangements have been agreed to in
                                            writing by Inglis;
                                        </li>
                                        <li>
                                            must sign and provide to the Vendor
                                            such documents Inglis and the Vendor
                                            requires.
                                        </li>
                                        <li>
                                            Once a Lot is sold by Auction, no
                                            further negotiations will be
                                            conducted by Inglis.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Where the Vendor is the highest Bidder of
                                    their Lot at the Auction End, Inglis shall
                                    be entitled to commission. In such
                                    circumstances, the Vendor shall have no
                                    obligation to pay the Purchase Price.
                                </li>
                                <li>
                                    Any dispute arising between the Vendor and
                                    the Purchaser in relation to a Lot must be
                                    resolved between them only, and a Lot cannot
                                    be returned to Inglis or its stables.
                                </li>
                                <li>
                                    Inglis will:
                                    <ol type="a">
                                        <li>
                                            at Auction End, notify the Vendor by
                                            email of the Purchase Price and the
                                            Purchaser; and
                                        </li>
                                        <li>
                                            on receipt of the Purchase Price (or
                                            such other arrangement as agreed to
                                            prior with Inglis), notify the
                                            Vendor that the Purchase Price has
                                            been received by Inglis
                                            (Confirmation of Sale).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Without limiting any other obligations or
                                    liabilities of the Purchaser under these
                                    terms and conditions, until the Purchase
                                    Price and any interest payable thereon and
                                    any other moneys payable by the Purchaser
                                    under these terms and conditions have been
                                    paid in full the Purchaser must:
                                    <ol type="a">
                                        <li>
                                            keep the Lot in good health and
                                            condition and protect it from injury
                                            and damage;
                                        </li>
                                        <li>
                                            at its cost insure the Lot jointly,
                                            with Inglis, for its full insurable
                                            value against all risks and provide
                                            a copy of the certificate of
                                            insurance to Inglis;
                                        </li>
                                        <li>
                                            notify Inglis of the location of the
                                            Lot where it is no longer in the
                                            possession of the Vendor or Inglis;
                                        </li>
                                        <li>
                                            not sell, lease, assign or create
                                            any security interest in the Lot;
                                        </li>
                                        <li>
                                            not move the Lot outside Australia;
                                        </li>
                                        <li>
                                            not submit the Lot to any surgical
                                            procedure; and
                                        </li>
                                        <li>
                                            not do any act or thing prejudicial
                                            to the respective interests of the
                                            Vendor and Inglis in the Lot.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    A Lot that is Passed In will automatically
                                    be listed on the &quot;Classic
                                    Classified&quot; classifieds operated by
                                    Inglis at&nbsp;
                                    <a href="https://inglisdigital.com/www.bloodstock.com.au">
                                        www.bloodstock.com.au
                                    </a>
                                    . under the same terms as specified on the
                                    Lot&apos;s Auction listing. The listing
                                    price displayed will be the reserve price of
                                    the Lot or &ldquo;Price On
                                    Application&rdquo; for Lots that are being
                                    offered unreserved.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">OUTSIDE SALES</Typography>
                            <ol>
                                <li>
                                    A Lot entered for sale by Auction may be
                                    sold outside the Auction only in accordance
                                    with the following provisions:
                                    <ol type="a">
                                        <li>
                                            No Lot shall be sold privately prior
                                            to the auction without the prior
                                            written consent of Inglis.
                                        </li>
                                        <li>
                                            In the event of any prior sale,
                                            Inglis shall be entitled to
                                            commission as if the Lot was sold
                                            through the Site, and the Vendor
                                            shall provide Inglis with full
                                            details of any such sale.
                                        </li>
                                        <li>
                                            The sale of Passed In Lots must be
                                            referred to the Vendor for approval.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                VENDORS RIGHTS AND OBLIGATIONS
                            </Typography>
                            <ol>
                                <li>
                                    Inglis must pay the proceeds of sale to the
                                    Vendor within ten (10) business days of the
                                    sale unless other arrangements have been
                                    agreed to in writing between the Vendor and
                                    Inglis or the sale is validly cancelled
                                    under these terms.
                                </li>
                                <li>
                                    Prior to paying the proceeds of sale to the
                                    Vendor, Inglis may deduct all of its fees as
                                    set out on the entry form or otherwise
                                    agreed in writing.
                                </li>
                                <li>
                                    Inglis does not need to pay the Vendor
                                    unless:
                                    <ol type="a">
                                        <li>
                                            The Vendor has executed all
                                            documents and done all other things
                                            necessary to permit registration of
                                            a transfer of the title to the Lot
                                            in the name of the Purchaser;
                                        </li>
                                        <li>
                                            If the Vendor is not identical with
                                            the registered owner, the Vendor has
                                            produced to Inglis such evidence as
                                            Inglis reasonably requires of the
                                            Vendor&apos;s Identity and authority
                                            to receive the Purchase Price;
                                        </li>
                                        <li>
                                            The Vendor has provided Inglis with
                                            evidence of the discharge of any
                                            Security Interest or other
                                            encumbrance affecting the Lot; and
                                        </li>
                                        <li>
                                            Inglis is satisfied that it will
                                            receive payment of the Purchase
                                            Price from the Purchaser
                                        </li>
                                        <li>
                                            Inglis has received all
                                            identification information as
                                            required by Inglis to verify the
                                            identity of the Purchaser and the
                                            Vendor.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The net proceeds of sale is a debt owing by
                                    Inglis to the Vendor, however Inglis is
                                    authorised to pay any part of the proceeds
                                    of sale to any Secured Party with a Security
                                    Interest affecting the Lot in order to
                                    obtain clear title to the Lot and such
                                    payment will discharge any obligation by
                                    Inglis to the Vendor with respect to such
                                    amount.
                                </li>
                                <li>
                                    Upon the payment of the net proceeds of sale
                                    from Inglis to the Vendor, title to the Lot
                                    passes to Inglis.
                                </li>
                                <li>
                                    If the Vendor agrees to extend any credit
                                    terms to the Purchaser, then Inglis shall be
                                    under no obligation to the Vendor as to
                                    collection and payment of the Purchase
                                    Price. In any such case the Vendor shall
                                    remain fully responsible for the due payment
                                    to Inglis of the commission and all other
                                    moneys owing to Inglis in connection with
                                    the auction and sale of the Lot, whether or
                                    not the Vendor is paid by the Purchaser.
                                </li>
                                <li>
                                    Any arrangements under clause 7.6 must be in
                                    writing and executed by the Vendor,
                                    Purchaser and Inglis.
                                </li>
                                <li>
                                    The Vendor and the Purchaser must disclose
                                    to Inglis the terms of payment of any
                                    purchase at any time upon request by Inglis.
                                </li>
                                <li>
                                    Clauses 7.1 - 7.9 may be varied at any time
                                    provided Inglis gives written notice of the
                                    change of terms and conditions prior to the
                                    commencement of the auction;
                                </li>
                                <li>
                                    The Vendor acknowledges that it has read and
                                    accepted Inglis&apos;s the Privacy Statement
                                    contained within these auction terms, and
                                    agreed to any collection, use or disclosure
                                    of the vendor&apos;s Personal Information by
                                    Inglis, including the disclosure of such
                                    Personal Information by Inglis to a third
                                    party such as Racing New South Wales.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                PAYMENT BY THE PURCHASER
                            </Typography>
                            <ol>
                                <li>
                                    At the Auction End, the Purchaser:
                                    <ol type="a">
                                        <li>
                                            must sign and provide all documents
                                            as required by the Vendor or Inglis
                                            to effect the sale; and
                                        </li>
                                        <li>
                                            pay the full Purchase Price to
                                            Inglis within sixty (60) minutes of
                                            the Auction End, unless other
                                            arrangements have been agreed to in
                                            writing by Inglis.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    If the Purchase Price or any part is not
                                    paid in accordance with clause 8.1, the
                                    Purchaser is liable to pay interest on the
                                    outstanding balance at the rate of 16% per
                                    annum calculated daily in arrears from the
                                    Auction End date up to and including the
                                    date of payment.
                                </li>
                                <li>
                                    If the Purchaser (hereinafter referred to in
                                    this sub condition as the Initial Purchaser)
                                    defaults in the due payment of the Purchase
                                    Price or any part or any interest thereon or
                                    any other moneys payable by the Initial
                                    Purchaser under these conditions of sale
                                    then:
                                    <ol type="a">
                                        <li>
                                            the Vendor or Inglis may elect to
                                            cancel the sale. In such a
                                            circumstance the Vendor, or if
                                            Inglis has already paid the Vendor
                                            the net proceeds then Inglis, shall
                                            have the following rights:
                                            <ol type="i">
                                                <li>
                                                    To treat as forfeited all
                                                    moneys paid by the Initial
                                                    Purchaser except so much as
                                                    exceeds 25% of the Purchase
                                                    Price, or such lesser sum as
                                                    the Vendor may at law be
                                                    permitted to treat as
                                                    forfeited;
                                                </li>
                                                <li>
                                                    To sue the Initial Purchaser
                                                    for damages for breach of
                                                    contract;
                                                </li>
                                                <li>
                                                    To resell the Lot as owner
                                                    (either by way of auction or
                                                    otherwise), and recover from
                                                    the Initial Purchaser as
                                                    liquidated damages the
                                                    deficiency (if any) arising
                                                    on the resale plus all costs
                                                    and expenses associated with
                                                    the resale or otherwise
                                                    arising out of the Initial
                                                    Purchaser&apos;s default;
                                                    and
                                                </li>
                                                <li>
                                                    To retain any moneys paid by
                                                    the Initial Purchaser, over
                                                    and above moneys forfeited
                                                    pursuant to clause 8.3(a)
                                                    (i) as security for any
                                                    damages or other moneys
                                                    payable by the Initial
                                                    Purchaser to the Vendor
                                                    and/or Inglis under the
                                                    conditions of sale.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Alternatively, the Vendor, or if
                                            Inglis has already accounted to the
                                            Vendor for the Purchase Price then
                                            Inglis, may elect to affirm the sale
                                            and sue the Initial Purchaser for
                                            the Purchase Price and interest and
                                            other moneys payable by the Initial
                                            Purchaser under the conditions of
                                            sale, or such part or parts of such
                                            moneys as may be outstanding at any
                                            time.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Any potential Bidder seeking credit or
                                    finance from Inglis must enter into a credit
                                    or finance arrangement with Inglis prior to
                                    the commencement of the Auction. Any such
                                    arrangement shall be between Inglis and the
                                    Bidder as principals, and shall not involve
                                    the Vendor nor form part of any sale whether
                                    made by auction or private treaty. The
                                    extension of credit or finance by Inglis to
                                    a Bidder at a prior sale shall not
                                    automatically apply at this sale, and it is
                                    a Bidder&apos;s responsibility to reaffirm
                                    credit arrangements with Inglis prior to
                                    bidding.
                                </li>
                                <li>
                                    The Purchaser must pay the Purchase Price to
                                    Inglis in accordance with the credit
                                    contract.
                                </li>
                                <li>
                                    Any Purchaser who seeks to purchase a Lot on
                                    credit authorises Inglis to seek from third
                                    parties and be provided with personal
                                    information, including financial
                                    information, about the creditworthiness of
                                    the Purchaser for the purpose of allowing
                                    Inglis to assess a request for credit
                                    submitted by the Purchaser.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                PASSING OF RISK
                            </Typography>
                            <ol>
                                <li>
                                    The Vendor shall bear the sole risk and
                                    responsibility for a Lot until risk in the
                                    Lot passes to the Purchaser pursuant to
                                    clause 9.2.
                                </li>
                                <li>
                                    Despite clause 10, upon the Confirmation of
                                    Sale, the sole risk and responsibility for a
                                    Lot shall be borne by the Purchaser, who
                                    shall thereafter be responsible for all
                                    expenses incurred in respect of the Lot,
                                    including care of the Lot. Such expenses
                                    include but are not limited to stabling,
                                    agistment and transport charges.
                                </li>
                                <li>
                                    Such risk and responsibility shall include
                                    any loss, expenses, injury or damage caused
                                    by intentional act or omission, negligence
                                    (whether caused by any act or omission on
                                    the part of Inglis or its servants or
                                    agents), accident, disease, illness, act of
                                    God, or otherwise.
                                </li>
                                <li>
                                    The Purchaser indemnifies and holds harmless
                                    Inglis, Inglis and the Vendor for any loss,
                                    expense, injury or damage contemplated by
                                    clause 9.3.
                                </li>
                                <li>
                                    Any Lot not sold, or for which any sale is
                                    subsequently cancelled, shall remain at, or
                                    revert to, the risk of the Vendor, who shall
                                    be responsible for all expenses pertaining
                                    to the Lot and where applicable for removal
                                    of the Lot from the premises of Inglis.
                                </li>
                                <li>
                                    Inglis shall be entitled, in its absolute
                                    discretion and at the expense of the Vendor
                                    or the Purchaser (as Inglis determines) to
                                    engage a veterinary surgeon to inspect
                                    and/or treat any Lot.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                PASSING OF TITLE
                            </Typography>
                            <ol>
                                <li>
                                    Prior to the commencement of an Auction, the
                                    Vendor must execute and deliver to Inglis
                                    all such documents as may be necessary to
                                    confirm registration of the Lot with Racing
                                    Information Services Australia Pty Ltd and
                                    to permit registration of the transfer of
                                    title to the Lot from the Vendor to the
                                    Purchaser, plus evidence of payment of all
                                    stud book and registration fees payable for
                                    the Lot up to the date of the sale. If the
                                    Vendor fails to provide all necessary
                                    documents as required then Inglis may, but
                                    is not required to do so, obtain these
                                    documents on behalf of and at the expense of
                                    the Vendor.
                                </li>
                                <li>
                                    Title in a Lot shall not pass to the
                                    Purchaser until the full Purchase Price and
                                    all other costs and expenses owed by the
                                    Purchaser to Inglis or the Vendor for the
                                    Lot have been paid by the Purchaser to
                                    Inglis, notwithstanding that the Purchaser
                                    may have taken delivery of the Lot.
                                </li>
                                <li>
                                    After the Auction End, Inglis shall be
                                    authorised on behalf of the Vendor to
                                    complete the registration and transfer
                                    documentation in the name of the Purchaser,
                                    and to do all such other acts and things as
                                    may be necessary to effect the transfer of
                                    title to the Lot. Both the Vendor and the
                                    Purchaser must execute such other documents
                                    and do such other acts and things as are
                                    necessary to give effect to these terms and
                                    conditions and to complete the sale and
                                    transfer of title to the Lot.
                                </li>
                                <li>
                                    Inglis is entitled, but without being under
                                    any obligation to do so, to retain the title
                                    documents for a Lot until the Purchase Price
                                    and all other moneys owing by a Purchaser to
                                    the Vendor or Inglis have been paid. Where
                                    the Vendor buys back a Lot, Inglis may
                                    retain the registration documents until all
                                    commissions and other moneys owing by the
                                    Vendor have been paid.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">DELIVERY</Typography>
                            <ol>
                                <li>
                                    The Purchaser shall, in the absence of other
                                    arrangements with Inglis, take delivery of
                                    the Lot within five (5) days of the
                                    Confirmation of Sale, and where applicable,
                                    shall remove the Lot from its location
                                    (unless other arrangements are agreed to
                                    with the Vendor). Inglis may in its
                                    discretion refuse to issue a Confirmation of
                                    Purchase until the full Purchase Price and
                                    all other moneys owing to either the Vendor
                                    or Inglis have been paid.
                                </li>
                                <li>
                                    If the Purchaser takes delivery of the Lot
                                    prior to payment of the Purchase Price and
                                    the sale is cancelled pursuant to clause
                                    8.3(a), the Vendor and/or Inglis may,
                                    without any prior notice to the Purchaser,
                                    take all necessary steps and use any
                                    reasonable force, either by themselves or
                                    through servants and agents, to recover
                                    possession of the Lot. For this purpose they
                                    may enter upon any land or premises and
                                    repossess the Lot. All costs and expenses of
                                    any such repossession activity shall be a
                                    debt owing by the Purchaser to the Vendor or
                                    Inglis (as the case may be). Until payment
                                    of the Purchase Price in full, the Purchaser
                                    shall at all times inform Inglis of the
                                    address of the premises at which the Lot is
                                    for the time being located.
                                </li>
                                <li>
                                    If:
                                    <ol type="a">
                                        <li>
                                            sale is cancelled by the Purchaser
                                            as a consequence of a breach of a
                                            Vendor warranty under clause 13; and
                                        </li>
                                        <li>
                                            the Purchaser has taken possession
                                            of a Lot,
                                            <br />
                                            the Lot shall be returned to the
                                            Vendor at the Vendor&apos;s expense.
                                            Any transportation, agistment and
                                            veterinary costs incurred by the
                                            Purchaser from the date of purchase
                                            up to cancellation of the sale shall
                                            be to the Purchaser&apos;s account.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    In the circumstances where the Purchaser
                                    exercises its rights pursuant to clauses
                                    11.3, 13 or 16, the debt owing by Inglis to
                                    the Vendor under 7.4 is void and where
                                    Inglis has already paid the Vendor the
                                    proceeds of sale, the Vendor must repay
                                    those monies to Inglis on demand.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">PPS ACT</Typography>
                            <ol>
                                <li>
                                    These terms and conditions, together with
                                    the terms and conditions of any credit or
                                    financing arrangement for value extended by
                                    Inglis, at its discretion, to the Purchaser
                                    as a pre-condition to permitting the
                                    Purchaser to take possession of the Lot
                                    prior to payment of the full amount of the
                                    Purchase Price, will constitute a security
                                    agreement under the PPS Act.
                                </li>
                                <li>
                                    The Purchaser grants to Inglis a PMSI being
                                    a charge over the Purchaser&rsquo;s interest
                                    in the Lot and any proceeds of the Lot
                                    including but not limited to any foals of
                                    the Lot and any proceeds of such foals to
                                    secure the Purchaser&apos;s obligations
                                    under these terms and conditions (including
                                    the terms and conditions of any credit or
                                    financing arrangement for value extended by
                                    Inglis) and payment of the full amount of
                                    the Purchase Price and all other fees and
                                    charges payable by the Purchaser in
                                    accordance therewith
                                </li>
                                <li>
                                    Inglis may at its discretion register the
                                    Security Interest created under clause 12.2,
                                    on the PPSR and amend such registration as
                                    may be required from time to time.
                                </li>
                                <li>
                                    The Purchaser agrees to execute any and all
                                    documents, provide all necessary information
                                    and do anything else required to ensure that
                                    Inglis obtains and maintains a perfected
                                    security interest as required by the PPS
                                    Act, which will have priority over all other
                                    Security Interests or encumbrances over or
                                    affecting the Lot and all fees and costs
                                    associated therewith, plus any fees and
                                    costs incurred by or on behalf of Inglis in
                                    relation to the maintenance, enforcement
                                    and/or discharge of such security interest
                                    are to be reimbursed by the Purchaser.
                                </li>
                                <li>
                                    If in Inglis&apos; opinion Inglis&apos;
                                    security position or obligations under or in
                                    connection with these terms and conditions
                                    of sale and any credit or financing
                                    arrangement between the parties have been or
                                    will be materially adversely affected,
                                    Inglis may by notice to the Purchaser cancel
                                    any credit or financing arrangement between
                                    the parties and the Purchaser must
                                    immediately pay to Inglis all monies owing
                                    by the Purchaser to Inglis within 15
                                    business days of that cancellation.
                                </li>
                                <li>
                                    The Purchaser undertakes not to dispose of
                                    any of rights in the Lot or grant any
                                    Security Interest, or other encumbrance to
                                    any other person in the Lot without first
                                    obtaining the consent in writing of Inglis
                                    which consent will be entirely at
                                    Inglis&apos; discretion and may be subject
                                    to any terms imposed by Inglis.
                                </li>
                                <li>
                                    To the extent permitted under the PPS Act
                                    the Purchaser agrees that Sections 142 and
                                    143 of the PPS Act will not apply to this
                                    contract or the Security Interest granted to
                                    Inglis under these terms and conditions and
                                    agrees to waive all rights to any of the
                                    following provided for in the PPS Act:
                                    <ol type="a">
                                        <li>
                                            Receive notice of an intention to
                                            seize collateral under Section 123;
                                        </li>
                                        <li>
                                            Receive notice of disposal of
                                            collateral under Section 130;
                                        </li>
                                        <li>
                                            Receive information under a
                                            statement of account under section
                                            132 (3) (d),
                                        </li>
                                        <li>
                                            Receive a statement of account if
                                            there is no disposal under Section
                                            132(4);
                                        </li>
                                        <li>
                                            Receive a notice of retention of
                                            collateral under Section 135; and/or
                                        </li>
                                        <li>
                                            Receive a notice of verification
                                            statement under Section 157.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Inglis will discharge its Security Interest
                                    when it is satisfied that all the
                                    Purchaser&apos;s obligations under these
                                    terms and conditions and those of any credit
                                    or financing arrangements between Inglis and
                                    the-Purchaser have been fulfilled.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                VENDOR&rsquo;S WARRANTIES
                            </Typography>
                            <ol>
                                <li>
                                    The Vendor warrants that:
                                    <ol type="a">
                                        <li>
                                            The description, pedigree and other
                                            information for the Lot as specified
                                            in the Entry Details and otherwise
                                            provided to Inglis is true and
                                            correct in all respects;
                                        </li>
                                        <li>
                                            The Vendor has the right to sell the
                                            Lot and can give good title to the
                                            Lot and in the case where there is
                                            more than one owner of the Lot, has
                                            the authority of every owner to sell
                                            the Lot and any applicable
                                            Authorisations;
                                        </li>
                                        <li>
                                            Where the Lot is two years old or
                                            less, full and complete disclosure
                                            has been made in the Entry Details
                                            to Inglis as to whether the Lot has
                                            undergone invasive joint surgery (as
                                            defined as if there has been an
                                            arthroscope used to assess or treat
                                            a joint or a surgical manipulation
                                            of the joint has been performed
                                            including insertion of a needle to
                                            inject beneath the cartilage into a
                                            cyst) or surgical intervention of
                                            the upper respiratory tract or has
                                            undergone abdominal surgery of any
                                            type (and the Vendor is solely
                                            responsible for ensuring the
                                            complete accuracy of the disclosure
                                            to Inglis); and
                                        </li>
                                        <li>
                                            The Lot is not and has not
                                            previously been subject to any bans,
                                            embargoes or other restriction (for
                                            bleeding, barrier behaviour or any
                                            other reasons) imposed by any
                                            horseracing authority, other than as
                                            notified by the Vendor to Inglis in
                                            the Entry Details and disclosed by
                                            Inglis in the listing (and it is the
                                            responsibility of the Vendor to see
                                            that such disclosure is made).
                                            Fillies and mares that are entered
                                            in the broodmare section of the
                                            Catalogue are excluded from this
                                            clause unless catalogued as a
                                            &ldquo;racing and breeding
                                            prospect&rdquo;.
                                        </li>
                                        <li>
                                            Where the Lot has been notified to
                                            Racing Australia as being officially
                                            &ldquo;retired &ldquo; as per Racing
                                            Australia Rule AR.64JA , then this
                                            is advised to Inglis in the Entry
                                            Details who will make the disclosure
                                            in the Lot&apos;s listing (and the
                                            Vendor is solely responsible for
                                            ensuring the complete accuracy of
                                            the disclosure to Inglis)
                                        </li>
                                        <li>
                                            The details of any Security Interest
                                            or other encumbrance over the Lot
                                            has been disclosed to Inglis in the
                                            Entry Details.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Vendor is solely responsible for the
                                    accuracy of any statement, description or
                                    particular relating to the Lot contained in
                                    the Entry Details and must notify Inglis of
                                    any error, omission or inaccuracy prior to
                                    the sale of the Lot. Inglis have no
                                    responsibility for any such error, omission
                                    or inaccuracy.
                                </li>
                                <li>
                                    Each Lot is sold with the benefit of any
                                    current engagements.
                                </li>
                                <li>
                                    In the event of a breach by the Vendor of
                                    any of the warranties in this clause 13, the
                                    Purchaser may elect to cancel the sale by
                                    notice in writing to Inglis within ten (10)
                                    days of the date of the sale and will be
                                    entitled to the return in full of the
                                    Purchase Price if paid and acknowledges that
                                    it shall have no other claims against the
                                    Vendor or Inglis.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                SPECIAL CHARACTERISTICS
                            </Typography>
                            <ol>
                                <li>
                                    Windsuckers and Wobblers
                                    <ol type="a">
                                        <li>
                                            The Vendor must disclose in writing
                                            to Inglis full details of any Lot
                                            which shows symptoms of being a
                                            Wobbler or Windsucker prior to the
                                            sale. Inglis will disclose this
                                            before or at the time of the sale.
                                        </li>
                                        <li>
                                            If the Vendor or Inglis fails to
                                            make the disclosure required by
                                            clause 14.1(a) and if the Lot proves
                                            to be a Windsucker or Wobbler within
                                            ten (10) days of the Auction End,
                                            then subject to the Purchaser
                                            producing at his expense written
                                            evidence, signed by a veterinary
                                            surgeon approved by Inglis,
                                            certifying that the Lot is a
                                            Windsucker or Wobbler, then, subject
                                            to clause 14.4, the Purchaser may
                                            cancel the sale by notice in writing
                                            to Inglis.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Roarers and Respiratory Problems:
                                    <ol type="a">
                                        <li>
                                            The Vendor must disclose in writing
                                            to Inglis in full on the Entry
                                            Details any Lot which shows symptoms
                                            of being a Roarer prior to the
                                            commencement of the Auction. Inglis
                                            will disclose this on the listing
                                            prior to commencement of the
                                            auction.
                                        </li>
                                        <li>
                                            If the Vendor or Inglis fails to
                                            make the disclosure required by
                                            clause 14.2(a) and if the Lot proves
                                            to be a Roarer within ten (10) days
                                            of the Auction End, then subject to
                                            the Purchaser producing at his
                                            expense written evidence, signed by
                                            a veterinary surgeon approved by
                                            Inglis, certifying that the Lot is a
                                            Roarer, then, subject to clause
                                            14.4, the Purchaser may cancel the
                                            sale by notice in writing to Inglis.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Impaired Vision
                                    <ol type="a">
                                        <li>
                                            The Vendor must disclose in writing
                                            to Inglis in full in the Entry
                                            Details, any Lot which shows
                                            symptoms of having impaired vision
                                            such that it could be deemed unfit
                                            to race. Inglis will disclose this
                                            before or at the time of the sale.
                                        </li>
                                        <li>
                                            Excluding broodmares, if:
                                            <ol type="i">
                                                <li>
                                                    Any Lot proves within ten
                                                    (10) days after the Auction
                                                    End to have been sold with
                                                    impaired vision such that it
                                                    could be deemed unfit to
                                                    race, and
                                                </li>
                                                <li>
                                                    The Purchaser produces to
                                                    Inglis or Vendor written
                                                    certification obtained at
                                                    the cost of the Purchaser
                                                    that the Lot has impaired
                                                    vision, signed by an
                                                    independent veterinarian
                                                    approved by Inglis; and
                                                </li>
                                                <li>
                                                    Inglis or the Vendor did not
                                                    disclose prior to or at the
                                                    time of the sale that the
                                                    Lot had impaired vision,
                                                    then subject to clause 14.4,
                                                    the Purchaser may cancel its
                                                    purchase of the Lot by
                                                    giving Inglis written notice
                                                    of cancellation within ten
                                                    (10) days after the Auction
                                                    End.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Inglis reserves the right to obtain a
                                    confirmatory opinion by a panel of not more
                                    than three (3) veterinary surgeons appointed
                                    by Inglis before a notice of cancellation of
                                    sale under clauses 14.1, 14.2 or 14.3 is
                                    accepted by it, and if such panel
                                    unanimously denies the existence of the
                                    defect in the horse then, in the absence of
                                    agreement to the contrary by Inglis, the
                                    Purchaser shall not have any right to cancel
                                    the sale.
                                </li>
                                <li>
                                    In the Entry Details, the description
                                    &ldquo;Colt&rdquo; includes a
                                    &ldquo;Rig&rdquo; or
                                    &ldquo;Crypt-orchid&rdquo;, which
                                    expressions mean a male horse in which one
                                    or both testes have not descended into the
                                    scrotum from the abdomen. Any dispute on
                                    this issue shall be determined by Inglis,
                                    who shall be entitled (at the expense of the
                                    vendor) to obtain such veterinary advice on
                                    the issue as they consider necessary.
                                </li>
                                <li>
                                    Age of Broodmare
                                    <ol type="a">
                                        <li>
                                            (a) Mares aged 20 years and over who
                                            are not in foal will not be accepted
                                            into the sale. Mares that are aged
                                            22 years and over will not be
                                            accepted into the sale under any
                                            circumstances whether or not in
                                            foal.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    When a broodmare is sold as being &ldquo;in
                                    foal&rdquo; purchasers should note there is
                                    no guarantee that the stallion owner will
                                    offer a free return should the mare
                                    subsequently fail to produce a live foal.
                                    Purchasers are encouraged to make their own
                                    enquires prior to the lot being offered with
                                    the covering stallion&apos;s stud master.
                                    Neither the vendor or Inglis will be liable
                                    for any loss resulting in the failure of a
                                    stud to offer a free return.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                GOODS AND SERVICES TAX (GST)
                            </Typography>
                            <ol>
                                <li>
                                    Bidding will be on a GST exclusive basis.
                                    Where the Vendor of a Lot is registered for
                                    GST and the Lot is sold, then subject to
                                    condition 15.4 GST will be added to the
                                    final bid price.
                                </li>
                                <li>
                                    The Vendor will pay GST on all supplies
                                    provided by Inglis and on all supplies
                                    provided by Inglis as agent.
                                </li>
                                <li>
                                    Inglis is entitled to rely on all
                                    representations made to it by the Vendor
                                    regarding the Vendor&apos;s GST registration
                                    status.
                                </li>
                                <li>
                                    Where a Purchaser advises the Vendor and
                                    Inglis that the sale will be GST exempt (as
                                    a result of being an export sale within the
                                    meaning of section 38-185 A New Tax System
                                    (Goods and Services Tax) Act 1999) then the
                                    sale may, subject to the agreement of the
                                    Vendor, Purchaser and Inglis, proceed on a
                                    GST free basis. Any such Purchaser must make
                                    their own enquiries as to GST exemption
                                    requirements, and in claiming an exemption
                                    the Purchaser warrants to the Vendor and
                                    Inglis that all requirements necessary for
                                    the sale of the Lot to be GST exempt have
                                    been or will be met. The Purchaser must
                                    execute and provide all such documents as
                                    the Vendor or Inglis require.
                                </li>
                                <li>
                                    The Vendor and the Purchaser must do all
                                    things necessary to ensure that the
                                    provisions of the GST legislation are
                                    complied with in relation to each sale.
                                </li>
                                <li>
                                    Inglis shall not in any circumstances have
                                    any liability or obligation to the Vendor or
                                    the Purchaser in relation to GST, and
                                    specifically shall not be under any
                                    obligation to pay to the Vendor any amount
                                    in respect of GST on a sale unless and until
                                    Inglis has been paid the relevant GST by the
                                    Purchaser.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                INGLIS&rsquo; POSITION
                            </Typography>
                            <ol>
                                <li>
                                    If any sale is cancelled for any reason,
                                    Inglis shall remain entitled to receive its
                                    commission on the sale, and to be paid or
                                    reimbursed for all moneys owing to it by
                                    either the Vendor or the Purchaser, for any
                                    charges, dues (including stabling and yard
                                    dues, fees, including veterinary and entry
                                    fees) interest and any other moneys owing to
                                    Inglis.
                                </li>
                                <li>
                                    Inglis shall be entitled to appropriate and
                                    deduct from any money held or received by it
                                    from any source, such amounts as may be
                                    necessary to cover any commission or other
                                    moneys owing to it by the Vendor or the
                                    Purchaser. If Inglis does not hold any
                                    moneys on behalf of the Vendor, then any
                                    amounts owing to Inglis by the Vendor shall
                                    be paid by the Vendor to Inglis on demand.
                                </li>
                                <li>
                                    Inglis shall not have any liability either
                                    to the Vendor in consequence of any breach
                                    or default on the part of the Purchaser, or
                                    to the Purchaser in consequence of any
                                    breach or default on the part of the Vendor.
                                    Inglis&apos; liability to both the Vendor
                                    and the Purchaser for any negligent act or
                                    omission, or any breach or default on the
                                    part of Inglis, shall, to the extent that
                                    any such liability exists and is not
                                    effectively excluded by these terms and
                                    conditions of sale, be limited to the lesser
                                    of the party&apos;s actual direct loss or
                                    the sale price of the Lot. Under no
                                    circumstances shall Inglis be liable for any
                                    consequential loss.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                POWER OF ATTORNEY
                            </Typography>
                            <ol>
                                <li>
                                    Each of the Vendor and the Purchaser, for
                                    the purpose of enabling Inglis to give full
                                    force and effect to these terms and
                                    conditions of sale, hereby irrevocably
                                    appoint Inglis to be their attorney, with
                                    full powers to Inglis to execute on their
                                    behalf any necessary documents, to give all
                                    necessary instructions, and to do all such
                                    other acts and things as may be necessary to
                                    permit Inglis to give full force and effect
                                    to these conditions of sale.
                                </li>
                                <li>
                                    In the event of default under these
                                    conditions of sale by either the Vendor or
                                    the Purchaser, the Vendor or Purchaser
                                    (whichever is applicable) hereby irrevocably
                                    appoints Inglis as its Power of Attorney to
                                    enforce any right under these conditions of
                                    sale, to take all action and do all things
                                    necessary (including to execute on their
                                    behalf any necessary documents) to protect
                                    Inglis&apos; interest in the Lot.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                WORK HEALTH AND SAFETY
                            </Typography>
                            <ol>
                                <li>
                                    To the extent that a Vendor or a Purchaser
                                    or their employees or agents may conduct any
                                    activities on Inglis&apos; premises then
                                    that Vendor or Purchaser shall be
                                    responsible, to the exclusion of Inglis, for
                                    establishing and observing appropriate
                                    occupational health and safety procedures,
                                    and for complying with any relevant
                                    legislation provisions, in relation to those
                                    activities.
                                </li>
                                <li>
                                    A Vendor or Purchaser conducting any
                                    activities on the premises of Inglis does so
                                    at his own risk in all respects and must
                                    indemnify Inglis in respect of any claims
                                    for personal injury or damage to property
                                    which may be made against Inglis arising out
                                    of any such activities.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">GENERAL</Typography>
                            <ol>
                                <li>
                                    Any variation to these terms and conditions
                                    must be in writing and signed by or on
                                    behalf of the parties intended to be bound
                                    by the variation.
                                </li>
                                <li>
                                    If any provision in these terms and
                                    conditions is or becomes unenforceable or
                                    invalid, the remaining provisions shall not
                                    be affected but shall remain in full force
                                    and effect to the fullest extent permitted
                                    by law.
                                </li>
                                <li>
                                    All conditions, guarantees and warranties
                                    expressed or implied other than those
                                    expressly contained in these terms and
                                    conditions are excluded from the sale to the
                                    extent permissible by law.
                                </li>
                                <li>
                                    To the extent that section 102 of the
                                    Australian Consumer Law (&ldquo;ACL&rdquo;)
                                    may be applicable we advise that the ACL
                                    provides the following prescribed wording is
                                    to be included in these terms and
                                    conditions: &ldquo;Our goods come with
                                    guarantees that cannot be excluded under the
                                    Australian Consumer Law. You are entitled to
                                    a replacement or refund for a major failure
                                    and for compensation for any other
                                    reasonably foreseeable loss or damage. You
                                    are also entitled to have the goods repaired
                                    or replaced if the goods fail to be of
                                    acceptable quality and the failure does not
                                    amount to a major failure&rdquo;.
                                </li>
                                <li>
                                    The ACL guarantees as to acceptable quality
                                    and fitness for purpose do not apply to
                                    sales by auction.
                                </li>
                                <li>
                                    FOR SALES CONDUCTED IN THE STATE OF NEW
                                    SOUTH WALES
                                    <ol type="a">
                                        <li>
                                            The provisions of the Property Stock
                                            and Business Agents Act 2002 (NSW),
                                            the Sale of Goods Act 1923 (NSW),
                                            the Crimes Act 1900 (NSW), the Fair
                                            Trading Act 1987 (NSW), any
                                            equivalent legislation in other
                                            States and Territories, the
                                            Competition and Consumer Act 2010
                                            (Cth) and any regulations under this
                                            legislation shall apply to each
                                            sale, save to the extent that any
                                            provisions of these statutes are
                                            validly excluded from these terms
                                            and conditions of sale
                                        </li>
                                        <li>
                                            These terms and conditions of sale
                                            are to be construed and shall take
                                            effect in accordance with the laws
                                            of New South Wales. All parties
                                            agree to consent to the
                                            non-exclusive jurisdiction of the
                                            Courts of New South Wales.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    FOR SALES CONDUCTED IN THE STATE OF VICTORIA
                                    <ol type="a">
                                        <li>
                                            The provisions of the Estate Agents
                                            Act 1980 (VIC), the Goods Act 1958
                                            (VIC), the Crimes Act 1958 (VIC),
                                            the Fair Trading Act, 1985 (VIC),
                                            the Competition and Consumer Act
                                            2010 (Cth) and any regulations under
                                            this legislation shall apply to each
                                            sale, save to the extent that any
                                            provisions of these Statutes are
                                            validly excluded by these terms
                                            conditions.
                                        </li>
                                        <li>
                                            The terms and conditions of sale are
                                            to be construed and shall take
                                            effect in accordance with the laws
                                            of Victoria. All parties agree to
                                            consent to the non-exclusive
                                            jurisdiction of the Courts of
                                            Victoria.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="h4">
                                THE USE OF THIS WEBSITE IS SUBJECT TO THE
                                FOLLOWING TERMS OF USE
                            </Typography>
                            <ul>
                                <li>
                                    The content of the pages of this website is
                                    for your general information and use only.
                                    It is subject to change without notice.
                                </li>
                                <li>
                                    Neither we nor any third parties provide any
                                    warranty or guarantee as to the accuracy,
                                    timeliness, performance, completeness or
                                    suitability of the information and materials
                                    found or offered on this website for any
                                    particular purpose. You acknowledge that
                                    such information and materials may contain
                                    inaccuracies or errors and we expressly
                                    exclude liability for any such inaccuracies
                                    or errors to the fullest extent permitted by
                                    law.
                                </li>
                                <li>
                                    Your use of any information or materials on
                                    this website is entirely at your own risk,
                                    for which we shall not be liable. It shall
                                    be your own responsibility to ensure that
                                    any products, services or information
                                    available through this website meet your
                                    specific requirements.
                                </li>
                                <li>
                                    This website contains material which is
                                    owned by or licensed to us. This material
                                    includes, but is not limited to, the design,
                                    layout, look, appearance and graphics.
                                    Reproduction is prohibited other than in
                                    accordance with the copyright notice or
                                    explicit written permission from Inglis.
                                </li>
                                <li>
                                    All trademarks reproduced in this website,
                                    which are not the property of, or licensed
                                    to the operator, are acknowledged on the
                                    website.
                                </li>
                                <li>
                                    Unauthorised use of this website may give to
                                    a claim for damages and/or be a criminal
                                    offence.
                                </li>
                                <li>
                                    From time to time this website may also
                                    include links to other websites. These links
                                    are provided for your convenience to provide
                                    further information. They do not signify
                                    that we endorse the website(s). We have no
                                    responsibility for the content of the linked
                                    website(s).
                                </li>
                                <li>
                                    By uploading content to inglis.com.au you
                                    hereby grant Inglis an irrevocable,
                                    non-exclusive transferable, royalty free
                                    license to use the photograph and other
                                    content in all forms of media and throughout
                                    the world in perpetuity for any kind of use
                                    whatsoever, including for purposes of
                                    advertising and promoting our services, and
                                    including rights to print, reproduce, edit,
                                    reuse, publish the photograph and other
                                    content in whole or in part, individually or
                                    in connection with other images or material
                                    of any kind and to permit use to other
                                    non-Inglis organisations in order to promote
                                    an Inglis auction or event.
                                </li>
                                <li>
                                    Your use of this website and any dispute
                                    arising out of such use of the website is
                                    subject to the laws of the Commonwealth of
                                    Australia and New South Wales .
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography variant="h4">
                                INTERPRETATION AND DEFINITIONS
                            </Typography>
                            <br />
                            In these conditions words have the following
                            meaning:
                            <br />
                            &quot;Authorisation&quot; means any applicable
                            consent, approval, permit, registration or other
                            authorisation given or issued by any applicable
                            Regulatory Authority or any other person.
                            <br />
                            &quot;Auction&quot; means the auction of a Lot
                            conducted by Inglis through the Site.
                            <br />
                            &quot;Auction End&quot; has the meaning given to it
                            in clause 3.3.
                            <br />
                            &quot;Catalogue&quot; means the list containing the
                            Lots available for auction.
                            <br />
                            &ldquo;Conditions&rdquo; and &ldquo;conditions of
                            sale&rdquo; means these conditions of sale.
                            <br />
                            &quot;Confirmation of Sale&quot; has the meaning
                            given to it in clause 5.6.
                            <br />
                            &ldquo;Entry Details&rdquo; means the details of a
                            Lot provided to Inglis by the Vendor for inclusion
                            in the Catalogue and published in the Lot&apos;s
                            listing.
                            <br />
                            &ldquo;PMSI&rdquo; means a Purchase Money Security
                            Interest as that term is defined in the PPS Act
                            <br />
                            &ldquo;PPS Act&rdquo; means the Personal Property
                            Securities Act 2009 (Cth) as amended from time to
                            time. &ldquo;PPSR&rdquo; means the Personal Property
                            Securities Register.
                            <br />
                            &quot;Purchaser&quot; has the meaning given in
                            clause 5.1.
                            <br />
                            &quot;Regulatory Authority&quot; means:
                            <ol type="a">
                                <li>
                                    any applicable government or local authority
                                    and any department, minister or agency of
                                    any government; and
                                </li>
                                <li>
                                    any other applicable authority, agency,
                                    commission or similar entity having powers
                                    or jurisdiction under any law or regulation
                                    or listing rules of any prescribed financial
                                    market.
                                </li>
                            </ol>
                            &ldquo;Roarer&rdquo; means a Lot demonstrating
                            laryngeal neurophy (grossly deficient abductor
                            function of one or both arytenoid cartilages as
                            defined by grades 4 or 5 &ndash; Lane JG.
                            Bain-Fallon Proceedings 1993) on laryngoscopic
                            examination.
                            <br />
                            &ldquo;Secured Party&rdquo; means a party holding a
                            Security Interest affecting a Lot. &ldquo;Security
                            Interest&rdquo; means a security interest as that
                            term is defined in the PPS Act
                            <br />
                            &ldquo;Windsucker&rdquo; means the vice of noisily
                            drawing in and swallowing air by a contraction of
                            the ventral neck muscles, arching of the neck and
                            retraction of the larynx.
                            <br />
                            &ldquo;Wobbler&rdquo; means a Lot exhibiting ataxia
                            and proprioceptive defects as a result of cervical
                            vertebral stenosis. References to &ldquo;a gender
                            shall include each other gender as appropriate in
                            the circumstances.
                        </li>
                    </Typography>
                </ol>
            </Grid>
        </Grid>
    );
}
