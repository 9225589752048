import { Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { INGLIS_SCROLL_CONST } from '../../utls/constants';
import { CatalogueTiles } from '../contentComponents/catalogueTiles';

interface CurrentAndUpcomingSalesComponentProps {
    currentSales: any[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        currentUpcomingSales: {
            backgroundColor: theme.palette.grey[500],
            padding: theme.spacing(3, 0)
        },
        sectionTitle: {
            textAlign: 'center',
            'z-index': '1'
        },
        saleTileCollection: {
            display: 'flex',
            padding: '0 18.7%',
            overflow: 'hidden',
            margin: theme.spacing(2, 0),
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            [theme.breakpoints.down('md')]: {
                overflow: 'auto',
                padding: theme.spacing(0, 5),
                '&::-webkit-scrollbar': {
                    display: 'block'
                }
            }
        },
        inglisScrollButtonZone: {
            display: 'flex',
            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
            width: '75%',
            justifyContent: 'space-between',
            top: '330px',
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        inglisScrollButton: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[700],
            padding: '8px',
            borderRadius: '100%',
            border: `1px solid ${theme.palette.grey[600]}`,
            cursor: 'pointer'
        },
        sectionLink: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            textAlign: 'center'
        }
    })
);

export const CurrentAndUpcomingSalesComponent = (
    props: CurrentAndUpcomingSalesComponentProps
) => {
    const { currentSales } = props;
    const classes = useStyles();
    const [currScroll, setCurrScroll] = useState<boolean>(false);
    useEffect(() => {
        inglisScrollCheck();
    }, [currentSales]);

    const inglisScrollCheck = () => {
        const scrollArea = document.getElementById('currSalesArea');
        const shouldEnableScrollArea =
            scrollArea!.scrollWidth > scrollArea!.offsetWidth;
        setCurrScroll(shouldEnableScrollArea);
    };

    const scrollAreaLeft = (section: string) => {
        const divArea = document.getElementById(section);
        if (divArea!.scrollLeft > 0) {
            divArea!.scrollLeft =
                divArea!.scrollLeft -
                divArea!.offsetWidth * INGLIS_SCROLL_CONST;
        }
    };

    const scrollAreaRight = (section: string) => {
        const divArea = document.getElementById(section);
        divArea!.scrollLeft =
            divArea!.scrollLeft + divArea!.offsetWidth * INGLIS_SCROLL_CONST;
    };

    return (
        <Grid item xs={12} className={classes.currentUpcomingSales}>
            <Typography
                className={classes.sectionTitle}
                variant="h2"
                color="secondary"
            >
                Current and Upcoming
            </Typography>
            <div id="currSalesArea" className={classes.saleTileCollection}>
                {currentSales.map((item: any) => {
                    return (
                        <CatalogueTiles
                            saleCode={item.saleCode}
                            saleTitle={item.saleName}
                            saleStartDate={item.saleStartDate}
                            saleEndDate={item.saleEndDate}
                            saleLocation={item.saleVenue}
                            saleImage={item.catalogueImage}
                            tileType="CurrentUpcoming"
                        />
                    );
                })}
                {currScroll && (
                    <div className={classes.inglisScrollButtonZone}>
                        <IconButton
                            onClick={() => scrollAreaLeft('currSalesArea')}
                        >
                            <KeyboardArrowLeft
                                className={classes.inglisScrollButton}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => scrollAreaRight('currSalesArea')}
                        >
                            <KeyboardArrowRight
                                className={classes.inglisScrollButton}
                            />
                        </IconButton>
                    </div>
                )}
            </div>
            <Typography className={classes.sectionLink} variant="body2">
                View Sale Calendar
            </Typography>
        </Grid>
    );
};
