import React, { useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core/';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import data from '../../utls/footer.json';
import { ReactComponent as FacebookIcon } from '../../icons/Facebook - Negative.svg';
import { ReactComponent as InstaIcon } from '../../icons/Instagram - Negative.svg';
import { ReactComponent as TwitterIcon } from '../../icons/Twitter - Negative.svg';

type inglisFooter = {
    appState: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
            color: 'white',
            position: 'absolute',
            bottom: '0',
            height: '180px',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                padding: '10px',
                boxSizing: 'border-box'
            }
        },
        mainLogo: {
            height: '32px',
            '& path': {
                fill: 'white'
            }
        },
        menuButton: {
            marginRight: theme.spacing(1)
        },
        footerContent: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '30px',
            flexWrap: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                'flex-direction': 'column',
                '& div': {
                    marginBottom: '15px'
                }
            }
        },
        lowerFooterContainer: {
            marginTop: '15px',
            paddingTop: '15px'
        },
        lowerFooterContent: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                'flex-direction': 'column',
                height: '100%'
            }
        },
        socialsSection: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '15%',
            [theme.breakpoints.down('sm')]: {
                width: '40%'
            }
        },
        footerlinks: {
            marginBottom: '15px'
        },
        amplifySignOutStyle: {
            '--amplify-primary-color': theme.palette.primary.main,
            '--amplify-primary-tint': theme.palette.secondary.main,
            '--amplify-primary-shade': theme.palette.secondary.main,
            '--amplify-font-family': theme.typography.fontFamily
        }
    })
);

export const FooterNav = (props: inglisFooter) => {
    const { appState } = props;
    const classes = useStyles();
    const theme = useTheme<Theme>();

    useEffect(() => {
        const globalNavComponent = document.getElementById('inglis-footer');
        if (appState === 1) {
            globalNavComponent!.style.backgroundColor = 'black';
        } else {
            globalNavComponent!.style.backgroundColor =
                theme.palette.secondary.main;
        }
    }, [appState]);

    return (
        <footer id="inglis-footer" className={classes.root}>
            <Box>
                <Container maxWidth="xl">
                    <Grid container className={classes.footerContent}>
                        <div className={classes.lowerFooterContent}>
                            {data.locations.map((item) => {
                                return (
                                    <Grid item xs={10} sm={4} key={item.name}>
                                        <Typography variant="body2">
                                            {item.name}
                                        </Typography>
                                        <Typography>{item.address}</Typography>
                                    </Grid>
                                );
                            })}
                        </div>
                        <div className={classes.socialsSection}>
                            <Grid item xs={10} sm={4}>
                                <FacebookIcon className={classes.mainLogo} />
                            </Grid>
                            <Grid item xs={10} sm={4}>
                                <TwitterIcon className={classes.mainLogo} />
                            </Grid>
                            <Grid item xs={10} sm={4}>
                                <InstaIcon className={classes.mainLogo} />
                            </Grid>
                        </div>
                    </Grid>
                    <Box borderTop={1} className={classes.lowerFooterContainer}>
                        <Grid container>
                            <Grid
                                item
                                xs={10}
                                sm={4}
                                className={classes.lowerFooterContent}
                            >
                                {data.links.map((item) => {
                                    return (
                                        <a href={item.url} key={item.url}>
                                            <Typography
                                                className={classes.footerlinks}
                                                variant="body2"
                                            >
                                                {item.text}
                                            </Typography>
                                        </a>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </footer>
    );
};
