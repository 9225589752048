import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './App.css';
import './pedigree_report.css';
import { Auth, Hub } from 'aws-amplify';
import { Amplify } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core/';
import GlobalNav from './components/global-nav/globalNav';
import { FooterNav } from './components/footer/footer';
import { globalConfig } from './globalConfig';
import Routes from './components/routes/routes';

Amplify.configure(globalConfig.amplify);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            backgroundColor: theme.palette.grey[200],
            position: 'relative',
            minHeight: '100vh',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.grey[200]
            }
        },
        root: {
            '--amplify-primary-color': theme.palette.primary.main,
            '--amplify-primary-tint': theme.palette.secondary.main,
            '--amplify-primary-shade': theme.palette.secondary.main,
            '--amplify-font-family': theme.typography.fontFamily,
            paddingBottom: '180px',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '450px'
            }
        }
    })
);

const App = () => {
    const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
    const [appState, setAppState] = useState<number>(0);
    const [user, setUser] = useState(null);
    const history = useHistory();
    const authRef = useRef({});
    const classes = useStyles();
    authRef.current = authState;

    useEffect(() => {
        const loadedLocation = history.location.pathname.split('/');

        if (loadedLocation.length > 0) {
            const state = loadedLocation[1] === 'digital' ? 1 : 0;
            setAppState(state);
            setInglisState(state);
        }
        const updateUser = async () => {
            try {
                const userValue = await Auth.currentAuthenticatedUser();
                setUser(userValue);
            } catch {
                setUser(null);
            }
        };
        onAuthUIStateChange((nextAuthState) => {
            setAuthState(nextAuthState);
            const prevLoginLocation =
                sessionStorage.getItem('loginPrevLocation');
            if (authRef.current === AuthState.SignedIn) {
                if (prevLoginLocation) {
                    history.push(prevLoginLocation);
                } else {
                    history.push('/');
                }
            }
        });
        Hub.listen('auth', updateUser);
        updateUser();
        return () => Hub.remove('auth', updateUser);
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            const userLocation = location.pathname.split('/');
            if (userLocation.length > 0) {
                if (userLocation[1] === 'digital') {
                    setAppState(1);
                } else {
                    setAppState(0);
                }
            }
        });
    }, [history]);

    const siteToIndex: any = {
        0: 'inglis',
        1: 'inglisdigital'
    };

    const setInglisState = (val: number) => {
        setAppState(val);
        sessionStorage.setItem('siteState', siteToIndex[val]);
    };

    return (
        <div className={classes.app}>
            <div className={classes.root}>
                <GlobalNav
                    authedState={!!user}
                    setInglisAppState={setInglisState}
                    appState={appState}
                />
                <Container disableGutters maxWidth="xl">
                    <Routes authedState={!!user} />
                </Container>
            </div>
            <FooterNav appState={appState} />
        </div>
    );
};

export default App;
