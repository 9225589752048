import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { GA_TRACKING_ID } from '../utls/constants';

const TRACKING_ID = GA_TRACKING_ID;

function init() {
    ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload: UaEventOptions) {
    ReactGA.event(payload);
}

function sendPageview(path: string) {
    ReactGA.send({ hitType: 'pageview', page: path });
}

export default {
    init,
    sendEvent,
    sendPageview
};
