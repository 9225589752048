import React from 'react';
import {
    AmplifyAuthenticator,
    AmplifyForgotPassword
} from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';

export default function AuthForgotPasswordPage() {
    return (
        <AmplifyAuthenticator initialAuthState={AuthState.ForgotPassword}>
            <AmplifyForgotPassword
                usernameAlias="email"
                slot="forgot-password"
            />
        </AmplifyAuthenticator>
    );
}
