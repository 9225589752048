import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import LandingPage from '../landing-page/landingPage';
import { ChangePassword } from '../change-password/changePassword';
import TermsAndConditions from '../publicPages/termsAndConditions';
import PrivacyPolicy from '../publicPages/privacyPolicy';
import { MainLotDetailsPage } from '../publicPages/lotDetail';
import { MainDashboardPage } from '../publicPages/dashboard';
import AuthenticatePage from '../authenticate-pages/authenticate-page';
import { AuthSignUpPage } from '../authenticate-pages/signUp';
import AuthForgotPasswordPage from '../authenticate-pages/forgotPassword';
import { SalesHomepage } from '../publicPages/physicalSales';
import { InglisHomePage } from '../publicPages/homepage';
import { DigitalInglisHomePage } from '../publicPages/digitalHomePage';
import { SaleOverview } from '../publicPages/saleOverview';
import { PastSalesPage } from '../publicPages/pastSalesPage';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import { AccountDetails } from '../authenticate-pages/accountDetails';

interface routesProps {
    authedState: boolean;
}

export const routes = [
    {
        name: 'signin',
        path: '/signin',
        text: 'Sign In',
        component: AuthenticatePage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'signup',
        path: '/signup',
        text: 'Sign Up',
        component: AuthSignUpPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'passwordreset',
        path: '/password-reset',
        text: 'Password Reset',
        component: AuthForgotPasswordPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'accountdetails',
        path: '/account-details/:tab',
        text: 'Account Details',
        component: AccountDetails,
        // todo: need to change this to private in future
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'Home',
        path: '/',
        text: 'Home',
        component: InglisHomePage,
        type: 'public',
        exact: true,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'sales',
        path: '/salesandresults',
        text: 'Sales & Results',
        component: SalesHomepage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: 'Sales Calendar',
                path: '/privacypolicy',
                pathtype: 'internal'
            },
            {
                text: 'Current & Upcoming',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Past Results',
                path: '/pastsales',
                pathtype: 'internal'
            },
            {
                text: 'X-Ray Repository',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Online Bidding',
                path: '/',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'raceseries',
        path: '/raceseries',
        text: 'Race Series',
        component: LandingPage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: '2021 Graduates',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: '2020 Graduates',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: '2019 Graduates',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: '2018 Graduates',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Inglis Race Series',
                path: '/',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'salesoverview',
        path: '/sales/:saleCode/:saleName/:tabs',
        text: 'Sales Overview',
        component: SaleOverview,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        text: 'Dashboard',
        component: MainDashboardPage,
        type: 'private',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'newsandmedia',
        path: '/newsandmedia',
        text: 'News & Media',
        component: LandingPage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: 'Our Magazines',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Latest News',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Inglis TV',
                path: '/',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'services',
        path: '/services',
        text: 'Services',
        component: LandingPage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: 'Our Magazines',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Latest News',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Inglis TV',
                path: '/',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'aboutus',
        path: '/about',
        text: 'About Us',
        component: LandingPage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: 'Company Overview',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Staff & Directors',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Riverside Stables Complex',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Oaklands Complex',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Jobs at Inglis',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Our partners',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Contact Us',
                path: '/',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'info',
        path: '/info',
        text: 'Info',
        component: LandingPage,
        type: 'public',
        exact: true,
        display: true,
        sublinks: [
            {
                text: 'Buyer Info',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Conditions of sale',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Payment',
                path: '/',
                pathtype: 'internal'
            },
            {
                text: 'Dashboard',
                path: '/dashboard',
                pathtype: 'internal'
            }
        ],
        pathtype: 'internal'
    },
    {
        name: 'lots',
        path: '/:catSaleCode/lots/:id',
        text: 'Lots',
        component: MainLotDetailsPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'termsandconditions',
        path: '/termsandconditions',
        text: 'Terms And Conditions',
        component: TermsAndConditions,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'privacypolicy',
        path: '/privacypolicy',
        text: 'Privacy Policy',
        component: PrivacyPolicy,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'pastsales',
        path: '/pastsales',
        text: 'Past Sales',
        component: PastSalesPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    }
];

export const digitalRoutes = [
    {
        name: 'signin',
        path: '/signin',
        text: 'Sign In',
        component: AuthenticatePage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'signup',
        path: '/signup',
        text: 'Sign Up',
        component: AuthSignUpPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'forgotpassword',
        path: '/forgotpassword',
        text: 'Forgot Password',
        component: AuthForgotPasswordPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'updatepassword',
        path: '/updatepassword',
        text: 'Update Password',
        component: ChangePassword,
        type: 'private',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'inglisDigitalHome',
        path: '/digital',
        text: 'Home',
        component: DigitalInglisHomePage,
        type: 'public',
        exact: true,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'lots',
        path: '/digital/:catSaleCode/lots/:id',
        text: 'Lots',
        component: MainLotDetailsPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'digitalsalesoverview',
        path: '/digital/sales/:saleCode/:saleName/:tabs',
        text: 'Sales Overview',
        component: SaleOverview,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'enterHorse',
        path: '/enterhorse',
        text: 'Enter A Horse',
        component: MainDashboardPage,
        type: 'private',
        exact: false,
        display: true,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'bidregister',
        path: '/bidregister',
        text: 'Register To Bid',
        component: TermsAndConditions,
        type: 'public',
        exact: false,
        display: true,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'privacypolicy',
        path: '/privacypolicy',
        text: 'Privacy Policy',
        component: PrivacyPolicy,
        type: 'public',
        exact: false,
        display: true,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'contact',
        path: '/contact',
        text: 'Contact',
        component: PrivacyPolicy,
        type: 'public',
        exact: false,
        display: true,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'info',
        path: '/info',
        text: 'Info',
        component: PrivacyPolicy,
        type: 'public',
        exact: false,
        display: true,
        sublinks: [],
        pathtype: 'internal'
    },
    {
        name: 'pastsalesdigital',
        path: '/digital/pastsales',
        text: 'Past Sales',
        component: PastSalesPage,
        type: 'public',
        exact: false,
        display: false,
        sublinks: [],
        pathtype: 'internal'
    }
];

export default function Routes({ authedState }: routesProps) {
    useGoogleAnalytics();
    return (
        <Switch>
            {routes.map((route) => {
                return route.type === 'public' ? (
                    <Route
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        key={route.path}
                    />
                ) : (
                    <PrivateRoute
                        authed={authedState}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        key={route.path}
                    />
                );
            })}
            {digitalRoutes.map((route) => {
                return route.type === 'public' ? (
                    <Route
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        key={route.path}
                    />
                ) : (
                    <PrivateRoute
                        authed={authedState}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        key={route.path}
                    />
                );
            })}
        </Switch>
    );
}

type PrivateRouteProps = RouteProps & {
    authed: boolean;
    component: FunctionComponent;
};
const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: NavContainer,
    authed,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authed ? (
                    <NavContainer {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
