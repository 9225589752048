// Apollo Settings
import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    createHttpLink,
    NormalizedCacheObject,
    split
} from '@apollo/client';

// AppSync
import { Auth } from 'aws-amplify';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { getMainDefinition } from '@apollo/client/utilities';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { globalConfig } from '../globalConfig';

export type CreateAppSyncApolloClientProps = {
    endpoint: string;
    region?: string;
    authenticationType?: 'AWS_IAM' | 'AMAZON_COGNITO_USER_POOLS' | 'API_KEY';
    apiKey?: string;
};

/**
 * Creates ApploClient instance and link it with AWS AppSync using the given auth type.
 * @param CreateAppSyncApolloClientProps
 * @returns ApolloClient
 */
export const CreateAppSyncApolloClient = ({
    endpoint,
    ...props
}: CreateAppSyncApolloClientProps): ApolloClient<NormalizedCacheObject> => {
    const region = props.region || globalConfig.amplify.region;
    const authenticationType =
        props.authenticationType || 'AMAZON_COGNITO_USER_POOLS';

    const cognitoAuth = {
        type: authenticationType,
        jwtToken: async () =>
            (await Auth.currentSession()).getIdToken().getJwtToken()
    } as AuthOptions;

    const iamAuth = {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials()
    } as AuthOptions;

    const apiKeyAuth = {
        type: AUTH_TYPE.API_KEY,
        apiKey: props.apiKey
    } as AuthOptions;

    let auth = iamAuth;
    if (authenticationType === AUTH_TYPE.AMAZON_COGNITO_USER_POOLS) {
        auth = cognitoAuth;
    }

    if (authenticationType === AUTH_TYPE.API_KEY && props.apiKey) {
        auth = apiKeyAuth;
    }
    const authLink = createAuthLink({ url: endpoint, region, auth });
    const httpLink = createHttpLink({ uri: endpoint });
    const wsLink = createSubscriptionHandshakeLink({
        url: endpoint,
        region,
        auth
    });
    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        ApolloLink.from([wsLink]),
        ApolloLink.from([authLink, httpLink])
    );

    const client = new ApolloClient({
        link,

        cache: new InMemoryCache()
    });
    return client;
};
