import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    purchaseLimit: yup
        .number()
        .required('Purchase Limit is requireed.')
        .positive('Amount should be positive number.')
});

export const BiddingApprovalRequestForm = () => {
    const formik = useFormik({
        initialValues: {
            purchaseLimit: 0
        },
        validationSchema,
        onSubmit: (values) => {
            // TODO: save purchase limit in the backend
        }
    });
    return (
        <div style={{ marginTop: '30px', marginLeft: '30px' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Requested Purchase Limit*
                        </Typography>
                    </Grid>
                    <Grid>
                        <TextField
                            id="purchaseLimit"
                            name="purchaseLimit"
                            variant="outlined"
                            size="small"
                            value={formik.values.purchaseLimit}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.purchaseLimit &&
                                Boolean(formik.touched.purchaseLimit)
                            }
                            helperText={
                                formik.touched.purchaseLimit &&
                                formik.errors.purchaseLimit
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
