import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type LotJumperProps = {
    saleCode: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        numField: {
            '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                {
                    '-webkit-appearance': 'none',
                    margin: 0
                },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            }
        }
    })
);

export const LotJumpComponent = (props: LotJumperProps) => {
    const classes = useStyles();
    const history: any = useHistory();
    const { saleCode } = props;
    const [selectedLot, setLotValue] = useState<string>('');
    const siteState = sessionStorage.getItem('siteState');

    const jumpToLot = async (lotValue: string) => {
        try {
            if (lotValue) {
                const lotJumpValid = await API.get(
                    'rest-api',
                    `catalogue/${saleCode}/${lotValue}/overview`,
                    {
                        response: true,
                        responseType: 'text'
                    }
                )
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        return error.response;
                    });
                if (lotJumpValid.status === 200) {
                    if (siteState === 'inglis') {
                        history.push(`/${saleCode}/lots/${lotValue}`);
                        history.go(0);
                    } else {
                        history.push(`/digital/${saleCode}/lots/${lotValue}`);
                        history.go(0);
                    }
                } else {
                    alert('Lot does not exist');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{ width: '35%' }}>
            <TextField
                id="lot-jump-field"
                className={classes.numField}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        jumpToLot(selectedLot);
                    }
                }}
                onChange={(
                    event: React.ChangeEvent<{
                        value: string;
                    }>
                ) => {
                    setLotValue(event.target.value);
                }}
                type="number"
                variant="outlined"
                size="small"
            />
        </div>
    );
};
