import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core/';
import { useHistory } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { saleUrlFormatter } from '../../utls/formatHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        salesGrid: {
            marginTop: '30px',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                overflow: 'auto'
            }
        },
        saleTile: {
            display: 'flex',
            flexDirection: 'column',
            width: '232px',
            height: '308px',
            justifyContent: 'space-between',
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '4px',
            padding: theme.spacing(3),
            backgroundColor: theme.palette.grey[50],
            marginRight: theme.spacing(3)
        },
        saleTileIcon: {
            height: '18px'
        },
        firstTextZone: {
            display: 'flex',
            flexDirection: 'column',
            '& img': {
                margin: 'auto'
            },
            '& p': {
                marginTop: theme.spacing(2)
            }
        },
        secondaryTextZone: {
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[500]}`,
            borderBottom: `1px solid ${theme.palette.grey[500]}`,
            padding: theme.spacing(1, 0)
        },
        saleTileSecondaryText: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[700],
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap'
            }
        }
    })
);

export const SalesHomepage = () => {
    const classes = useStyles();
    const history = useHistory();

    const salesRedirect = () => {
        const saleStartDate = new Date(2021, 9, 1);
        const saleEndDate = new Date(2021, 9, 3);
        const saleUrl = saleUrlFormatter(
            '21R2R',
            '2021-Ready2Race-Sale',
            saleStartDate,
            saleEndDate
        );
        history.push(saleUrl);
    };

    const digitalSalesRedirect = () => {
        const saleStartDate = new Date(2021, 9, 1);
        const saleEndDate = new Date(2021, 9, 3);
        const saleUrl = saleUrlFormatter(
            '2109B',
            '2021-september-late-online-sale',
            saleStartDate,
            saleEndDate,
            'digital'
        );
        history.push(saleUrl);
    };

    return (
        <Grid container spacing={0}>
            <Grid xs={12} item className={classes.salesGrid}>
                <div className={classes.saleTile}>
                    <div className={classes.firstTextZone}>
                        <img
                            src="https://content.inglis.com.au/images/uploaded/sales/catalogues/medium/1631590024.f5b5da2e8a.jpg"
                            alt="Sales Poster"
                            height="150px"
                            width="104px"
                        />
                        <Typography variant="body2">
                            2021 Ready2Race Sale
                        </Typography>
                    </div>
                    <div className={classes.secondaryTextZone}>
                        <Typography
                            className={classes.saleTileSecondaryText}
                            variant="body1"
                        >
                            <EventIcon className={classes.saleTileIcon} />
                            26 Oct 2021
                        </Typography>
                        <Typography
                            className={classes.saleTileSecondaryText}
                            variant="body1"
                        >
                            <LocationOnOutlinedIcon
                                className={classes.saleTileIcon}
                            />
                            Riverside
                        </Typography>
                    </div>
                    <Button
                        onClick={salesRedirect}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        View Catalogue
                    </Button>
                </div>
                <div className={classes.saleTile}>
                    <div className={classes.firstTextZone}>
                        <img
                            src="https://content.inglis.com.au/images/uploaded/sales/catalogues/large/1632457781.887799e4b1.jpg"
                            alt="Sales Poster"
                            height="150px"
                            width="104px"
                        />
                        <Typography variant="body2">
                            2021 SEPTEMBER (Late) Online Sale
                        </Typography>
                    </div>
                    <div className={classes.secondaryTextZone}>
                        <Typography
                            className={classes.saleTileSecondaryText}
                            variant="body1"
                        >
                            <EventIcon className={classes.saleTileIcon} />
                            24, 29 Sep 2021
                        </Typography>
                        <Typography
                            className={classes.saleTileSecondaryText}
                            variant="body1"
                        >
                            <LocationOnOutlinedIcon
                                className={classes.saleTileIcon}
                            />
                            Online
                        </Typography>
                    </div>
                    <Button
                        onClick={digitalSalesRedirect}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        View Catalogue
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};
