import { Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridValueFormatterParams
} from '@material-ui/data-grid';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { makeStyles } from '@material-ui/styles';

import React from 'react';
import { ReactComponent as HorsePlaceHolder } from '../../icons/horsePlaceholder.svg';

import { ausDollarFormatter } from '../../utls/formatHelper';
import { CountdownComponent } from './countdownTimer';
import { ARDEXCLOUD_IMAGE_BASEURL } from '../../utls/constants';
import { mapLotStatus } from '../../utls/mapper';
import { BidNowPopOver } from './bidNowPopOver';

interface UserBidsOnCurrentSaleDashboardComponentProps {
    catalogueResults: any;
    saleName: string;
    saleCode: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeaderHeading: {
            color: theme.palette.secondary.main,
            marginBottom: '10px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px',
                paddingLeft: theme.spacing(2)
            }
        },
        bidderText: {
            fontSize: '12px !important',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            height: 'fit-content',
            padding: '2px 8px 2px 8px',
            width: '120px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            lineHeight: '15px'
        },
        highestBidder: {
            color: `${theme.palette.success.main} !important`,
            backgroundColor: theme.palette.success.light
        }
    })
);

export const UserBidsOnCurrentSaleDashboardComponent = (
    props: UserBidsOnCurrentSaleDashboardComponentProps
) => {
    const classes = useStyles();
    const { catalogueResults, saleName, saleCode } = props;
    const columns: GridColDef[] = [
        {
            field: '',
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4,
            renderCell: () => {
                return <FavoriteBorderIcon color="primary" />;
            }
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 65,
            renderCell: (params: GridCellParams) => {
                const idValue: any = params;
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            width: '100%'
                        }}
                    >
                        {idValue.id}
                    </div>
                );
            }
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            renderCell: (params: GridCellParams) => {
                const horseImageCollection: any = params;
                let horseThumbnail: any = (
                    <div
                        style={{
                            height: '48px',
                            width: '48px',
                            margin: 'auto'
                        }}
                    >
                        <HorsePlaceHolder />
                    </div>
                );
                if (Object.keys(horseImageCollection).length > 0) {
                    if (horseImageCollection.value.length > 0) {
                        horseThumbnail = (
                            <div
                                style={{
                                    height: '48px',
                                    width: '48px',
                                    margin: 'auto',
                                    backgroundImage: `url("${ARDEXCLOUD_IMAGE_BASEURL}${horseImageCollection.value[0].objectName}")`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                        );
                    }
                }
                return horseThumbnail;
            },
            width: 100
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            width: 65
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'lot_current_bid',
            headerName: 'Bid',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                let valueFormatted = 0;
                if (params.value) valueFormatted = Number(params.value);
                return ausDollarFormatter(valueFormatted);
            }
        },
        {
            field: 'lot_closing_time',
            headerName: 'Time Left',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 1.2,
            renderCell: (params: GridCellParams) => {
                const idValue: any = params;
                const rawTime = idValue.value;
                return <CountdownComponent remainingTime={rawTime} />;
            }
        },
        {
            field: 'bidStatus',
            headerName: 'Bid Status',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 1.2,
            renderCell: (params: GridCellParams) => {
                const bidValue: any = params;
                return (
                    <Typography
                        variant="body2"
                        className={`${classes.bidderText} ${classes.highestBidder}`}
                    >
                        {bidValue.value}
                    </Typography>
                );
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            width: 95,
            align: 'center',
            renderCell: (params: GridCellParams) => {
                const bidValue: any = params;
                return (
                    <BidNowPopOver
                        saleId={bidValue.id}
                        saleCode={saleCode}
                        horseName={catalogueResults.name}
                        handleMutatedData={mapLotStatus}
                    />
                );
            }
        }
    ];
    return (
        <div>
            {saleName ? (
                <Typography variant="h3" className={classes.subHeaderHeading}>
                    Current Bids - {saleName}
                </Typography>
            ) : (
                <Typography variant="h3" className={classes.subHeaderHeading}>
                    No Current Sale!
                </Typography>
            )}

            {Object.keys(catalogueResults).length > 0 ? (
                <DataGrid
                    rows={catalogueResults}
                    columns={columns}
                    getRowClassName={() => 'catalogue-table-rows'}
                    autoHeight
                    disableColumnMenu
                    rowsPerPageOptions={[]}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                />
            ) : (
                <div />
            )}
        </div>
    );
};
