import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    phone: yup.string().required('Phone is required'),
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    confirmEmail: yup
        .string()
        .email('Enter a valid email')
        .oneOf([yup.ref('email')], 'Email does not match.')
        .required('Confirm Email is required'),
    message: yup
        .string()
        .required('Message is required')
        .min(30, 'Message must be at least 30 characters long.')
});

const useStyles = makeStyles((materialTheme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        }
    })
);

export const VendorContactFormComponent = () => {
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            confirmEmail: '',
            message: ''
        },
        validationSchema,
        onSubmit: (values) => {
            // TODO: invoke backend service to send the email.
        }
    });

    return (
        <div
            style={{ marginBottom: '30px', width: '600px' }}
            className={classes.root}
        >
            <Typography color="primary" variant="h3">
                Enquiry Form
            </Typography>
            <br />
            <p>
                Use this from to send an enquiry about this lot to the vendor.
            </p>
            <br />
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={3}>
                        <Typography variant="body1">Your Name*</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            size="small"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">Your Phone*</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            size="small"
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.phone &&
                                Boolean(formik.errors.phone)
                            }
                            helperText={
                                formik.touched.phone && formik.errors.phone
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">Your Email*</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            size="small"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">Confirm Email*</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            size="small"
                            id="confirmEmail"
                            name="confirmEmail"
                            value={formik.values.confirmEmail}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.confirmEmail &&
                                Boolean(formik.errors.confirmEmail)
                            }
                            helperText={
                                formik.touched.confirmEmail &&
                                formik.errors.confirmEmail
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Message*</Typography>
                        <TextField
                            multiline
                            id="message"
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.message &&
                                Boolean(formik.touched.message)
                            }
                            helperText={
                                formik.touched.message && formik.errors.message
                            }
                            maxRows={10}
                            minRows={5}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
