import React from 'react';
import { Typography, Toolbar } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type PageBannerProps = {
    titleText: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerBanner: {
            backgroundColor: theme.palette.grey[100],
            height: '104px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '0',
            padding: '0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '24px',
                padding: theme.spacing(2, 0)
            }
        },
        headerContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1920px',
            '& img': {
                marginLeft: theme.spacing(4)
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'start',
                padding: theme.spacing(0),
                '& img': {
                    marginLeft: theme.spacing(2)
                }
            }
        },
        headerHeading: {
            color: theme.palette.secondary.main,
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px'
            }
        },
        headerTextZone: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '30px',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: 'auto',
                width: 'auto',
                padding: theme.spacing(0),
                alignItems: 'baseline',
                marginLeft: theme.spacing(2)
            }
        }
    })
);

export const PageBanner = (props: PageBannerProps) => {
    const classes = useStyles();
    const { titleText } = props;
    return (
        <div>
            <Toolbar className={classes.headerBanner}>
                <div className={classes.headerContent}>
                    <div className={classes.headerTextZone}>
                        <Typography
                            variant="h2"
                            className={classes.headerHeading}
                        >
                            {titleText}
                        </Typography>
                    </div>
                </div>
            </Toolbar>
        </div>
    );
};
