import moment from 'moment';
import {
    ARDEXCLOUD_IMAGE_BASEURL,
    INGLIS_VIDEO_BASEURL,
    INGLIS_IMAGE_BASEURL
} from './constants';

export const ausDollarFormatter = (value: number): string => {
    const formattedValue = Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        maximumFractionDigits: 0
    }).format(value);
    return formattedValue;
};

const buildSaleUrl = (
    saleCode: string,
    saleName: string,
    saleStartDate: Date,
    saleEndDate: Date,
    busUnit?: string
) => {
    let saleUrl = `/sales/${saleCode}/${urlStringFormatter(saleName)}`;
    sessionStorage.setItem('siteState', 'inglis');
    if (busUnit) {
        sessionStorage.setItem('siteState', 'inglisdigital');
        const today = moment();
        const isSaleRunning =
            today.isSameOrAfter(moment(saleStartDate)) &&
            today.isSameOrBefore(moment(saleEndDate));
        saleUrl = isSaleRunning
            ? `/${busUnit}/sales/current/${saleCode}/${urlStringFormatter(
                  saleName
              )}`
            : `/${busUnit}/sales/${saleCode}/${urlStringFormatter(saleName)}`;
    }
    return saleUrl;
};

const buildSaleOverviewUrl = (
    saleCode: string,
    saleName: string,
    busUnit?: string
) => {
    let saleUrl = `/sales/${saleCode}/${urlStringFormatter(saleName)}`;
    sessionStorage.setItem('siteState', 'inglis');
    if (busUnit) {
        sessionStorage.setItem('siteState', 'inglisdigital');
        saleUrl = `/${busUnit}/sales/${saleCode}/${urlStringFormatter(
            saleName
        )}`;
    }
    return saleUrl;
};

export const saleUrlFormatter = (
    saleCode: string,
    saleName: string,
    saleStartDate: Date,
    saleEndDate: Date,
    busUnit?: string
): string => {
    return `${buildSaleUrl(
        saleCode,
        saleName,
        saleStartDate,
        saleEndDate,
        busUnit
    )}/lots`;
};

export const saleOverviewUrlFormatter = (
    saleCode: string,
    saleName: string,
    busUnit?: string
): string => {
    return `${buildSaleOverviewUrl(saleCode, saleName, busUnit)}/overview`;
};

export const urlStringFormatter = (value: string): string => {
    const urlFormattedString = value.replace(/\s+/g, '-');
    return urlFormattedString;
};

export const physicalImageUrlFormatter = (
    imageSize: string,
    imageName: string
): string => {
    return `${INGLIS_IMAGE_BASEURL}${imageSize}/${imageName}`;
};

export const lotVideoUrlFormatter = (
    siteLocation: string,
    saleCode: string,
    videoName: string
): string => {
    return siteLocation === 'inglis'
        ? `${INGLIS_VIDEO_BASEURL}${saleCode}/${videoName}`
        : `${ARDEXCLOUD_IMAGE_BASEURL}${videoName}`;
};

export const epochToDate = (epoch: number): Date => {
    return moment.utc(epoch).toDate();
};

export const dateFormat = (dateEnd: any): number => {
    const endDate = moment(dateEnd, 'DD-MM-YYYY');
    const currentTime = moment();
    const timeDifference = moment.duration(endDate.diff(currentTime));
    return timeDifference.asMilliseconds();
};

export const timeRemainingReturn = (dateEnd: any): string => {
    const timeDifference = dateFormat(epochToDate(Number(dateEnd)));
    if (dateEnd === '' || !dateEnd || timeDifference <= 0) {
        return 'Ended';
    }
    const timeRemaining = moment.duration(timeDifference, 'milliseconds');
    const remainingDays = timeRemaining.days();
    const remainingHours = timeRemaining.hours();
    const remainingMinutes = timeRemaining.minutes();
    const remainingSeconds = timeRemaining.seconds();
    if (remainingDays > 0) {
        return `${remainingDays}d ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    }
    if (remainingHours > 0) {
        return `${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    }
    if (remainingMinutes > 0) {
        return `${remainingMinutes}m ${remainingSeconds}s`;
    }
    // there will always be remaining seconds left otherwise it will be returned Ended from top already
    return `${remainingSeconds}s`;
};
