import React, { useState, useEffect } from 'react';
import { timeRemainingReturn } from '../../utls/formatHelper';

type CountdownProps = {
    remainingTime: string;
};

export const CountdownComponent = (props: CountdownProps) => {
    const { remainingTime } = props;
    const [lotTimeRemaining, setLotTimeRemaining] = useState<string>(
        `${timeRemainingReturn(remainingTime)}`
    );

    useEffect(() => {
        const interval = setInterval(() => countDownTimer(remainingTime), 1000);
        return () => {
            setLotTimeRemaining('Loading...');
            clearInterval(interval);
        };
    }, [remainingTime]);

    const countDownTimer = (value: string) => {
        if (value === '' || !value) {
            setLotTimeRemaining('');
        } else {
            const remainderTime = timeRemainingReturn(value);
            setLotTimeRemaining(remainderTime);
        }
    };

    return <div>{lotTimeRemaining}</div>;
};
