import React from 'react';
import {
    AmplifyAuthContainer,
    AmplifyAuthenticator
} from '@aws-amplify/ui-react';
import AuthSignInPage from './signIn';
import { AuthSignUp } from './signUp';

export default function AuthenticatePage() {
    return (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator usernameAlias="email">
                <AuthSignInPage />
                <AuthSignUp />
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    );
}
