import { Grid, Tab, Box, Tabs, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChangePassword } from '../change-password/changePassword';
import { BiddingApprovalRequestForm } from './biddingApprovalRequest';

type TabPanelProps = {
    children: React.ReactNode;
    index: any;
    value: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        salesOverviewContainer: {
            padding: theme.spacing(10, 4, 0, 4),
            [theme.breakpoints.down('sm')]: {
                padding: '80px 32px 0px 32px'
            }
        },
        headerBanner: {
            backgroundColor: theme.palette.grey[100],
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '0',
            padding: '0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                height: '80px',
                padding: theme.spacing(2, 0)
            }
        },
        headerContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1920px',
            '& img': {
                marginLeft: theme.spacing(4)
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                padding: theme.spacing(0),
                '& img': {
                    marginLeft: theme.spacing(2)
                }
            }
        },
        headerTextZone: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '30px',
            alignItems: 'center',
            padding: theme.spacing(0, 4),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: 'auto',
                width: 'auto',
                padding: theme.spacing(0),
                alignItems: 'baseline',
                marginLeft: theme.spacing(2)
            }
        },
        selectionTabs: {
            borderBottom: `1px solid ${theme.palette.grey[100]}`
        },
        selectionTabsBackground: {
            height: '48px',
            backgroundColor: theme.palette.grey[50],
            position: 'absolute',
            width: '100%',
            left: '0',
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        salesTabsContent: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0)
            }
        }
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className={classes.salesTabsContent}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const indexToTab: any = {
    personalinfo: 0,
    changepassword: 1,
    biddingapproval: 2,
    addressbook: 3
};

export const AccountDetails = () => {
    const params: any = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<number>(
        indexToTab[params.tab]
    );
    const handleTabSelection = (
        event: React.ChangeEvent<Record<string, never>>,
        newValue: number
    ) => {
        history.push(`/account-details/${params.tab}`);
        setSelectedTab(newValue);
    };
    return (
        <div>
            <Toolbar className={classes.headerBanner}>
                <div className={classes.headerContent}>
                    <div className={classes.headerTextZone}>
                        <Typography variant="h2">Account Details</Typography>
                    </div>
                </div>
            </Toolbar>
            <Grid container className={classes.salesOverviewContainer}>
                <div className={classes.selectionTabsBackground} />
                <Grid xs={12} item className={classes.selectionTabs}>
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        onChange={handleTabSelection}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Personal Info" />
                        <Tab label="Change Password" />
                        <Tab label="Bidding Approval" />
                        <Tab label="Address Book" />
                    </Tabs>
                </Grid>
                <Grid xs={12} item>
                    <TabPanel value={selectedTab} index={0}>
                        Personal Info
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <ChangePassword />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <BiddingApprovalRequestForm />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        Address Book
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
};
