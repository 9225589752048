import React from 'react';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';

export function AuthSignUp() {
    return (
        <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
                {
                    type: 'given_name',
                    label: 'First Name',
                    placeholder: 'Enter your first name',
                    inputProps: { required: true }
                },
                {
                    type: 'family_name',
                    label: 'Last Name',
                    placeholder: 'Enter your Last name',
                    inputProps: { required: true }
                },
                {
                    type: 'email',
                    label: 'Email',
                    placeholder: 'Enter your Email',
                    inputProps: {
                        required: true,
                        autocomplete: 'username'
                    }
                },
                {
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Enter your Password',
                    inputProps: {
                        required: true,
                        autocomplete: 'new-password'
                    }
                },
                {
                    type: 'phone_number',
                    label: 'Phone Number',
                    placeholder: 'Enter your Phone Number'
                }
            ]}
        />
    );
}

export function AuthSignUpPage() {
    return (
        <AmplifyAuthenticator initialAuthState={AuthState.SignUp}>
            <AuthSignUp />
        </AmplifyAuthenticator>
    );
}
