import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 992,
            lg: 1200,
            xl: 1920
        }
    },
    palette: {
        primary: {
            main: '#3855A6'
        },
        secondary: {
            main: '#152759',
            dark: '#47360A',
            light: '#A68738'
        },
        error: {
            main: '#A80000',
            light: '#F6E5E5'
        },
        warning: {
            main: '#D83B01',
            light: '#FCEFE9'
        },
        success: {
            main: '#088031',
            light: '#E6F2EA'
        },
        grey: {
            50: '#FFFFFF',
            100: '#F1F1ED',
            200: '#F6F6F6',
            300: '#FCFCF6',
            400: '#E5E5E5',
            500: '#F6F6F6',
            600: '#C2C2BA',
            700: '#656562',
            800: '#222222',
            900: '#000000'
        }
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: 'MetropolisRegular',
        fontSize: 16,
        fontWeightBold: 700,
        h1: {
            fontFamily: 'Optima',
            fontSize: '10vw',
            fontWeight: 700,
            lineHeight: '48px'
        },
        h2: {
            fontFamily: 'Optima',
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '40px'
        },
        h3: {
            fontFamily: 'Optima',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32px'
        },
        h4: {
            fontFamily: 'Optima',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px'
        },
        h5: {
            fontFamily: 'Optima',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px'
        },
        body1: {
            fontFamily: 'MetropolisRegular',
            fontSize: '16px'
        },
        body2: {
            fontFamily: 'MetropolisRegular',
            fontWeight: 700,
            fontSize: '16px'
        },
        button: {
            fontFamily: 'MetropolisRegular',
            fontWeight: 700,
            textTransform: 'none'
        }
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                fontSize: '16px'
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '16px',
                '&$focused': {
                    color: '#3855A6'
                }
            }
        },
        MuiButton: {
            contained: {
                height: '42px',
                '&$disabled': {
                    backgroundColor: 'rgba(56, 85, 166, 0.5)',
                    color: 'white'
                }
            },
            label: {
                fontWeight: 700
            },
            outlinedPrimary: {
                '&$disabled': {
                    borderColor: 'rgba(56, 85, 166, 0.5)',
                    color: 'rgba(56, 85, 166, 0.5)'
                }
            }
        }
    },
    spacing: 8
});
