/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import { useAsync } from 'react-async';
import { API, Auth } from 'aws-amplify';
import {
    Box,
    Grid,
    Tabs,
    Tab,
    Button,
    Toolbar,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Popover,
    IconButton
} from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import Slideshow from '@material-ui/icons/Slideshow';
import parse from 'html-react-parser';
import ImageGallery from 'react-image-gallery';
import { useHistory, useParams } from 'react-router-dom';
import { ApolloProvider, useQuery, useSubscription } from '@apollo/client';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';

import PlayImage from '../../icons/MicrosoftTeams-image.png';
import LargeHorsePlaceholder from '../../icons/Horse-placeholder-large.svg';
import {
    LotBiddingInfo,
    LotBiddingResult,
    BidIncrement,
    BiddingLimit
} from '../../types/inglisTypes';
import {
    ARDEXCLOUD_IMAGE_BASEURL,
    INGLIS_PHYSICAL_PEDIGREE_URL
} from '../../utls/constants';
import {
    getLotStatusQuery,
    onBidPlacedQuery,
    getBidIncrement,
    getBiddingLimit
} from '../../queries/gqlQueries';
import { CreateAppSyncApolloClient } from '../../services/AppSyncApolloClient';
import { globalConfig } from '../../globalConfig';
import { getUserDebtorId } from '../../utls/userHelpers';
import { CountdownComponent } from '../helperComponents/countdownTimer';
import { LotJumpComponent } from '../helperComponents/lotJumper';

import {
    ausDollarFormatter,
    urlStringFormatter,
    physicalImageUrlFormatter,
    lotVideoUrlFormatter
} from '../../utls/formatHelper';
import { BidNowPopUp } from '../helperComponents/bidPopUp';
import { VendorContactFormComponent } from '../helperComponents/vendorContactForm';
import { mapLotStatus } from '../../utls/mapper';

interface TabPanelProps {
    children: React.ReactNode;
    index: any;
    value: any;
}

interface InitialTabProps {
    currentTab: string;
}

interface HorseLocationProps {
    lat: number;
    lng: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexLine: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main
        },
        flexZone: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        lotReturn: {
            cursor: 'pointer'
        },
        lotSelection: {
            width: '20%',
            display: 'flex',
            justifyContent: 'end',
            '& p': {
                width: '35%'
            },
            [theme.breakpoints.down(1640)]: {
                display: 'none'
            }
        },
        lotFormStyling: {
            flexDirection: 'row',
            margin: '45px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        lotFormField: {
            marginRight: '25px'
        },
        galleryGrid: {
            display: 'flex',
            backgroundColor: 'rgba(241, 241, 237, 0.5)',
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        galleryDetail: {
            width: '20%',
            backgroundColor: '#F1F1ED',
            padding: '35px 35px 0px 35px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& h2': {
                color: theme.palette.secondary.main
            },
            '& div': {
                marginBottom: '24px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                boxSizing: 'border-box',
                padding: '10px',
                height: 'auto'
            }
        },
        tableHeadStyle: {
            '& th': {
                padding: '15px 0 0 0',
                borderBottom: 'none',
                borderTop: '1px solid rgba(224, 224, 224, 1)'
            }
        },
        tableBodyStyle: {
            '& td': {
                padding: '0',
                borderBottom: 'none'
            },
            '& div': {
                marginBottom: 0,
                width: '135px'
            }
        },
        imageGallery: {
            cursor: 'pointer',
            position: 'relative',
            width: '80%',
            maxWidth: '1000px',
            '& .image-gallery-slides': {
                'z-index': '3'
            },
            '& img.image-gallery-image': {
                height: '400px'
            },
            '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg':
                {
                    height: '60px !important'
                },
            '& .image-gallery-content.bottom.fullscreen': {
                zIndex: 999,
                '& img': {
                    height: 'auto'
                },
                '& video': {
                    width: '60%',
                    height: '100%'
                }
            },
            '& video': {
                width: '715px',
                height: '400px'
            },
            '& video::-webkit-media-controls-fullscreen-button': {
                display: 'none'
            },
            '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
                height: '60px !important',
                width: '100%',
                cursor: 'pointer'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '& img.image-gallery-image': {
                    width: '100% !important',
                    height: '200px'
                },
                '& video': {
                    width: 'auto',
                    height: '200px'
                }
            }
        },
        mediaCount: {
            display: 'flex',
            justifyContent: 'space-evenly',
            position: 'absolute',
            top: '15px',
            alignItems: 'center',
            backgroundColor: 'rgba(101, 101, 98, 0.8)',
            borderRadius: '4px',
            width: '80px',
            right: '32px',
            padding: theme.spacing(0.5, 1),
            color: theme.palette.grey[50],
            'z-index': '4',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                '& p': {
                    fontSize: '14px',
                    lineHeight: '0',
                    paddingTop: theme.spacing(0.25)
                },
                '& svg': {
                    height: '20px'
                }
            }
        },
        closeIconStyle: {
            fill: 'white',
            position: 'absolute',
            top: theme.spacing(5),
            right: '25px',
            backgroundColor: theme.palette.grey[900],
            borderRadius: '100%',
            zIndex: 999,
            [theme.breakpoints.down('sm')]: {
                top: '10px',
                right: '100px'
            },
            '@media (max-width:400px)': {
                top: theme.spacing(1),
                right: theme.spacing(3)
            }
        },
        overViewListStyle: {
            listStyle: 'none',
            padding: '0',
            width: '30%'
        },
        overviewTabDetail: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        overviewDataTable: {
            width: '400px',
            maxHeight: '240px',
            '& tr>td:first-child': {
                color: 'rgba(81, 80, 75, 1)'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(2)
            }
        },
        locationDataTable: {
            marginTop: '10px',
            '& tr': {
                verticalAlign: 'baseline',
                '& td': {
                    paddingBottom: '15px'
                }
            }
        },

        horseOverviewDescription: {
            width: '400px',
            whiteSpace: 'pre-line',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingTop: '15px',
                borderTop: '1px solid #C4C4C4'
            }
        },
        physicalLotNumberRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            color: 'rgba(101, 101, 98, 1)',
            marginBottom: '0px !important'
        },
        physicalLotName: {
            marginBottom: theme.spacing(3)
        },
        physicalLotDetails: {
            color: `${theme.palette.grey[900]} !important`,
            marginBottom: `${theme.spacing(0)}!important`,
            padding: theme.spacing(0, 1),
            width: '50%'
        },
        physicalLotsResults: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        locationLink: {
            color: theme.palette.primary.main,
            textAlign: 'left'
        },
        navigationContainer: {
            width: '95%',
            position: 'absolute',
            top: '245px',
            [theme.breakpoints.down(1640)]: {
                backgroundColor: theme.palette.grey[200],
                width: '100%',
                padding: '15px',
                position: 'sticky',
                bottom: '0',
                zIndex: '4'
            }
        },
        navigationButtonZone: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        navigationButtonStyle: {
            height: '38px',
            width: '71px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '4px',
            color: theme.palette.primary.main,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        lotMobileSelection: {
            display: 'none',
            [theme.breakpoints.down(1640)]: {
                border: 'none',
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '50%',
                alignItems: 'center',
                '& .MuiFormControl-root.MuiTextField-root': {
                    width: '100%'
                }
            }
        },
        lotMobilePaginatorShadow: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100%',
                bottom: '70px',
                position: 'sticky',
                boxShadow: '0px 0.5px 15px 1px rgb(0 0 0 / 50%)'
            }
        },
        lotMobilePaginatorCover: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100%',
                height: '15px',
                position: 'absolute',
                backgroundColor: theme.palette.grey[200],
                bottom: '70px',
                zIndex: 1
            }
        },
        desktopFavButton: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobileFavButton: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        highestBidder: {
            color: `${theme.palette.success.main} !important`,
            backgroundColor: theme.palette.success.light
        },
        mediumBidder: {
            color: `${theme.palette.warning.main} !important`,
            backgroundColor: theme.palette.warning.light
        },
        lowestBidder: {
            color: `${theme.palette.error.main} !important`,
            backgroundColor: theme.palette.error.light
        },
        currentBidStatus: {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '12px',
            marginTop: '10px',
            alignItems: 'baseline'
        },
        bidderText: {
            fontSize: '12px !important',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            height: 'fit-content',
            padding: '2px 8px 2px 8px'
        },
        bidNowButton: {
            width: '100%'
        },
        bidPopOver: {
            '& .MuiPopover-paper': {
                width: '345px !important'
            }
        },
        lineBreak: {
            width: '100%',
            borderTop: `1px solid ${theme.palette.grey[600]}`
        },
        selectionTabsBackground: {
            height: '48px',
            position: 'absolute',
            width: '100%',
            left: '0',
            borderBottom: `1px solid ${theme.palette.grey[600]}`
        },
        pedigreeImage: {
            margin: theme.spacing(2, 0),
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        numField: {
            '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                {
                    '-webkit-appearance': 'none',
                    margin: 0
                },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            }
        },
        mapMarker: {
            backgroundColor: '#3855A6',
            borderRadius: '5px',
            color: '#FFFFFF',
            fontSize: '14px',
            padding: '10px 5px',
            position: 'relative',
            width: '100px',
            '& mapMarker::after': {
                content: '',
                position: 'absolute',
                left: '50%',
                top: '100%',
                width: '0',
                height: '0',
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: '8px solid #4285F4'
            }
        }
    })
);

const MapMarker = ({ lat, lng }: { lat: number; lng: number }) => (
    <div>
        {' '}
        <img
            alt="Location Map"
            src="https://developers.google.com/static/maps/documentation/javascript/images/default-marker.png"
        />
    </div>
);

export const MainLotDetailsPage = () => {
    const { data, error } = useAsync({ promiseFn: getCurrentUser });
    if (error) return null;
    if (data !== undefined) {
        const apolloClient = CreateAppSyncApolloClient({
            endpoint: globalConfig.graphql_endpoint,
            authenticationType:
                data === true ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        });
        return (
            <ApolloProvider client={apolloClient}>
                <LotDetails />
            </ApolloProvider>
        );
    }
    return null;
};

const getCurrentUser = async () => {
    const user = await Auth.currentUserInfo();
    const isAuthenticated = !!user;
    return isAuthenticated;
};

const getBidderStatus = (
    horseBidResults: LotBiddingResult[],
    userId: string
): string => {
    if (horseBidResults.length === 0 || !horseBidResults[0].lot_reserve_met) {
        return 'fail';
    }
    const horseBidResult = horseBidResults[0];

    if (horseBidResult.lot_leading_bidder === userId) {
        return 'success';
    }

    if (
        horseBidResult.lot_bidders.includes(userId) &&
        horseBidResult.lot_leading_bidder !== userId
    ) {
        return 'medium';
    }
    return 'fail';
};

export const LotDetails = () => {
    const [pageInfo, setPageInfo] = useState<number>(0);
    const [pedigrees, setPedigrees] = useState<any>(
        <InitialTab currentTab="Pedigree" />
    );
    const [raceRecord, setRaceRecord] = useState<any>(
        <InitialTab currentTab="Race Record" />
    );
    const [overView, setOverview] = useState<any>(null);
    const [horseImages, setHorseImages] = useState<any>([]);
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
    const [addedHorseData, setAddedHorsedata] = useState<LotBiddingInfo[]>([]);
    const [horseBidResultData, setHorseBidResultData] = useState<
        LotBiddingResult[]
    >([]);
    const [currentBidderStatus, setCurrentBidderStatus] = useState<string>('');
    const [currentUserId, setCurrentUserId] = useState<string>('');
    /** Bid Pop Up Component Functions */
    const [bidNowAnchor, setBidNowAnchorEl] =
        useState<HTMLButtonElement | null>(null);
    const [lotBidIncrement, setBidIncrement] = useState<BidIncrement[]>([]);
    const [userBiddingLimit, setBiddingLimit] = useState<BiddingLimit>({
        commited: 0,
        credit_limit: 0,
        spent: 0
    } as BiddingLimit);
    const [lotImageCount, setLotImageCount] = useState<number>(0);
    const [lotVideoCount, setLotVideoCount] = useState<number>(0);
    const [purchaserLocation, setPurchaserLocation] = useState<string>('');
    const [horseLocation, setHorseLocation] = useState<HorseLocationProps>({
        lat: 0,
        lng: 0
    });
    const [showMobile, setShowMobile] = useState<boolean>(false);
    const [showEmail, setShowEmail] = useState<boolean>(false);
    const [anonymizedMobile, setAnonymizedMobile] = useState<string>('');
    const [anonymizedEmail, setAnonymizedEmail] = useState<string>('');

    /** End Pop Up Component Functions */
    const classes = useStyles();
    const galleryElement = useRef<any>();
    const catParams: any = useParams();
    const history: any = useHistory();
    const catLength = sessionStorage.getItem('catalogueLength');
    const siteState = sessionStorage.getItem('siteState');

    useEffect(() => {
        let userDebtId = null;
        (async () => {
            const getUserDebtorValue = async () => {
                userDebtId = await getUserDebtorId();
            };
            await getUserDebtorValue();
            if (userDebtId !== null) {
                setCurrentUserId(
                    CryptoJS.enc.Base64.stringify(
                        CryptoJS.MD5(`${catParams.catSaleCode}:${userDebtId}`)
                    )
                );
            }
            getOverview(catParams.catSaleCode, catParams.id);
        })();
    }, []);

    useEffect(() => {
        const bidderStatus = getBidderStatus(horseBidResultData, currentUserId);
        setCurrentBidderStatus(bidderStatus);
    }, [horseBidResultData, currentUserId]);

    const handleChange = (
        event: React.ChangeEvent<Record<string, never>>,
        newValue: number
    ) => {
        setPageInfo(newValue);
    };

    const getPedigrees = (
        saleCode: string,
        saleId: string,
        lotNumber: string
    ) => {
        if (siteState === 'inglis') {
            setPedigrees(
                `${INGLIS_PHYSICAL_PEDIGREE_URL}${saleCode}/Lot${parseInt(
                    lotNumber,
                    10
                ).toLocaleString('en-US', {
                    minimumIntegerDigits: 5,
                    useGrouping: false
                })}.png`
            );
        } else {
            API.get('rest-api', `catalogue/${saleId}/${lotNumber}/pedigree`, {
                response: true,
                responseType: 'text'
            })
                .then((response) => {
                    setPedigrees(parse(response?.data?.replace(/&nbsp;/g, '')));
                })
                .catch((error) => {
                    // if (error.message.toString().indexOf('404')) {
                    //     alert(
                    //         'Cannot find horse pedigree information. Please try again.'
                    //     );
                    // }
                    setPedigrees(<InitialTab currentTab="Pedigree" />);
                });
        }
    };

    const getRaceRecord = (saleId: string, lotNumber: string) => {
        API.get('rest-api', `catalogue/${saleId}/${lotNumber}/race-record`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                setRaceRecord(parse(response?.data?.replace(/&nbsp;/g, '')));
            })
            .catch((error) => {
                // if (error.message.toString().indexOf('404')) {
                //     alert('Cannot find race record information for this Horse');
                // }
                setRaceRecord(<InitialTab currentTab="Race Record" />);
            });
    };

    const getAnonmyzedPhone = (phone: string): string => {
        const MIN_PHONE_CHARACTERS_TO_SHOW = 3;
        if (!phone) {
            return '';
        }

        if (phone.length <= MIN_PHONE_CHARACTERS_TO_SHOW) {
            return phone;
        }
        return phone
            .split(';')
            .map((phoneText: string) => {
                return `${'*'.repeat(
                    phone.length - MIN_PHONE_CHARACTERS_TO_SHOW
                )}${phone.slice(-MIN_PHONE_CHARACTERS_TO_SHOW)}`;
            })
            .join('');
    };

    const getAnonymizedEmail = (email: string): string => {
        if (!email) {
            return '';
        }
        return email
            .split(';')
            .map((emailText: string) => {
                const emailParts = emailText.split('@');
                return `${'*'.repeat(emailParts[0].length)}@${emailParts[1]}`;
            })
            .join('');
    };

    const getOverview = (saleCode: string, lotNumber: string) => {
        API.get('rest-api', `catalogue/${saleCode}/${lotNumber}/overview`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                setOverview(response.data);
                if (response.data?.result?.buyerLocation) {
                    if (response.data.result.buyerLocation.country) {
                        setPurchaserLocation(
                            `(${response.data.result.buyerLocation.country})`
                        );
                    } else {
                        setPurchaserLocation(
                            `(${response.data.result.buyerLocation.state})`
                        );
                    }
                }
                let horseImageCollection = [];
                let horseVideoCollection = [];
                if (siteState === 'inglis') {
                    horseImageCollection = response.data.images
                        ? response.data.images.map((item: any) => ({
                              original: `${physicalImageUrlFormatter(
                                  'large',
                                  item.objectName
                              )}`,
                              thumbnail: `${physicalImageUrlFormatter(
                                  'large',
                                  item.objectName
                              )}`
                          }))
                        : '';
                    horseVideoCollection = response.data.videos
                        ? response.data.videos.map((item: any) => ({
                              original: lotVideoUrlFormatter(
                                  'inglis',
                                  saleCode,
                                  item.objectName
                              ),
                              thumbnail: PlayImage,
                              renderItem: inglisVideoTile.bind(
                                  lotVideoUrlFormatter(
                                      'inglis',
                                      saleCode,
                                      item.objectName
                                  )
                              )
                          }))
                        : '';
                } else {
                    horseImageCollection = response.data.images
                        ? response.data.images.map((item: any) => ({
                              original: `${ARDEXCLOUD_IMAGE_BASEURL}${item.objectName}`,
                              thumbnail: `${ARDEXCLOUD_IMAGE_BASEURL}${item.objectName}`
                          }))
                        : '';
                    horseVideoCollection = response.data.videos
                        ? response.data.videos.map((item: any) => ({
                              original: lotVideoUrlFormatter(
                                  'digital',
                                  saleCode,
                                  item.objectName
                              ),
                              thumbnail: PlayImage,
                              renderItem: inglisVideoTile.bind(
                                  lotVideoUrlFormatter(
                                      'digital',
                                      saleCode,
                                      item.objectName
                                  )
                              )
                          }))
                        : '';
                }
                const currentLotImages = horseImageCollection;
                const currentLotVideos = horseVideoCollection;
                let currentMedia =
                    horseImageCollection.concat(horseVideoCollection);
                if (
                    horseImageCollection.length === 0 &&
                    horseVideoCollection.length === 0
                ) {
                    currentMedia = [
                        {
                            original: LargeHorsePlaceholder,
                            thumbnail: LargeHorsePlaceholder
                        }
                    ];
                }
                setLotImageCount(currentLotImages.length);
                setLotVideoCount(currentLotVideos.length);
                setHorseImages(currentMedia);
                getPedigrees(
                    response.data.sale.saleCode,
                    response.data.sale.saleId,
                    lotNumber
                );
                getRaceRecord(response.data.sale.saleId, lotNumber);
                setHorseLocation(response.data.location.address1);

                setAnonymizedMobile(
                    getAnonmyzedPhone(response.data?.vendor?.contact?.mobile)
                );

                setAnonymizedEmail(
                    getAnonymizedEmail(response?.data?.vendor?.contact?.email)
                );
                fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${response.data.location.address1}${response.data.location.suburb}${response.data.location.state}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        if (result.results && result.results.length > 0) {
                            setHorseLocation(
                                result.results[0]?.geometry?.location
                            );
                        }
                    });
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    alert('Cannot find overview information for this Horse');
                }
                return error.response;
            });
    };

    useQuery(getLotStatusQuery(catParams.catSaleCode, catParams.id), {
        onCompleted: (data) => {
            const result = data?.getBiddingInfo?.map((status: LotBiddingInfo) =>
                mapLotStatus(status)
            );
            if (result) {
                const lotAddedData = result.filter((i: any) => {
                    return i.lot_no === `${catParams.id}`;
                });
                setAddedHorsedata(lotAddedData);
            }
        }
    });

    useQuery(getBiddingLimit(catParams.catSaleCode), {
        onCompleted: (data) => {
            const result = data?.getBiddingLimit;
            if (result) {
                setBiddingLimit(result);
            }
        }
    });

    useQuery(getBidIncrement(), {
        onCompleted: (data) => {
            const result = data?.getIncrements;
            setBidIncrement(result);
        }
    });

    useSubscription(onBidPlacedQuery(catParams.catSaleCode, catParams.id), {
        onSubscriptionData: (data) => {
            const newStatus = mapLotStatus(
                data.subscriptionData?.data?.onBidPlaced
            );
            if (newStatus) {
                setAddedHorsedata((prevState: any) => {
                    const result = [...prevState];
                    const index = result.findIndex(
                        (s) =>
                            s.sale_code === catParams.catSaleCode &&
                            s.lot_no === catParams.id
                    );
                    if (index > -1)
                        result[index] = {
                            ...result[index],
                            ...newStatus
                        };
                    setHorseBidResultData(result);
                    return result;
                });
            }
        }
    });

    /**
     * Toggles fullscreen for Gallery
     */
    const toggleGalleryFullScreen = () => {
        if (galleryElement) {
            if (!fullScreenMode) {
                setFullScreenMode(true);
                galleryElement.current.fullScreen();
            }
        }
    };

    /**
     * Function for close button in full screen mode. Closes full screen mode.
     */
    const closeFullScreen = () => {
        setFullScreenMode(false);
        galleryElement.current.exitFullScreen();
    };

    /**
     * Returns to Lots table on Sale Overview page upon clicking the back button
     * on the top left of the screen
     */
    const returnToCatalogue = () => {
        let selectedCataloguePage = '';
        let selectedCataloguePageSize = '';
        let selectedCatalogueSortBy = '';
        let selectedCatalogueSortOrder = '';

        if (siteState === 'inglis') {
            selectedCataloguePage = sessionStorage.getItem(
                'catalogueSelectedPage'
            )
                ? sessionStorage.getItem('catalogueSelectedPage')!
                : '0';
            selectedCataloguePageSize = sessionStorage.getItem(
                'catalogueSelectedPageSize'
            )
                ? sessionStorage.getItem('catalogueSelectedPageSize')!
                : '100';
            selectedCatalogueSortBy = sessionStorage.getItem(
                'catalogueSelectedSortBy'
            )
                ? sessionStorage.getItem('catalogueSelectedSortBy')!
                : '';
            selectedCatalogueSortOrder = sessionStorage.getItem(
                'catalogueSelectedSortOrder'
            )
                ? sessionStorage.getItem('catalogueSelectedSortOrder')!
                : '';
            history.push(
                `/sales/${catParams.catSaleCode}/${urlStringFormatter(
                    overView.sale.saleName!
                )}/lots?pagesize=${selectedCataloguePageSize}&currentpage=${
                    parseInt(selectedCataloguePage, 10) + 1
                }&sortby=${selectedCatalogueSortBy}&sortorder=${selectedCatalogueSortOrder}`
            );
        } else {
            selectedCataloguePage = sessionStorage.getItem(
                'digitalCatalogueSelectedPage'
            )
                ? sessionStorage.getItem('digitalCatalogueSelectedPage')!
                : '0';
            selectedCataloguePageSize = sessionStorage.getItem(
                'digitalCatalogueSelectedPageSize'
            )
                ? sessionStorage.getItem('digitalCatalogueSelectedPageSize')!
                : '100';
            selectedCatalogueSortBy = sessionStorage.getItem(
                'digitalCatalogueSelectedSortBy'
            )
                ? sessionStorage.getItem('digitalCatalogueSelectedSortBy')!
                : '';
            selectedCatalogueSortOrder = sessionStorage.getItem(
                'digitalCatalogueSelectedSortOrder'
            )
                ? sessionStorage.getItem('digitalCatalogueSelectedSortOrder')!
                : '';
            history.push(
                `/digital/sales/${catParams.catSaleCode}/${urlStringFormatter(
                    overView.sale.saleName!
                )}/lots?pagesize=${selectedCataloguePageSize}&currentpage=${
                    parseInt(selectedCataloguePage, 10) + 1
                }&sortby=${selectedCatalogueSortBy}&sortorder=${selectedCatalogueSortOrder}`
            );
        }
    };

    // directs user to the next or previous lot depending on the direction that they have clicked.
    const navigateLots = (lotValue: string) => {
        setCurrentBidderStatus('');
        const lotNavigationUrl =
            siteState === 'inglis'
                ? `/${catParams.catSaleCode}/lots/${lotValue}`
                : `/digital/${catParams.catSaleCode}/lots/${lotValue}`;
        history.push(lotNavigationUrl);
        getOverview(catParams.catSaleCode, lotValue);
    };

    const getPreviousLot = (): string => {
        const currentLotNumber = parseInt(catParams.id, 10);
        if (currentLotNumber <= 1) {
            return '1';
        }
        return (currentLotNumber - 1).toString();
    };

    const getNextLot = (): string => {
        const currentLotNumber = parseInt(catParams.id, 10);
        if (catParams.id === catLength) {
            return catParams.id;
        }
        return (currentLotNumber + 1).toString();
    };

    /**
     * Renders custom close icon for full screen mode
     */
    const renderGalleryControl = () => {
        return (
            fullScreenMode && (
                <CloseIcon
                    onClick={closeFullScreen}
                    className={classes.closeIconStyle}
                />
            )
        );
    };

    /**
     * Returns bidder status text to be displayed during auction
     *
     * @param status
     * @returns bidder status text div
     */
    const bidderStatus = (status: string) => {
        // eslint-disable-next-line no-debugger
        switch (status) {
            case 'fail':
                return (
                    <Typography
                        id="bidder-status-text"
                        className={`${classes.bidderText} ${classes.lowestBidder}`}
                        variant="body2"
                    >
                        The reserve is not met
                    </Typography>
                );
            case 'medium':
                return (
                    <Typography
                        id="bidder-status-text"
                        className={`${classes.bidderText} ${classes.mediumBidder}`}
                        variant="body2"
                    >
                        You are not the highest bidder
                    </Typography>
                );
            case 'success':
                return (
                    <Typography
                        id="bidder-status-text"
                        className={`${classes.bidderText} ${classes.highestBidder}`}
                        variant="body2"
                    >
                        You are the highest bidder
                    </Typography>
                );
            default:
                return <div />;
        }
    };

    /** Bid Pop Up Component Functions */

    const handlePopLaunch = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBidNowAnchorEl(event.currentTarget);
    };

    const open = Boolean(bidNowAnchor);
    const bidNowButtonPopId = open ? 'simple-popover' : undefined;

    const handlePopClose = () => {
        setBidNowAnchorEl(null);
    };

    const goToDashboard = () => {
        history.push('/dashboard');
    };

    /** End Bid Pop Up Component Functions */

    /**
     * Resets video upon navigating to different item within the gallery.
     * Video time is set to 0 and is stopped to not play in the background when
     * cycling through gallery
     */
    const resetVideo = () => {
        const video: any = document.getElementsByTagName('video');
        [...video].forEach((item: any) => {
            const vid = item;
            vid.currentTime = 0;
            vid.pause();
        });
    };

    return (
        <div style={{ position: 'relative' }}>
            <Toolbar className={classes.flexZone}>
                <div className={`${classes.flexZone}  ${classes.lotReturn}`}>
                    <ArrowBackIcon onClick={returnToCatalogue} />{' '}
                    {overView && (
                        <Typography variant="body1" onClick={returnToCatalogue}>
                            Lots | {overView.sale.saleName}
                        </Typography>
                    )}
                </div>
                <div className={`${classes.flexZone}  ${classes.lotSelection}`}>
                    <Typography>Jump To Lot</Typography>
                    <LotJumpComponent saleCode={catParams.catSaleCode} />
                </div>
            </Toolbar>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12} className={classes.galleryGrid}>
                    <div className={classes.imageGallery}>
                        {lotImageCount ? (
                            <div className={classes.mediaCount}>
                                <div>
                                    <Typography variant="body1">
                                        {lotImageCount}
                                    </Typography>
                                    <ImageOutlinedIcon />
                                </div>
                                <div>
                                    <Typography variant="body1">
                                        {lotVideoCount}
                                    </Typography>
                                    <Slideshow />
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                        {horseImages && (
                            <ImageGallery
                                items={horseImages}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                showThumbnails={fullScreenMode}
                                ref={galleryElement}
                                onScreenChange={(screenState: boolean) =>
                                    setFullScreenMode(screenState)
                                }
                                onClick={toggleGalleryFullScreen}
                                useBrowserFullscreen={false}
                                renderCustomControls={renderGalleryControl}
                                onSlide={resetVideo}
                            />
                        )}
                    </div>
                    {overView &&
                        (siteState === 'inglis' ? (
                            <div className={classes.galleryDetail}>
                                <div>
                                    <div
                                        className={classes.physicalLotNumberRow}
                                    >
                                        <Typography variant="body2">
                                            Lot {overView.lotNumber}
                                        </Typography>
                                        <IconButton
                                            className={classes.mobileFavButton}
                                        >
                                            <FavoriteBorderIcon />
                                        </IconButton>
                                    </div>
                                    {overView.horse.name ? (
                                        <Typography
                                            className={classes.physicalLotName}
                                            variant="h2"
                                        >
                                            {overView.horse.name}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            className={classes.physicalLotName}
                                            variant="h2"
                                        >
                                            {overView.horse.sire.name}/
                                            {overView.horse.dam.name}
                                        </Typography>
                                    )}
                                    <div
                                        className={`${classes.flexLine} ${classes.desktopFavButton}`}
                                    >
                                        <FavoriteBorderIcon />
                                        <Typography variant="body2">
                                            Add to shortlist
                                        </Typography>
                                    </div>
                                    <hr className={classes.lineBreak} />
                                </div>

                                {overView.result ? (
                                    <div>
                                        {overView.result.status === 'Sold' ? (
                                            <div
                                                className={
                                                    classes.physicalLotsResults
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.physicalLotDetails
                                                    }
                                                >
                                                    <Typography variant="body1">
                                                        Sale Result
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {overView.result.status}{' '}
                                                        {ausDollarFormatter(
                                                            overView.result
                                                                .hammerSoldPrice
                                                        )}
                                                    </Typography>
                                                </div>
                                                <div
                                                    className={
                                                        classes.physicalLotDetails
                                                    }
                                                >
                                                    <Typography variant="body1">
                                                        Purchaser
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {
                                                            overView.result
                                                                .buyerName
                                                        }{' '}
                                                        {purchaserLocation}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    classes.physicalLotsResults
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.physicalLotDetails
                                                    }
                                                >
                                                    <Typography variant="body1">
                                                        Sale Result
                                                    </Typography>
                                                    {overView.result.status ===
                                                    'Withdrawn' ? (
                                                        <Typography
                                                            variant="body2"
                                                            color="error"
                                                        >
                                                            {
                                                                overView.result
                                                                    .status
                                                            }
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body2">
                                                            {
                                                                overView.result
                                                                    .status
                                                            }
                                                        </Typography>
                                                    )}
                                                </div>
                                                {overView.result.status ===
                                                'Withdrawn' ? (
                                                    <div />
                                                ) : (
                                                    <div
                                                        className={
                                                            classes.physicalLotDetails
                                                        }
                                                    >
                                                        <Typography variant="body1">
                                                            Reserve
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {ausDollarFormatter(
                                                                overView.reservePrice
                                                            )}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <div
                                            className={
                                                classes.physicalLotsResults
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.physicalLotDetails
                                                }
                                            >
                                                <Typography variant="body1">
                                                    Selling Time
                                                </Typography>
                                                <Typography variant="body2">
                                                    Time
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.physicalLotDetails
                                                }
                                            >
                                                <Typography variant="body1">
                                                    Arrival time at complex
                                                </Typography>
                                                <Typography variant="body2">
                                                    12 Jul 2021, 3:30pm
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.flexLine}>
                                            <div
                                                className={
                                                    classes.physicalLotDetails
                                                }
                                            >
                                                <Typography variant="body1">
                                                    Location
                                                </Typography>
                                                <Typography variant="body2">
                                                    {overView.stable}{' '}
                                                    {overView.stable2}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className={classes.galleryDetail}>
                                <div>
                                    <div
                                        className={classes.physicalLotNumberRow}
                                    >
                                        <Typography variant="body2">
                                            Lot {overView.lotNumber}
                                        </Typography>
                                        <IconButton
                                            className={classes.mobileFavButton}
                                        >
                                            <FavoriteBorderIcon />
                                        </IconButton>
                                    </div>
                                    <Typography variant="h2">
                                        {overView.horse.name}
                                    </Typography>
                                </div>
                                <div
                                    className={`${classes.flexLine} ${classes.desktopFavButton}`}
                                >
                                    <FavoriteBorderIcon />
                                    <Typography variant="body2">
                                        Add to shortlist
                                    </Typography>
                                </div>
                                <div>
                                    {addedHorseData.length > 0 ? (
                                        <Table>
                                            <TableHead>
                                                <TableRow
                                                    className={
                                                        classes.tableHeadStyle
                                                    }
                                                >
                                                    <TableCell>
                                                        Current Bid
                                                    </TableCell>
                                                    <TableCell>Bids</TableCell>
                                                    <TableCell>
                                                        Time Left
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    className={
                                                        classes.tableBodyStyle
                                                    }
                                                >
                                                    <TableCell>
                                                        {ausDollarFormatter(
                                                            addedHorseData[0]
                                                                .lot_current_bid
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {addedHorseData[0]
                                                            .lot_bid_count
                                                            ? addedHorseData[0]
                                                                  .lot_bid_count
                                                            : '0'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {}
                                                        <CountdownComponent
                                                            remainingTime={
                                                                addedHorseData[0]
                                                                    .lot_closing_time
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                                {currentUserId ? (
                                    <div>
                                        <Button
                                            aria-describedby={bidNowButtonPopId}
                                            className={classes.bidNowButton}
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={handlePopLaunch}
                                        >
                                            Bid Now
                                        </Button>
                                        <Popover
                                            id={bidNowButtonPopId}
                                            open={open}
                                            anchorEl={bidNowAnchor}
                                            onClose={handlePopClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                            className={classes.bidPopOver}
                                        >
                                            <BidNowPopUp
                                                horseData={addedHorseData}
                                                saleId={catParams.id}
                                                saleCode={catParams.catSaleCode}
                                                horseName={overView.horse.name}
                                                bidIncrementValues={
                                                    lotBidIncrement
                                                }
                                                handleDataMutation={
                                                    mapLotStatus
                                                }
                                                handlePopClose={handlePopClose}
                                                goToDashboard={goToDashboard}
                                            />
                                        </Popover>
                                        <div
                                            className={classes.currentBidStatus}
                                        >
                                            <Typography
                                                variant="body2"
                                                className={classes.bidderText}
                                            >
                                                Funds available{' '}
                                                {ausDollarFormatter(
                                                    userBiddingLimit.credit_limit -
                                                        userBiddingLimit.commited -
                                                        userBiddingLimit.spent
                                                )}
                                            </Typography>
                                            {bidderStatus(currentBidderStatus)}
                                        </div>
                                    </div>
                                ) : (
                                    <Button
                                        aria-describedby={bidNowButtonPopId}
                                        className={classes.bidNowButton}
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => history.push('/signin')}
                                    >
                                        Log in to Bid
                                    </Button>
                                )}
                            </div>
                        ))}
                </Grid>
                <Grid xs={10} item>
                    <div className={classes.selectionTabsBackground} />
                    {siteState === 'inglis' ? (
                        <Tabs
                            value={pageInfo}
                            indicatorColor="primary"
                            onChange={handleChange}
                            variant="scrollable"
                        >
                            <Tab label="Overview" />
                            <Tab label="Pedigree" />
                        </Tabs>
                    ) : (
                        <Tabs
                            value={pageInfo}
                            indicatorColor="primary"
                            onChange={handleChange}
                            variant="scrollable"
                        >
                            <Tab label="Overview" />
                            <Tab label="Pedigree" />
                            <Tab label="Race Record" />
                            <Tab label="Reports" />
                            <Tab label="Contact" />
                        </Tabs>
                    )}
                </Grid>
                <Grid xs={10} item>
                    <TabPanel value={pageInfo} index={0}>
                        {overView ? (
                            <OverviewTab
                                horseOverview={overView}
                                isLocationTabShown={siteState !== 'inglis'}
                                gotoLocationTab={setPageInfo}
                            />
                        ) : (
                            <div>Overview Tab</div>
                        )}
                    </TabPanel>
                    <TabPanel value={pageInfo} index={1}>
                        {siteState === 'inglis' ? (
                            <img
                                alt="Pedigree Information"
                                src={pedigrees}
                                className={classes.pedigreeImage}
                            />
                        ) : (
                            pedigrees
                        )}
                    </TabPanel>
                    <TabPanel value={pageInfo} index={2}>
                        {raceRecord}
                    </TabPanel>
                    <TabPanel value={pageInfo} index={3}>
                        Reports
                    </TabPanel>
                    <TabPanel value={pageInfo} index={4}>
                        <div>
                            <table className={classes.locationDataTable}>
                                {overView?.result?.status?.toLowerCase() ===
                                    'sold' ||
                                overView?.result?.status?.toLowerCase() ===
                                    'passed in' ? (
                                    <tbody />
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td>Vendor</td>
                                            <td>
                                                {
                                                    overView?.vendor
                                                        ?.catalogueName
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            {showMobile ? (
                                                <td>
                                                    {
                                                        overView?.vendor
                                                            ?.contact.mobile
                                                    }
                                                </td>
                                            ) : (
                                                <td>
                                                    {anonymizedMobile}
                                                    <br />
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            setShowMobile(true)
                                                        }
                                                    >
                                                        Show mobile
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            {showEmail ? (
                                                <td>
                                                    {
                                                        overView?.vendor
                                                            ?.contact.email
                                                    }
                                                </td>
                                            ) : (
                                                <td>
                                                    {anonymizedEmail} <br />
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            setShowEmail(true)
                                                        }
                                                    >
                                                        Show email
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                )}
                                <tbody>
                                    <tr>
                                        <td>Location</td>
                                        <td>
                                            {overView?.location?.address1}{' '}
                                            {overView?.location?.suburb}{' '}
                                            {overView?.location?.state?.toUpperCase()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td />
                                        <td>
                                            {overView?.location?.address1 &&
                                                horseLocation &&
                                                horseLocation.lat &&
                                                horseLocation.lng && (
                                                    <div
                                                        style={{
                                                            height: '200px',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <GoogleMapReact
                                                            bootstrapURLKeys={{
                                                                key:
                                                                    process.env
                                                                        .REACT_APP_GOOGLE_MAP_API_KEY ||
                                                                    ''
                                                            }}
                                                            defaultCenter={{
                                                                lat: horseLocation.lat,
                                                                lng: horseLocation.lng
                                                            }}
                                                            defaultZoom={12}
                                                        >
                                                            <MapMarker
                                                                lat={
                                                                    horseLocation.lat
                                                                }
                                                                lng={
                                                                    horseLocation.lng
                                                                }
                                                            />
                                                        </GoogleMapReact>
                                                    </div>
                                                )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                {}
                                <VendorContactFormComponent />
                            </div>
                        </div>
                    </TabPanel>
                </Grid>
                <div className={classes.lotMobilePaginatorCover} />
                <div className={classes.lotMobilePaginatorShadow} />
                <Grid xs={12} item className={classes.navigationContainer}>
                    <div className={classes.navigationButtonZone}>
                        <div
                            aria-hidden="true"
                            onClick={() => navigateLots(getPreviousLot())}
                            className={classes.navigationButtonStyle}
                        >
                            <NavigateBeforeIcon />
                            <Typography variant="body2">
                                {getPreviousLot()}
                            </Typography>
                        </div>
                        <div className={classes.lotMobileSelection}>
                            <Typography variant="body2">Jump To Lot</Typography>
                            <LotJumpComponent
                                saleCode={catParams.catSaleCode}
                            />
                        </div>
                        <div
                            aria-hidden="true"
                            onClick={() => navigateLots(getNextLot())}
                            className={classes.navigationButtonStyle}
                        >
                            <Typography variant="body2">
                                {getNextLot()}
                            </Typography>
                            <NavigateNextIcon />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const OverviewTab = (props: any) => {
    const { horseOverview, isLocationTabShown } = props;
    const goToLocationTab = props.gotoLocationTab;
    const classes = useStyles();
    const dateFormatter = (date: string) => {
        return new Date(date).toLocaleDateString();
    };
    return (
        <Typography variant="body1">
            <div className={classes.overviewTabDetail}>
                <table className={classes.overviewDataTable}>
                    <tbody>
                        <tr>
                            <td>Name:</td>
                            {horseOverview.horse.name ? (
                                <td>{horseOverview.horse.name}</td>
                            ) : (
                                <td>
                                    {horseOverview.horse.sire.name} /{' '}
                                    {horseOverview.horse.dam.name}
                                </td>
                            )}
                        </tr>
                        {horseOverview.category && (
                            <tr>
                                <td>Category:</td>
                                <td>{horseOverview.category}</td>
                            </tr>
                        )}
                        {horseOverview.vendor.catalogueName && (
                            <tr>
                                <td>Vendor:</td>
                                <td>{horseOverview.vendor.catalogueName}</td>
                            </tr>
                        )}
                        {horseOverview.horse.sire.name && (
                            <tr>
                                <td>Sire:</td>
                                <td>{horseOverview.horse.sire.name}</td>
                            </tr>
                        )}
                        {horseOverview.horse.dam.name && (
                            <tr>
                                <td>Dam:</td>
                                <td>{horseOverview.horse.dam.name}</td>
                            </tr>
                        )}
                        {horseOverview.horse.colour && (
                            <tr>
                                <td>Colour:</td>
                                <td>{horseOverview.horse.colour}</td>
                            </tr>
                        )}
                        {horseOverview.horse.sex && (
                            <tr>
                                <td>Sex:</td>
                                <td>{horseOverview.horse.sex}</td>
                            </tr>
                        )}
                        {horseOverview.horse.dateOfBirth && (
                            <tr>
                                <td>DOB:</td>
                                <td>
                                    {dateFormatter(
                                        horseOverview.horse.dateOfBirth
                                    )}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>Disclosures:</td>
                            <td>{horseOverview.defects}</td>
                        </tr>
                        {horseOverview.taxPercent > 0 ? (
                            <tr>
                                <td>GST:</td>
                                <td>Yes {horseOverview.taxPercent}%</td>
                            </tr>
                        ) : (
                            <tr>
                                <td>GST:</td>
                                <td>No</td>
                            </tr>
                        )}
                        {horseOverview.broodmare &&
                            horseOverview.broodmare.coveringStallion && (
                                <tr>
                                    <td>Covering Stallion:</td>
                                    <td>
                                        {
                                            horseOverview.broodmare
                                                .coveringStallion.name
                                        }
                                    </td>
                                </tr>
                            )}
                        {horseOverview.broodmare && (
                            <tr>
                                <td>Last Service Date:</td>
                                <td>
                                    {moment(
                                        horseOverview.broodmare.lastServiceDate
                                    ).format('DD MMM YYYY')}
                                </td>
                            </tr>
                        )}
                        {horseOverview.broodmare && (
                            <tr>
                                <td>Produce live foal current year:</td>
                                <td>
                                    {horseOverview.broodmare.producedLiveFoal}
                                </td>
                            </tr>
                        )}
                        {horseOverview.breezeupTime && (
                            <tr>
                                <td>Breeze Up Time:</td>
                                <td>{horseOverview.breezeupTime}</td>
                            </tr>
                        )}
                        {horseOverview.breezeupLocation && (
                            <tr>
                                <td>Breeze Up Location:</td>
                                <td>{horseOverview.breezeupLocation}</td>
                            </tr>
                        )}
                        {horseOverview.breezeupTrackCondition && (
                            <tr>
                                <td>Breeze Up Track Condition:</td>
                                <td>{horseOverview.breezeupTrackCondition}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Location:</td>
                            <td>
                                <Typography variant="body2">
                                    <Button
                                        className={classes.locationLink}
                                        onClick={() => {
                                            if (isLocationTabShown) {
                                                goToLocationTab(4);
                                            }
                                        }}
                                    >
                                        {horseOverview?.location?.address1}
                                        {'  '}
                                        {horseOverview?.location?.suburb}
                                        {'  '}
                                        {horseOverview?.location?.state?.toUpperCase()}
                                    </Button>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* be careful with xss attacks */}
                {horseOverview?.lotDescription && (
                    <div className={classes.horseOverviewDescription}>
                        (
                        {parse(parse(horseOverview?.lotDescription).toString())}
                    </div>
                )}
            </div>
        </Typography>
    );
};

const InitialTab = ({ currentTab }: InitialTabProps) => {
    return <div>{currentTab} information to come soon</div>;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const inglisVideoTile = (source: any) => {
    return (
        <video
            className="inglis-video-class"
            id="inglis-video"
            controls
            preload="metadata"
        >
            <source src={source.original} type="video/mp4" />
            <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
            />
        </video>
    );
};
