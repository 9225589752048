import {
    getGridNumericColumnOperators,
    getGridStringOperators,
    GridColDef,
    GridFilterOperator
} from '@material-ui/data-grid';

export interface LotGridFilterMenuItem {
    value: string;
    text: string;
}

export const numericOperators = getGridNumericColumnOperators().filter(
    (operator: GridFilterOperator) =>
        operator.value === '>' || operator.value === '<'
);

export const stringOperators = getGridStringOperators().filter(
    (operator: GridFilterOperator) =>
        operator.value === 'contains' || operator.value === 'equals'
);

export const horseCategories: LotGridFilterMenuItem[] = [
    {
        value: 'B',
        text: 'Broodmare'
    },
    {
        value: 'RF',
        text: 'Race Filly'
    },
    {
        value: 'RH',
        text: 'Racehorse'
    },

    {
        value: 'S',
        text: 'Stallion'
    },

    {
        value: 'SH',
        text: 'Share'
    },
    {
        value: 'Y',
        text: 'Yearling'
    }
];

export const horseSexes: LotGridFilterMenuItem[] = [
    {
        value: 'C',
        text: 'Colt'
    },
    {
        value: 'F',
        text: 'Filly'
    },
    {
        value: 'G',
        text: 'Gelding'
    },

    {
        value: 'M',
        text: 'Mare'
    },

    {
        value: 'M+',
        text: 'Mare (+)'
    }
];

export const getLotsFilterOptions = (catalogues: any) => {
    const horses: string[] = [];
    const sires: string[] = [];
    const dams: string[] = [];
    const vendors: string[] = [];
    const locations: string[] = [];
    const inFoalTos: string[] = [];
    catalogues.forEach((catalogueResult: any) => {
        // TODO: enable short circuiting in eslint

        // eslint-disable-next-line no-unused-expressions
        catalogueResult.name && horses.push(catalogueResult.name);
        // eslint-disable-next-line no-unused-expressions
        catalogueResult.sire && sires.push(catalogueResult.sire);
        // eslint-disable-next-line no-unused-expressions
        catalogueResult.dam && dams.push(catalogueResult.dam);
        // eslint-disable-next-line no-unused-expressions
        catalogueResult.accountOf && vendors.push(catalogueResult.accountOf);
        // eslint-disable-next-line no-unused-expressions
        catalogueResult.country && locations.push(catalogueResult.country);
        // eslint-disable-next-line no-unused-expressions
        catalogueResult.inFoalTo && inFoalTos.push(catalogueResult.inFoalTo);
    });
    horses.sort();
    sires.sort();
    dams.sort();
    vendors.sort();
    locations.sort();
    inFoalTos.sort();
    return {
        horses: [...new Set(horses)],
        sires: [...new Set(sires)],
        dams: [...new Set(dams)],
        vendors: [...new Set(vendors)],
        locations: [...new Set(locations)],
        inFoalTos: [...new Set(inFoalTos)]
    };
};

// todo: use this to reuse columns, left it as it due to issue with positioning, can be done with custom column index property
export const getCommonLotColumns = (): GridColDef[] => {
    return [
        {
            field: '',
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            width: 0.5,
            filterable: false,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 0.1
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            filterable: false,
            width: 85
        },
        {
            field: 'category',
            headerName: 'Category',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.1
        },
        {
            field: 'colour',
            headerName: 'Col',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'string'
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15
        },
        {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'number'
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },
        {
            field: 'dam',
            headerName: 'Dam',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },
        {
            field: 'country',
            headerName: 'Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        }
    ];
};

export const getPhysicalSaleLotsColumns = (): GridColDef[] => {
    return [
        {
            field: '',
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            width: 0.5,
            filterable: false,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 0.1
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            filterable: false,
            width: 85
        },
        {
            field: 'category',
            headerName: 'Category',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.1
        },
        {
            field: 'colour',
            headerName: 'Col',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'string'
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15
        },
        {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'number'
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },
        {
            field: 'dam',
            headerName: 'Dam',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },
        {
            field: 'country',
            headerName: 'Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },
        {
            field: 'inFoalTo',
            headerName: 'In foal to',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.25
        },

        {
            field: 'accountOf',
            headerName: 'Vendor',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4
        },
        {
            field: 'purchaser',
            headerName: 'Purchaser',
            headerClassName: 'catalogue-header',
            cellClassName: 'purchase-column',
            headerAlign: 'center',
            flex: 0.4,
            type: 'string'
        },
        {
            field: 'purchaserLocation',
            headerName: 'Purchaser Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.28,
            type: 'string'
        },
        {
            field: 'price',
            headerName: 'Price',
            headerClassName: 'catalogue-header',
            cellClassName: 'price-column',
            headerAlign: 'center',
            flex: 0.25,
            type: 'number'
        }
    ];
};

export const getSaleLotsColumns = (): GridColDef[] => {
    return [
        {
            field: '',
            filterable: false,
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.1,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 0.2
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            filterable: false,
            width: 100
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15
        },
        {
            field: 'category',
            headerName: 'Cat.',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15
        },
        {
            field: 'colour',
            headerName: 'Col',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'string'
        },
        {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            type: 'number'
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4,
            type: 'string'
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4
        },
        {
            field: 'dam',
            headerName: 'Dam',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4
        },
        {
            field: 'coveringSire',
            headerName: 'Covering Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4
        },
        {
            field: 'country',
            headerName: 'Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15
        },
        {
            field: 'accountOf',
            headerName: 'Vendor',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.4
        },
        {
            field: 'purchaser',
            headerName: 'Purchaser',
            headerClassName: 'catalogue-header',
            cellClassName: 'purchase-column',
            headerAlign: 'center',
            flex: 0.3,
            type: 'string'
        },
        {
            field: 'price',
            headerName: 'Price',
            headerClassName: 'catalogue-header',
            cellClassName: 'price-column',
            headerAlign: 'center',
            flex: 0.2,
            type: 'number'
        }
    ];
};

export const getCurrentSaleLotsPublicColumns = (): GridColDef[] => {
    return [
        {
            field: '',
            filterable: false,
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 65
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            filterable: false,
            width: 100
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'category',
            headerName: 'Cat.',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'colour',
            headerName: 'Col',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2,
            type: 'string'
        },
        {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2,
            type: 'number'
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5,
            type: 'string'
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'dam',
            headerName: 'Dam',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },

        {
            field: 'inFoalTo',
            headerName: 'Covering Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'country',
            headerName: 'Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'accountOf',
            headerName: 'Vendor',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'lot_closing_time',
            headerName: 'Time Left',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.6
        }
    ];
}

export const getCurrentSaleLotsColumns = (): GridColDef[] => {
    return [
        {
            field: '',
            filterable: false,
            headerName: '',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.15,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'Lot',
            type: 'number',
            headerClassName: 'catalogue-header',
            headerAlign: 'left',
            width: 65
        },
        {
            field: 'images',
            headerName: 'Media',
            headerAlign: 'center',
            headerClassName: 'catalogue-header',
            filterable: false,
            width: 100
        },
        {
            field: 'sex',
            headerName: 'Sex',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'category',
            headerName: 'Cat.',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'colour',
            headerName: 'Col',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2,
            type: 'string'
        },
        {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2,
            type: 'number'
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5,
            type: 'string'
        },
        {
            field: 'sire',
            headerName: 'Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'dam',
            headerName: 'Dam',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },

        {
            field: 'inFoalTo',
            headerName: 'Covering Sire',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'country',
            headerName: 'Location',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.2
        },
        {
            field: 'accountOf',
            headerName: 'Vendor',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'lot_current_bid',
            headerName: 'Bid',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.5
        },
        {
            field: 'lot_closing_time',
            headerName: 'Time Left',
            headerClassName: 'catalogue-header',
            headerAlign: 'center',
            flex: 0.6
        }
    ];
};

export const getColumnsWithOperators = (
    columns: GridColDef[]
): GridColDef[] => {
    return columns.map((column: GridColDef) => {
        if (column.type === 'number') {
            return {
                ...column,
                filterOperators: numericOperators
            };
        }
        if (column.type === 'string') {
            return {
                ...column,
                filterOperators: stringOperators
            };
        }
        return column;
    });
};
