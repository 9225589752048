import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Button, Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import { CountdownComponent } from '../helperComponents/countdownTimer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& div': {
                margin: '0 auto'
            },
            height: '560px',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '364px'
            }
        },
        imageGallery: {
            backgroundColor: theme.palette.grey[800],
            width: '100%',
            maxWidth: '1920px',
            cursor: 'pointer',
            position: 'absolute',
            objectFit: 'cover',
            backgroundSize: 'cover',
            margin: '0 auto',
            '& img.image-gallery-image': {
                height: '560px',
                objectFit: 'cover',
                objectPosition: 'top'
            },
            zIndex: 1,
            marginTop: '-164',
            '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg':
                {
                    height: '60px !important'
                },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '& img.image-gallery-image': {
                    width: '100% !important',
                    height: '200px'
                }
            },
            left: '50%',
            transform: 'translate(-50%, 0)'
        },
        linksBox: {
            position: 'absolute',
            zIndex: 2,
            height: '164px',
            width: '791px',
            display: 'table',
            left: '50%',
            borderBottomWidth: '2px',
            borderBottomColor: theme.palette.secondary.light,
            borderBottomStyle: 'solid',
            transform: 'translate(-50%, 344px)',
            backgroundColor: 'rgba(250, 250, 249, 0.95)',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: 'rgba(250, 250, 249, 1)',
                width: '100%',
                '& img.image-gallery-image': {
                    width: '100% !important',
                    height: '200px'
                },
                transform: 'translate(-50%, 200px)'
            }
        },
        linkTitle: {
            color: theme.palette.secondary.main,
            marginLeft: '16px',
            marginTop: '16px'
        },
        linkBody: {
            color: theme.palette.grey[800],
            marginLeft: '16px',
            marginTop: '16px'
        },
        linkButton: {
            marginLeft: '16px',
            marginTop: '16px'
        },
        saleStartTime: {
            fontWeight: 600,
            color: theme.palette.grey[800],
            marginLeft: '16px !important',
            marginTop: '16px !important'
        }
    })
);

type HomePageImageCarouselProps = {
    isDigital: boolean;
};

type ButtonData = {
    title: string;
    url: string;
    order: string;
};

type ImageData = {
    image: string;
    order: string;
    altText?: string;
    linkBoxTitle: string;
    linkBoxBody?: string;
    saleStartTime?: string;
    links?: ButtonData[];
};

export const HomePageImageCarousel = (props: HomePageImageCarouselProps) => {
    const { isDigital } = props;
    const classes = useStyles();
    const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
    const [imageData, setImageData] = useState<[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const getImages = () => {
            try {
                API.get(
                    'cms-api',
                    `get-features?property=${
                        isDigital ? 'digital' : 'physical'
                    }`,
                    {
                        response: true,
                        responseType: 'text'
                    }
                )
                    .then((response) => {
                        const responseData = response.data.data.sort(
                            (a: ImageData, b: ImageData) => {
                                return Number(a.order) > Number(b.order)
                                    ? 1
                                    : -1;
                            }
                        );
                        const imagesList = responseData.map((r: ImageData) => {
                            return {
                                original: r.image,
                                originalAlt: r.altText
                            };
                        });
                        setImages(imagesList);
                        setImageData(responseData);
                    })
                    .catch((error) => {
                        console.log(
                            'error in getting homepage images from CMS',
                            error
                        );
                    });
            } catch (error) {
                console.log('error in getting homepage images from CMS', error);
            }
        };

        getImages();
    }, [isDigital]);

    const onSlideHandler = (index: number) => {
        setCurrentIndex(index);
    };

    const current = imageData ? (imageData[currentIndex] as ImageData) : null;

    return images && images.length > 0 ? (
        <div data-testid="homepage-images" className={classes.container}>
            {current && (
                <div className={classes.linksBox} data-testid="linkbox">
                    <Typography
                        className={classes.linkTitle}
                        variant="h2"
                        style={{
                            fontWeight: 600
                        }}
                    >
                        {current.linkBoxTitle ? current.linkBoxTitle : ''}
                    </Typography>
                    {isDigital && current.saleStartTime ? (
                        <div className={classes.saleStartTime}>
                            <CountdownComponent
                                remainingTime={new Date(current.saleStartTime)
                                    .valueOf()
                                    .toString()}
                            />
                        </div>
                    ) : (
                        <Typography
                            className={classes.linkBody}
                            variant="body2"
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {current.linkBoxBody ? current.linkBoxBody : ''}
                        </Typography>
                    )}
                    {current.links &&
                        current.links
                            .sort((a: ButtonData, b: ButtonData) => {
                                return Number(a.order) > Number(b.order)
                                    ? 1
                                    : -1;
                            })
                            .map((b) => (
                                <Button
                                    key={b.order}
                                    className={classes.linkButton}
                                    variant={
                                        b.order === '1'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color={
                                        b.order === '1'
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    disableElevation
                                    href={b.url}
                                >
                                    {b.title}
                                </Button>
                            ))}
                </div>
            )}
            <div className={classes.imageGallery}>
                <ImageGallery
                    items={images}
                    showBullets
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails={false}
                    useBrowserFullscreen={false}
                    onBeforeSlide={onSlideHandler}
                />
            </div>
        </div>
    ) : (
        <div data-testid="no-image" />
    );
};
