import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core/';
import { Auth } from 'aws-amplify';

export default function LandingPage() {
    const [currentUser, setCurrentUser] = useState<string>('');

    Auth.currentAuthenticatedUser().then((user) => {
        setCurrentUser(user.attributes.given_name);
    });

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid xs={10} item>
                <Typography variant="h2">
                    Welcome {currentUser}. This page is only visible for logged
                    in users.
                </Typography>
            </Grid>
        </Grid>
    );
}
