import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Link, Button } from '@material-ui/core';
import { API } from 'aws-amplify';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& div': {
                margin: '0 auto'
            },
            height: '600px',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto'
            },
            backgroundColor: theme.palette.grey[500]
        },
        title: {
            paddingTop: '24px',
            height: '82px',
            fontWeight: 600,
            color: theme.palette.secondary.main
        },
        newsTileContainer: {
            '& div': {
                margin: '0 auto'
            },
            margin: '0 auto',
            marginBottom: '24px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto'
            }
        },
        tile: {
            height: '370px',
            paddingLeft: '12px',
            paddingRight: '12px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto'
            },
            '& img': {
                height: '220px',
                width: '100%',
                marginBottom: '16px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: 'auto'
                }
            }
        },
        tiles: {
            maxWidth: '1228px'
        },
        newsDate: {
            fontSize: '14px',
            fontWeight: 400,
            marginBottom: '0px',
            color: theme.palette.grey[700]
        },
        allNews: {
            color: theme.palette.primary.main,
            marginBottom: '24px !important',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                marginBottom: '20px !important'
            }
        },
        register: {
            backgroundColor: theme.palette.primary.main,
            height: '124px',
            color: theme.palette.grey[50],
            fontWeight: 700,
            paddingTop: '24px',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                height: '144px'
            }
        },
        registerTitle: {
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        registerButton: {
            width: '302px',
            backgroundColor: theme.palette.grey[50],
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                color: theme.palette.primary.main
            },
            marginTop: '16px !important'
        }
    })
);

type NewsData = {
    image: string;
    date: string;
    title: string;
    slug: string;
    link: string;
};

export const LatestNews = () => {
    const classes = useStyles();
    const [news, setNews] = useState<NewsData[]>([]);

    useEffect(() => {
        const getImages = () => {
            API.get('cms-api', `get-news?limit=3`, {
                response: true,
                responseType: 'text'
            })
                .then((response) => {
                    const responseData = response.data.data.sort(
                        (a: NewsData, b: NewsData) => {
                            return new Date(a.date) > new Date(b.date) ? 1 : -1;
                        }
                    );
                    setNews(responseData);
                })
                .catch((error) => {
                    console.log(
                        'error in getting homepage news list from CMS',
                        error
                    );
                });
        };

        getImages();
    }, []);

    const NewsTile = (props: { data: NewsData }) => {
        const { data } = props;

        return (
            <Grid
                container
                className={classes.newsTileContainer}
                data-testid={`news-tile-${data.slug}`}
            >
                <Grid item xs={12} md={12}>
                    <Link href={data.link}>
                        <img
                            data-testid={`news-img-${data.image}`}
                            src={data.image}
                            alt={data.title}
                            height="150px"
                            width="104px"
                        />
                    </Link>
                    <Typography
                        variant="body2"
                        className={classes.newsDate}
                        data-testid={`news-date-${data.date.toString()}`}
                    >
                        {moment(new Date(data.date)).format('DD MMM YYYY')}
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 600
                        }}
                    >
                        {data.title}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    if (news)
        return (
            <Grid container className={classes.container}>
                <Grid item xs={12} md={12} className={classes.title}>
                    <Typography variant="h2" align="center" data-testid="title">
                        Latest News
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.tiles}>
                    <Grid container>
                        {news.map((n) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={n.slug}
                                className={classes.tile}
                            >
                                <NewsTile data={n} />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.allNews}>
                        <Link href="/">
                            <Typography variant="body2" align="center">
                                All news
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.register}
                    data-testid="register"
                >
                    <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.registerTitle}
                    >
                        <Typography variant="body2" align="center">
                            Get all the latest Inglis news and notifications
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container justifyContent="center">
                            <Button
                                className={classes.registerButton}
                                variant="outlined"
                                disableElevation
                                href="/"
                                data-testid="register-button"
                            >
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    return <div>No News</div>;
};
