import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
    Button,
    Typography,
    Toolbar,
    AppBar,
    IconButton,
    Drawer,
    Container,
    Menu,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tabs,
    Tab,
    TextField,
    alpha
} from '@material-ui/core/';
import { Auth } from 'aws-amplify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { routes, digitalRoutes } from '../routes/routes';
import data from '../../utls/navigator.json';
import upperNavData from '../../utls/inglisUpperNav.json';
import { ReactComponent as InglisLogo } from '../../icons/inglis_logo_white.svg';
import { ReactComponent as InglisDigitalLogo } from '../../icons/InglisDigital_Logo_White.svg';

interface GlobalNavProps {
    appState: number;
    authedState: boolean;
    setInglisAppState: (val: number) => void;
}

interface MenuProps {
    authState: boolean;
    handleReload: () => void;
    appState: number;
}

interface SubMenuProps {
    sublinks: Array<Record<string, any>>;
    anchorElement: null | HTMLElement;
    handleSubMenuClose: () => void;
}

type ProfileMenuProps = {
    anchorElement: null | HTMLElement;
    handleSubMenuClose: () => void;
    handleReload: () => void;
};

interface NavLinkProps {
    mobileState: boolean;
    appState: number;
    setDrawer: Dispatch<SetStateAction<boolean>> | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.main
        },
        mainLogo: {
            height: '48px',
            width: '96px',
            cursor: 'pointer',
            '& path': {
                fill: 'white'
            }
        },
        accountIcon: {
            color: theme.palette.grey[50],
            height: '32px',
            width: '32px'
        },
        appBarStyling: {
            backgroundColor: 'transparent',
            padding: '0px'
        },
        closeDrawerIcon: {
            cursor: 'pointer',
            'z-index': '9999',
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(-4),
            color: theme.palette.grey[50],
            padding: 0
        },
        drawerStyling: {
            backgroundColor: theme.palette.secondary.main,
            width: '85%'
        },
        digitalDrawerStyling: {
            backgroundColor: theme.palette.grey[900],
            width: '85%'
        },
        backdropStyle: {
            background: `${alpha(theme.palette.grey[900], 0.8)}`
        },
        globalNavStyling: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column'
        },
        toolBarStyling: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        desktopNavStyling: {
            padding: theme.spacing(0, 4)
        },
        mobileNavZone: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            marginTop: theme.spacing(2)
        },
        navigator: {
            width: '90%',
            [theme.breakpoints.down('sm')]: {
                width: 'auto'
            }
        },
        listStyling: {
            listStyle: 'none',
            display: 'flex',
            padding: theme.spacing(0, 5),
            '& li': {
                marginRight: theme.spacing(5)
            },
            [theme.breakpoints.down('md')]: {
                'flex-direction': 'column',
                height: '100%',
                padding: theme.spacing(3, 3)
            }
        },
        listItemstyle: {
            color: 'white',
            textDecoration: 'none'
        },
        subMenuListStyle: {
            padding: theme.spacing(2)
        },
        subMenuListItemstyle: {
            color: 'black',
            textDecoration: 'none'
        },
        amplifySignOutZone: {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(0.5),
            color: theme.palette.primary.main,
            width: '70%',
            margin: theme.spacing(1, 2)
        },
        logInButton: {
            width: '80px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey[50]
        },
        amplifySignOutStyle: {
            '--amplify-primary-color': 'transparent',
            '--amplify-primary-tint': theme.palette.secondary.main,
            '--amplify-primary-shade': theme.palette.secondary.main,
            '--amplify-font-family': theme.typography.fontFamily,
            '--amplify-primary-contrast': theme.palette.primary.main
        },
        accordionStyling: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
            minHeight: '24px !important',
            padding: '15px',
            '& div': {
                padding: '0',
                border: 'none'
            },
            '&:before': {
                backgroundColor: 'transparent'
            }
        },
        accordionSummaryStyling: {
            height: '24px !important',
            minHeight: '24px !important'
        },
        accordionDetailsStyling: {
            flexDirection: 'column',
            marginTop: '10px',
            '& a': {
                padding: '10px'
            }
        },
        navLinks: {
            display: 'flex',
            alignItems: 'center'
        },
        accordioIcon: {
            fill: 'white'
        },
        inglisNavTabs: {
            color: theme.palette.grey[50],
            borderBottom: `1px solid ${alpha(theme.palette.grey[50], 0.2)}`,
            minHeight: theme.spacing(5),
            height: theme.spacing(5),
            '& .MuiTabs-flexContainer': {
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                    justifyContent: 'initial'
                }
            }
        },
        tabTextStyle: {
            fontSize: '12px'
        },
        searchIconStyle: {
            cursor: 'pointer',
            color: theme.palette.grey[800]
        },
        endZone: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        globalSearch: {
            width: '280px',
            marginRight: theme.spacing(2),
            '& div': {
                backgroundColor: theme.palette.grey[50]
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: theme.spacing(2, 0)
            }
        },
        profileMenu: {
            '& .MuiMenu-paper': {
                width: '224px'
            }
        },
        lineBreak: {
            margin: theme.spacing(0, 2),
            borderTop: `1px solid ${theme.palette.grey[100]}`
        }
    })
);

export default function GlobalNav({
    appState,
    authedState,
    setInglisAppState
}: GlobalNavProps) {
    const classes = useStyles();
    const history = useHistory();
    const [mobileState, setMobileState] = useState({
        mobileView: false
    });
    const [inglisSite, setInglisSite] = useState<number>(0);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
        null
    );
    const [subLinks, setSubLinks] = useState<Record<string, any>[]>([]);
    const reload = () => {
        history.push('/');
        window.location.reload();
    };
    const { mobileView } = mobileState;
    const upperTabToIndex: any = {};

    upperNavData.links.forEach((item: any) => {
        upperTabToIndex[item.id - 1] = item.path;
    });

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 1200
                ? setMobileState((prevState) => ({
                      ...prevState,
                      mobileView: true
                  }))
                : setMobileState((prevState) => ({
                      ...prevState,
                      mobileView: false
                  }));
        };
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness());

        return () => {
            window.removeEventListener('resize', () => setResponsiveness());
        };
    }, []);

    useEffect(() => {
        const globalNavComponent = document.getElementById('inglis-global-nav');
        const icon = document.getElementById('favicon') as HTMLLinkElement;
        setInglisSite(appState);
        const navBackgroundColor = appState === 1 ? '#000000': '#152759';
        const siteFavIcon =
            appState === 1 ? 'favicon-digital.ico' : 'favicon.ico';
        globalNavComponent!.style.backgroundColor = navBackgroundColor;
        icon.href = `${window.location.origin}/${siteFavIcon}`;
    }, [appState]);

    const openCloseDrawer = (
        drawerVal: boolean,
        subLinkItems: any | null,
        event: React.MouseEvent<HTMLAnchorElement>
    ) => {
        if (subLinkItems.length > 0) {
            event.preventDefault();
            setAnchorElement(event.currentTarget);
            setSubLinks(subLinkItems);
        }
    };

    const handleSubMenuClose = () => {
        setAnchorElement(null);
    };

    const handleChange = (
        event: React.ChangeEvent<Record<string, never>>,
        newValue: number
    ) => {
        if (newValue === 0 || newValue === 1) {
            history.push(`${upperTabToIndex[newValue]}`);
            setInglisSite(newValue);
            setInglisAppState(newValue);
        }
    };

    return (
        <div className={classes.root} id="inglis-global-nav">
            <Tabs
                value={inglisSite}
                className={classes.inglisNavTabs}
                indicatorColor="primary"
                variant="scrollable"
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: 'white' } }}
            >
                {upperNavData.links.length > 0 &&
                    upperNavData.links.map((item: any) => {
                        return item.pathtype === 'external' ? (
                            <Tab
                                label={
                                    <Link
                                        to={{ pathname: item.path }}
                                        target="_blank"
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.tabTextStyle}
                                        >
                                            {item.text}
                                        </Typography>{' '}
                                    </Link>
                                }
                            />
                        ) : (
                            <Tab
                                label={
                                    <Link
                                        to={item.path}
                                        onClick={(
                                            e: React.MouseEvent<HTMLAnchorElement>
                                        ) =>
                                            openCloseDrawer(
                                                false,
                                                item.sublinks,
                                                e
                                            )
                                        }
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.tabTextStyle}
                                        >
                                            {item.text}
                                        </Typography>{' '}
                                    </Link>
                                }
                            />
                        );
                    })}
            </Tabs>
            {subLinks.length > 0 && (
                <SubMenu
                    sublinks={subLinks}
                    anchorElement={anchorElement}
                    handleSubMenuClose={handleSubMenuClose}
                />
            )}
            <AppBar className={classes.appBarStyling} position="static">
                {mobileView ? (
                    <MobileNav
                        appState={inglisSite}
                        authState={authedState}
                        handleReload={reload}
                    />
                ) : (
                    <DesktopNav
                        appState={inglisSite}
                        authState={authedState}
                        handleReload={reload}
                    />
                )}
            </AppBar>
        </div>
    );
}

const DesktopNav = ({ appState, authState, handleReload }: MenuProps) => {
    const [profileAnchorElement, setProfileAnchorElement] =
        useState<null | HTMLElement>(null);
    const classes = useStyles();
    const history = useHistory();

    const openCloseProfileMenu = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        setProfileAnchorElement(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorElement(null);
    };

    const loginButtonClick = () => {
        sessionStorage.setItem('loginPrevLocation', history.location.pathname);
        history.push('/signin');
    };

    return (
        <Container disableGutters maxWidth="xl">
            <Toolbar className={classes.desktopNavStyling}>
                {appState === 1 ? (
                    <InglisDigitalLogo
                        onClick={() => history.push('/digital')}
                        className={classes.mainLogo}
                    />
                ) : (
                    <InglisLogo
                        onClick={() => history.push('/')}
                        className={classes.mainLogo}
                    />
                )}

                <NavLinks
                    appState={appState}
                    mobileState={false}
                    setDrawer={undefined}
                />
                <div className={classes.endZone}>
                    <TextField
                        placeholder="Find lots, dams, sires, + more"
                        variant="outlined"
                        size="small"
                        className={classes.globalSearch}
                        InputProps={{
                            endAdornment: (
                                <SearchIcon
                                    className={classes.searchIconStyle}
                                />
                            )
                        }}
                    />
                    {authState ? (
                        <IconButton
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                openCloseProfileMenu(e)
                            }
                        >
                            <AccountCircleOutlined
                                className={classes.accountIcon}
                            />
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            className={classes.logInButton}
                            onClick={() => {
                                loginButtonClick();
                            }}
                        >
                            Log in
                        </Button>
                    )}
                </div>
            </Toolbar>
            <ProfileMenu
                anchorElement={profileAnchorElement}
                handleReload={handleReload}
                handleSubMenuClose={handleProfileMenuClose}
            />
        </Container>
    );
};

const MobileNav = ({ appState, authState, handleReload }: MenuProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [drawerState, setDrawerState] = useState(false);
    const [profileAnchorElement, setProfileAnchorElement] =
        useState<null | HTMLElement>(null);

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawerState(open);
        };

    const openCloseProfileMenu = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        setProfileAnchorElement(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorElement(null);
    };

    return (
        <Toolbar className={classes.globalNavStyling}>
            <div className={classes.mobileNavZone}>
                <div className={classes.toolBarStyling}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {appState === 1 ? (
                        <InglisDigitalLogo
                            onClick={() => history.push('/digital')}
                            className={classes.mainLogo}
                        />
                    ) : (
                        <InglisLogo
                            onClick={() => history.push('/')}
                            className={classes.mainLogo}
                        />
                    )}
                </div>
                {authState ? (
                    <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                            openCloseProfileMenu(e)
                        }
                    >
                        <AccountCircleOutlined
                            className={classes.accountIcon}
                        />
                    </IconButton>
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        className={classes.logInButton}
                        onClick={() => {
                            history.push('/signin');
                        }}
                    >
                        Log in
                    </Button>
                )}
                <ProfileMenu
                    anchorElement={profileAnchorElement}
                    handleReload={handleReload}
                    handleSubMenuClose={handleProfileMenuClose}
                />
                {drawerState && (
                    <IconButton
                        className={classes.closeDrawerIcon}
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                {appState === 1 ? (
                    <Drawer
                        classes={{ paper: classes.digitalDrawerStyling }}
                        id="inglis-mobile-drawer"
                        open={drawerState}
                        onClose={toggleDrawer(false)}
                        ModalProps={{
                            BackdropProps: {
                                classes: { root: classes.backdropStyle }
                            }
                        }}
                    >
                        <NavLinks
                            appState={appState}
                            mobileState
                            setDrawer={setDrawerState}
                        />
                    </Drawer>
                ) : (
                    <Drawer
                        classes={{ paper: classes.drawerStyling }}
                        id="inglis-mobile-drawer"
                        open={drawerState}
                        onClose={toggleDrawer(false)}
                        ModalProps={{
                            BackdropProps: {
                                classes: { root: classes.backdropStyle }
                            }
                        }}
                    >
                        <NavLinks
                            appState={appState}
                            mobileState
                            setDrawer={setDrawerState}
                        />
                    </Drawer>
                )}
            </div>
            <TextField
                placeholder="Find lots, dams, sires, + more"
                variant="outlined"
                size="small"
                className={classes.globalSearch}
                InputProps={{
                    endAdornment: (
                        <SearchIcon className={classes.searchIconStyle} />
                    )
                }}
            />
        </Toolbar>
    );
};

const NavLinks = ({ appState, mobileState, setDrawer }: NavLinkProps) => {
    const classes = useStyles();
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
        null
    );
    const [subLinks, setSubLinks] = useState<Record<string, any>[]>([]);

    const openCloseDrawer = (
        drawerVal: boolean,
        subLinkItems: any | null,
        event: React.MouseEvent<HTMLAnchorElement>
    ) => {
        if (subLinkItems.length > 0) {
            event.preventDefault();
            setAnchorElement(event.currentTarget);
            setSubLinks(subLinkItems);
        } else if (setDrawer) {
            setDrawer(drawerVal);
        }
    };

    const handleSubMenuClose = () => {
        setAnchorElement(null);
        if (setDrawer) {
            setDrawer(false);
        }
    };

    return (
        <nav className={classes.navigator}>
            {!mobileState ? (
                <ul className={classes.listStyling}>
                    {appState === 1
                        ? digitalRoutes.map((route) => {
                              return (
                                  route.display && (
                                      <li key={route.path}>
                                          {route.pathtype === 'external' ? (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={{ pathname: route.path }}
                                                  target="_blank"
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          ) : (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={route.path}
                                                  onClick={(
                                                      e: React.MouseEvent<HTMLAnchorElement>
                                                  ) =>
                                                      openCloseDrawer(
                                                          false,
                                                          route.sublinks,
                                                          e
                                                      )
                                                  }
                                              >
                                                  <Typography
                                                      variant="h5"
                                                      className={
                                                          classes.navLinks
                                                      }
                                                  >
                                                      {route.text}{' '}
                                                      {route.sublinks.length >
                                                          0 && (
                                                          <ExpandMoreIcon
                                                              className={
                                                                  classes.accordioIcon
                                                              }
                                                          />
                                                      )}
                                                  </Typography>
                                              </Link>
                                          )}
                                      </li>
                                  )
                              );
                          })
                        : routes.map((route) => {
                              return (
                                  route.display && (
                                      <li key={route.path}>
                                          {route.pathtype === 'external' ? (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={{ pathname: route.path }}
                                                  target="_blank"
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          ) : (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={route.path}
                                                  onClick={(
                                                      e: React.MouseEvent<HTMLAnchorElement>
                                                  ) =>
                                                      openCloseDrawer(
                                                          false,
                                                          route.sublinks,
                                                          e
                                                      )
                                                  }
                                              >
                                                  <Typography
                                                      variant="h5"
                                                      className={
                                                          classes.navLinks
                                                      }
                                                  >
                                                      {route.text}{' '}
                                                      {route.sublinks.length >
                                                          0 && (
                                                          <ExpandMoreIcon
                                                              className={
                                                                  classes.accordioIcon
                                                              }
                                                          />
                                                      )}
                                                  </Typography>
                                              </Link>
                                          )}
                                      </li>
                                  )
                              );
                          })}
                    {data.links.length > 0 &&
                        data.links.map((item: any) => {
                            return (
                                <li key={item.path}>
                                    {item.pathtype === 'external' ? (
                                        <Link
                                            className={classes.listItemstyle}
                                            to={{ pathname: item.path }}
                                            target="_blank"
                                        >
                                            <Typography variant="h5">
                                                {item.text}
                                            </Typography>
                                        </Link>
                                    ) : (
                                        <Link
                                            className={classes.listItemstyle}
                                            to={item.path}
                                            onClick={(
                                                e: React.MouseEvent<HTMLAnchorElement>
                                            ) =>
                                                openCloseDrawer(
                                                    false,
                                                    item.sublinks,
                                                    e
                                                )
                                            }
                                        >
                                            <Typography variant="h5">
                                                {item.text}
                                            </Typography>
                                        </Link>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            ) : (
                <div className={classes.listStyling}>
                    {appState === 1
                        ? digitalRoutes.map((route) => {
                              return (
                                  route.display &&
                                  (route.sublinks.length > 0 ? (
                                      <Accordion
                                          className={classes.accordionStyling}
                                      >
                                          <AccordionSummary
                                              className={
                                                  classes.accordionSummaryStyling
                                              }
                                              expandIcon={
                                                  <ExpandMoreIcon
                                                      className={
                                                          classes.accordioIcon
                                                      }
                                                  />
                                              }
                                          >
                                              <Typography variant="h5">
                                                  {route.text}
                                              </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails
                                              className={
                                                  classes.accordionDetailsStyling
                                              }
                                          >
                                              {route.sublinks.map(
                                                  (subroute: any) => {
                                                      return subroute.pathtype ===
                                                          'external' ? (
                                                          <Link
                                                              className={
                                                                  classes.listItemstyle
                                                              }
                                                              to={{
                                                                  pathname:
                                                                      subroute.path
                                                              }}
                                                              target="_blank"
                                                          >
                                                              <Typography variant="body1">
                                                                  {
                                                                      subroute.text
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      ) : (
                                                          <Link
                                                              className={
                                                                  classes.listItemstyle
                                                              }
                                                              to={subroute.path}
                                                          >
                                                              <Typography variant="body1">
                                                                  {
                                                                      subroute.text
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      );
                                                  }
                                              )}
                                          </AccordionDetails>
                                      </Accordion>
                                  ) : (
                                      <Accordion
                                          className={classes.accordionStyling}
                                      >
                                          {route.pathtype === 'external' ? (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={{ pathname: route.path }}
                                                  target="_blank"
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          ) : (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={route.path}
                                                  onClick={(
                                                      e: React.MouseEvent<HTMLAnchorElement>
                                                  ) =>
                                                      openCloseDrawer(
                                                          false,
                                                          route.sublinks,
                                                          e
                                                      )
                                                  }
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          )}
                                      </Accordion>
                                  ))
                              );
                          })
                        : routes.map((route) => {
                              return (
                                  route.display &&
                                  (route.sublinks.length > 0 ? (
                                      <Accordion
                                          className={classes.accordionStyling}
                                      >
                                          <AccordionSummary
                                              className={
                                                  classes.accordionSummaryStyling
                                              }
                                              expandIcon={
                                                  <ExpandMoreIcon
                                                      className={
                                                          classes.accordioIcon
                                                      }
                                                  />
                                              }
                                          >
                                              <Typography variant="h5">
                                                  {route.text}
                                              </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails
                                              className={
                                                  classes.accordionDetailsStyling
                                              }
                                          >
                                              {route.sublinks.map(
                                                  (subroute: any) => {
                                                      return subroute.pathtype ===
                                                          'external' ? (
                                                          <Link
                                                              className={
                                                                  classes.listItemstyle
                                                              }
                                                              to={{
                                                                  pathname:
                                                                      subroute.path
                                                              }}
                                                              target="_blank"
                                                          >
                                                              <Typography variant="body1">
                                                                  {
                                                                      subroute.text
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      ) : (
                                                          <Link
                                                              className={
                                                                  classes.listItemstyle
                                                              }
                                                              to={subroute.path}
                                                          >
                                                              <Typography variant="body1">
                                                                  {
                                                                      subroute.text
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      );
                                                  }
                                              )}
                                          </AccordionDetails>
                                      </Accordion>
                                  ) : (
                                      <Accordion
                                          className={classes.accordionStyling}
                                      >
                                          {route.pathtype === 'external' ? (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={{ pathname: route.path }}
                                                  target="_blank"
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          ) : (
                                              <Link
                                                  className={
                                                      classes.listItemstyle
                                                  }
                                                  to={route.path}
                                                  onClick={(
                                                      e: React.MouseEvent<HTMLAnchorElement>
                                                  ) =>
                                                      openCloseDrawer(
                                                          false,
                                                          route.sublinks,
                                                          e
                                                      )
                                                  }
                                              >
                                                  <Typography variant="h5">
                                                      {route.text}
                                                  </Typography>
                                              </Link>
                                          )}
                                      </Accordion>
                                  ))
                              );
                          })}
                    {data.links.length > 0 &&
                        data.links.map((item: any) => {
                            return item.sublinks.length > 0 ? (
                                <Accordion className={classes.accordionStyling}>
                                    <AccordionSummary
                                        className={
                                            classes.accordionSummaryStyling
                                        }
                                        expandIcon={
                                            <ExpandMoreIcon
                                                className={classes.accordioIcon}
                                            />
                                        }
                                    >
                                        <Typography variant="h5">
                                            {item.text}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className={
                                            classes.accordionDetailsStyling
                                        }
                                    >
                                        {item.sublinks.map((subroute: any) => {
                                            return subroute.pathtype ===
                                                'external' ? (
                                                <Link
                                                    className={
                                                        classes.listItemstyle
                                                    }
                                                    to={{
                                                        pathname: subroute.path
                                                    }}
                                                    target="_blank"
                                                >
                                                    <Typography variant="body1">
                                                        {subroute.text}
                                                    </Typography>
                                                </Link>
                                            ) : (
                                                <Link
                                                    className={
                                                        classes.listItemstyle
                                                    }
                                                    to={subroute.path}
                                                >
                                                    <Typography variant="body1">
                                                        {subroute.text}
                                                    </Typography>
                                                </Link>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <Accordion className={classes.accordionStyling}>
                                    {item.pathtype === 'external' ? (
                                        <Link
                                            className={classes.listItemstyle}
                                            to={{ pathname: item.path }}
                                            target="_blank"
                                        >
                                            <Typography variant="h5">
                                                {item.text}
                                            </Typography>
                                        </Link>
                                    ) : (
                                        <Link
                                            className={classes.listItemstyle}
                                            to={item.path}
                                            onClick={(
                                                e: React.MouseEvent<HTMLAnchorElement>
                                            ) =>
                                                openCloseDrawer(
                                                    false,
                                                    item.sublinks,
                                                    e
                                                )
                                            }
                                        >
                                            <Typography variant="h5">
                                                {item.text}
                                            </Typography>
                                        </Link>
                                    )}
                                </Accordion>
                            );
                        })}
                </div>
            )}
            {subLinks.length > 0 && (
                <SubMenu
                    sublinks={subLinks}
                    anchorElement={anchorElement}
                    handleSubMenuClose={handleSubMenuClose}
                />
            )}
        </nav>
    );
};

const SubMenu = ({
    sublinks,
    anchorElement,
    handleSubMenuClose
}: SubMenuProps) => {
    const classes = useStyles();
    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorElement}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={Boolean(anchorElement)}
            onClose={handleSubMenuClose}
        >
            {sublinks.map((item) => {
                return (
                    <MenuItem className={classes.subMenuListStyle}>
                        {item.pathtype === 'external' ? (
                            <Link
                                className={classes.subMenuListItemstyle}
                                to={{ pathname: item.path }}
                                target="_blank"
                                onClick={handleSubMenuClose}
                            >
                                {item.text}
                            </Link>
                        ) : (
                            <Link
                                className={classes.subMenuListItemstyle}
                                to={item.path}
                                onClick={handleSubMenuClose}
                            >
                                {item.text}
                            </Link>
                        )}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

const ProfileMenu = ({
    anchorElement,
    handleReload,
    handleSubMenuClose
}: ProfileMenuProps) => {
    const classes = useStyles();
    const [currentUser, setCurrentUser] = useState<string>('');
    Auth.currentAuthenticatedUser().then((user) => {
        setCurrentUser(user.attributes.given_name);
    });
    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorElement}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={Boolean(anchorElement)}
            onClose={handleSubMenuClose}
            className={classes.profileMenu}
        >
            <Typography
                className={classes.subMenuListStyle}
                variant="body2"
                key="hello-user"
            >
                Hi {currentUser}
            </Typography>
            <hr className={classes.lineBreak} />
            <MenuItem
                component={Link}
                to="/dashboard"
                className={classes.subMenuListStyle}
                key="dashboard-menuitem"
            >
                Dashboard
            </MenuItem>
            <MenuItem
                className={classes.subMenuListStyle}
                key="my-entries-menuitem"
            >
                My Entries
            </MenuItem>
            <MenuItem
                className={classes.subMenuListStyle}
                key="purhcase-history-menuitem"
            >
                Purchase History
            </MenuItem>
            <MenuItem
                className={classes.subMenuListStyle}
                key="account-settings-menuitem"
                component={Link}
                to="/account-details/personalinfo"
            >
                Account Settings
            </MenuItem>
            <MenuItem
                className={classes.subMenuListStyle}
                key="notification-settings-menuitem"
            >
                Notification Settings
            </MenuItem>
            <div className={classes.amplifySignOutZone} key="signout-menuitem">
                <AmplifySignOut
                    className={classes.amplifySignOutStyle}
                    handleAuthStateChange={handleReload}
                />
            </div>
        </Menu>
    );
};
