import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { API } from 'aws-amplify';
import { Grid } from '@material-ui/core/';
import { LatestNews } from '../contentComponents/latestNews';
import { Partners } from '../contentComponents/partners';
import { HomePageImageCarousel } from '../contentComponents/homePageImageCarousel';
import { GroupWinners } from '../contentComponents/groupWinners';
import { CurrentAndUpcomingSalesComponent } from '../helperComponents/currentAndUpcomingSales';
import { PastSalesComponent } from '../helperComponents/pastSales';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export const InglisHomePage = () => {
    const [currentSales, setCurrentSales] = useState<any>([]);
    const [pastSales, setPastSales] = useState<any>([]);
    const history = useHistory();

    useEffect(() => {
        getCurrentSales();
        getPastSales();
    }, []);

    const getCurrentSales = () => {
        API.get('rest-api', `/catalogue/current`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                const sortedResponse = response.data.sort((a: any, b: any) => {
                    return a.entriesClose.localeCompare(b.entriesClose);
                });
                setCurrentSales(sortedResponse);
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    alert('Cannot find current catalogue');
                }
            });
    };

    const getPastSales = () => {
        API.get('rest-api', `/catalogue/past`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                const sortedResponse = response.data.sort((a: any, b: any) => {
                    return b.biddingCloses.localeCompare(a.biddingCloses);
                });
                setPastSales(sortedResponse);
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    alert('Cannot find current catalogue');
                }
            });
    };

    const gotoPastSales = ()=> {
        history.push('/pastsales');
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <HomePageImageCarousel isDigital={false} />
            </Grid>
            <CurrentAndUpcomingSalesComponent {...{ currentSales }} />
            <PastSalesComponent
                {...{ PastSales: pastSales, GotoPastSales: gotoPastSales }}
            />
            <Grid item xs={12}>
                <LatestNews />
                <GroupWinners />
            </Grid>
            <Grid item xs={12}>
                <Partners />
            </Grid>
        </Grid>
    );
};
