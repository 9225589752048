import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Toolbar, Grid, Tabs, Tab, Box } from '@material-ui/core/';
import { useHistory, useParams } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import moment from 'moment';
import { SaleOverviewContent } from '../contentComponents/saleOverviewContent';
import { MainCataloguePage } from '../contentComponents/catalogue';
import { MainPhysicalCataloguePage } from '../contentComponents/physicalCatalogue';
import { urlStringFormatter } from '../../utls/formatHelper';
import { MainCurrentSaleCataloguePage } from '../contentComponents/currentSaleCatalogue';
import { DEFAULT_SALETILE_IMAGE } from '../../utls/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        salesOverviewContainer: {
            padding: theme.spacing(13, 4, 0, 4),
            [theme.breakpoints.down('sm')]: {
                padding: '136px 0px'
            }
        },
        headerBanner: {
            backgroundColor: theme.palette.grey[100],
            height: '104px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '0',
            padding: '0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                height: '104px',
                padding: theme.spacing(2, 0)
            }
        },
        headerContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1920px',
            '& img': {
                marginLeft: theme.spacing(4)
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                padding: theme.spacing(0),
                '& img': {
                    marginLeft: theme.spacing(2)
                }
            }
        },
        headerHeading: {
            color: theme.palette.secondary.main,
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px'
            }
        },
        headerDivs: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '320px',
            '& div': {
                display: 'flex',
                '& p': {
                    marginRight: theme.spacing(4),
                    whiteSpace: 'nowrap',
                    alignItems: 'center'
                }
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '& div': {
                    justifyContent: 'space-between',
                    width: '100%',
                    '& p': {
                        marginRight: `${theme.spacing(0)}!important`,
                        paddingRight: theme.spacing(1)
                    }
                }
            }
        },
        headerTextZone: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '30px',
            alignItems: 'center',
            padding: theme.spacing(0, 4),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: 'auto',
                width: 'auto',
                padding: theme.spacing(0),
                alignItems: 'baseline',
                marginLeft: theme.spacing(2)
            }
        },
        headerSecondaryTextZone: {
            display: 'flex',
            alignItems: 'center',
            color: '#656562'
        },
        headerIcons: {
            height: '20px'
        },
        selectionTabs: {
            borderBottom: `1px solid ${theme.palette.grey[100]}`
        },
        selectionTabsBackground: {
            height: '48px',
            backgroundColor: theme.palette.grey[50],
            position: 'absolute',
            width: '100%',
            left: '0',
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        salesTabsContent: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0)
            }
        }
    })
);

type TabPanelProps = {
    children: React.ReactNode;
    index: any;
    value: any;
};

// sets value of tab to index
const tabToIndex: any = {
    0: 'overview',
    1: 'lots',
    2: 'shortlist',
    3: 'stats',
    4: 'withdrawals',
    5: 'passedin'
};

// sets value of index to tab
const indexToTab: any = {
    overview: 0,
    lots: 1,
    shortlist: 2,
    stats: 3,
    withdrawals: 4,
    passedin: 5
};

export function SaleOverview() {
    const classes = useStyles();
    const salesParams: any = useParams();
    const history = useHistory();
    const siteState = sessionStorage.getItem('siteState');

    const [selectedTab, setSelectedTab] = useState<number>(
        indexToTab[salesParams.tabs]
    );
    const [catalogueInfo, setCatalogueInfo] = useState<any>([]);
    const [saleCode, setSaleCode] = useState<string>('');
    const [saleName, setSaleName] = useState<string>('');
    const [isSaleRunning, setIsSaleRunning] = useState<boolean>(false);
    const [horseResponse, setHorseResponse] = useState<any>({});
    const [fullHorseLotsCollection, setHorseLots] = useState<any>([]);

    useEffect(() => {
        setSaleCode(salesParams.saleCode);
        getCatalogueInfo(salesParams.saleCode);
    }, []);

    const getCatalogueInfo = async (saleValue: string) => {
        const result = await API.get('rest-api', `catalogue/${saleValue}`, {
            response: true,
            responseType: 'text'
        });

        setCatalogueInfo(result.data);
        // if (siteState === 'inglis') {
        //     getLotImages(response.data.saleId);
        //     getLotVideos(response.data.saleId);
        // }
        getCatalogueLots(saleValue, result.data.saleVenue);
        /**
         * TODO: TODO: though the sale is alredy ended in Ardex Premier since import sale doesn't run for past sales,
         * the status won't update and keep status bidding even though it's status change to ended
         * Condition to render Catalogue page could be changed to bidding close or sale end date but stage needs to be updated anyway
         * https://team-1625701534297.atlassian.net/jira/software/projects/IU/boards/2?selectedIssue=IU-658
         */
        const saleRunning = result.data.stage === 'bidding';
        setIsSaleRunning(saleRunning);

        return () => {
            API.cancel(result);
        };
    };

    const getCatalogueLots = async (saleValue: string, saleVenue: string) => {
        const response = await API.get(
            'rest-api',
            `catalogue/${saleValue}/lots`,
            {
                response: true,
                responseType: 'text'
            }
        );

        const horseLots = response.data.lots.map((item: any) => {
            const horseLotResponse = {
                id: item.lotNumber,
                sessionNumber: item.sessionNumber,
                images: item.images,
                sex: item.horse.sex,
                category: item.category,
                name: item.horse.name,
                country: item.horse.country,
                sire: item.horse.sire.name,
                inFoalTo: item.broodmare?.coveringStallion?.name,
                dam: item.horse.dam.name,
                colour: item.horse.colour,
                accountOf: item.vendor.catalogueName,
                time: item.breezeupTime,
                purchaser: item.result?.buyerName,
                purchaserCountry: item.result?.buyerLocation?.country,
                purchaserState: item.result?.buyerLocation?.state,
                purchaserSuburb: item.result?.buyerLocation?.suburb,
                price: item.result?.price
            };
            return horseLotResponse;
        });
        sessionStorage.setItem('selectedSaleName', response.data.saleName);
        setSaleName(response.data.saleName);
        setHorseResponse(response);
        setHorseLots(horseLots);
        return ()=> {
            API.cancel(response);
        }
    };

    /**
     * Upon selection of a tab, set in URL for deeplinking to return to previous selected tab
     */
    const handleTabSelection = (
        event: React.ChangeEvent<Record<string, never>>,
        newValue: number
    ) => {
        const urlHistory =
            siteState === 'inglis'
                ? `/sales/${saleCode}/${urlStringFormatter(saleName)}/${
                      tabToIndex[newValue]
                  }`
                : `/digital/sales/${saleCode}/${urlStringFormatter(saleName)}/${
                      tabToIndex[newValue]
                  }`;

        history.push(urlHistory);
        setSelectedTab(newValue);
    };

    const renderLots = () => {
        if (isSaleRunning) {
            return (
                <MainCurrentSaleCataloguePage
                    catalogueResults={fullHorseLotsCollection}
                    saleCode={salesParams.saleCode}
                />
            );
        }
        if (siteState === 'inglis') {
            return (
                <MainPhysicalCataloguePage
                    catalogueResults={fullHorseLotsCollection}
                    saleCode={salesParams.saleCode}
                />
            );
        }
        return (
            <MainCataloguePage
                catalogueResults={fullHorseLotsCollection}
                saleCode={salesParams.saleCode}
            />
        );
    };

    return (
        <div>
            <Toolbar className={classes.headerBanner}>
                <div className={classes.headerContent}>
                    <img
                        src={
                            catalogueInfo.catalogueImage ||
                            DEFAULT_SALETILE_IMAGE
                        }
                        alt="Sales Poster"
                        width="53px"
                        height="76px"
                    />

                    <div className={classes.headerTextZone}>
                        <div>
                            {Object.keys(horseResponse).length > 0 ? (
                                <Typography
                                    variant="h2"
                                    className={classes.headerHeading}
                                >
                                    {horseResponse.data.saleName}
                                </Typography>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={classes.headerDivs}>
                            {siteState === 'inglis' ? (
                                <div>
                                    <Typography
                                        className={
                                            classes.headerSecondaryTextZone
                                        }
                                        variant="body1"
                                    >
                                        <EventIcon
                                            className={classes.headerIcons}
                                        />
                                        {moment(
                                            catalogueInfo.biddingOpens
                                        ).format('DD MMM YYYY')}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.headerSecondaryTextZone
                                        }
                                        variant="body1"
                                    >
                                        <LocationOnOutlinedIcon
                                            className={classes.headerIcons}
                                        />
                                        {catalogueInfo.saleVenue}
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Typography
                                        className={
                                            classes.headerSecondaryTextZone
                                        }
                                        variant="body1"
                                    >
                                        <EventIcon
                                            className={classes.headerIcons}
                                        />
                                        {moment(
                                            catalogueInfo.biddingOpens
                                        ).format('DD MMM YYYY')}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.headerSecondaryTextZone
                                        }
                                        variant="body1"
                                    >
                                        <LocationOnOutlinedIcon
                                            className={classes.headerIcons}
                                        />
                                        Online
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Toolbar>
            <Grid container className={classes.salesOverviewContainer}>
                <div className={classes.selectionTabsBackground} />
                <Grid xs={12} item className={classes.selectionTabs}>
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        onChange={handleTabSelection}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Overview" />
                        <Tab label="Lots" />
                        <Tab label="Shortlist" />
                        <Tab label="Stats" />
                        <Tab label="Withdrawals" />
                        <Tab label="Passed In" />
                    </Tabs>
                </Grid>
                <Grid xs={12} item>
                    <TabPanel value={selectedTab} index={0}>
                        <SaleOverviewContent />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        {renderLots()}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        Shortlist
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        Stats
                    </TabPanel>
                    <TabPanel value={selectedTab} index={4}>
                        Withdrawals
                    </TabPanel>
                    <TabPanel value={selectedTab} index={5}>
                        Passed In
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    const classes = useStyles();

    return (
        <div
            className={classes.salesTabsContent}
            role="tabpanel"
            hidden={value !== index}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};
