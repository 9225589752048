import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Link } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { ApolloProvider, useQuery, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import {
    LotBiddingInfo,
    BidIncrement,
    BiddingLimit
} from '../../types/inglisTypes';
import { ausDollarFormatter } from '../../utls/formatHelper';
import { getBiddingLimit, placeBidMutation } from '../../queries/gqlQueries';
import { CreateAppSyncApolloClient } from '../../services/AppSyncApolloClient';
import { globalConfig } from '../../globalConfig';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popContentZone: {
            padding: theme.spacing(0, 2)
        },
        popAreaTop: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: theme.spacing(2, 'auto')
        },
        popAreaInput: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: theme.spacing(3, 'auto')
        },
        popAreaBottom: {
            display: 'flex',
            justifyContent: 'center',
            margin: '25px 0',
            '& button': {
                width: '100%'
            }
        },
        numField: {
            '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                {
                    '-webkit-appearance': 'none',
                    margin: 0
                },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            }
        },
        popSuccessTop: {
            padding: theme.spacing(2, 0),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        popSuccessBottom: {
            margin: theme.spacing(3, 'auto'),
            display: 'flex',
            justifyContent: 'flex-end'
        },
        successLabel: {
            color: theme.palette.success.main
        }
    })
);

type BidNowPopUpProps = {
    horseData: LotBiddingInfo[];
    saleId: string;
    saleCode: string;
    horseName: string;
    bidIncrementValues: BidIncrement[];
    handleDataMutation: (data: any) => void;
    handlePopClose: () => void;
    goToDashboard: () => void;
};

export const MainBidNowPopup = (props: BidNowPopUpProps) => {
    const apolloClient = CreateAppSyncApolloClient({
        endpoint: globalConfig.graphql_endpoint,
        authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    });

    const {
        horseData,
        saleId,
        saleCode,
        horseName,
        bidIncrementValues,
        handleDataMutation,
        handlePopClose,
        goToDashboard
    } = props;

    return (
        <ApolloProvider client={apolloClient}>
            <BidNowPopUp
                horseData={horseData}
                saleId={saleId}
                saleCode={saleCode}
                horseName={horseName}
                bidIncrementValues={bidIncrementValues}
                handleDataMutation={handleDataMutation}
                handlePopClose={handlePopClose}
                goToDashboard={goToDashboard}
            />
        </ApolloProvider>
    );
};

export const BidNowPopUp = (props: BidNowPopUpProps) => {
    const [bidAmountValue, setBidAmountValue] = useState<number>(0);
    const [userBiddingLimit, setBiddingLimit] = useState<BiddingLimit>(
        {} as BiddingLimit
    );
    const [bidDecreaseState, setBidDecreaseState] = useState<boolean>(false);
    const [bidIncreaseState, setBidIncreaseState] = useState<boolean>(true);
    const [bidCompleted, setBidCompleted] = useState<boolean>(false);
    const [confirmButtonState, setConfirmButtonState] =
        useState<boolean>(false);
    const classes = useStyles();

    const {
        horseData,
        saleId,
        saleCode,
        horseName,
        bidIncrementValues,
        handleDataMutation,
        handlePopClose
    } = props;

    const currentHorseName = horseName;
    const closePop = () => {
        handlePopClose();
    };

    useEffect(() => {
        latestBidAmountValue();
    }, []);

    useQuery(getBiddingLimit(saleCode), {
        onCompleted: (data) => {
            const result = data?.getBiddingLimit;
            if (result) {
                setBiddingLimit(result);
            }
        }
    });

    const setBidValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBidAmountValue(+event.target.value);
    };

    /**
     * Sets the bid to appropriate value depending on bid increment
     * upon leaving the textfield after user has entered a value
     *
     * The entered value is divided by the bid increment and a rounded down quotient is received.
     * The quotient is then multipled by the increment value to get the rounded acceptable value
     * dictated by the Inglis Increments. All quotients have been rounded down as required
     *
     * E.g. value of 1100 will be rounded down to 100. Value of 15050 will be rounded down to 15000.
     * Value of 19900 will be rounded down to 19000
     */
    const onTextFieldBlur = () => {
        let currentVal = bidAmountValue;
        const userFundsAvailable =
            userBiddingLimit.credit_limit -
            userBiddingLimit.commited -
            userBiddingLimit.spent;
        const minBidAmount = getMinBidAmount();
        if (currentVal < minBidAmount) {
            currentVal = minBidAmount;
        }
        const currentBidIncrement = getCurrentBidIncrement(currentVal);
        if (currentVal % currentBidIncrement.increment !== 0) {
            const quotient = Math.floor(
                currentVal / currentBidIncrement.increment
            );
            currentVal = currentBidIncrement.increment * quotient;
        }
        const confirmButtonStateFromVal = currentVal > userFundsAvailable;
        setConfirmButtonState(confirmButtonStateFromVal);
        setBidAmountValue(currentVal);
    };

    /**
     * Gets the current increment depending on the value entered by the user
     */
    const getCurrentBidIncrement = (valueCheck?: number) => {
        let currentBid = 0;
        if (horseData.length > 0) {
            currentBid = horseData[0].lot_current_bid;
        }
        if (bidAmountValue !== 0 && bidAmountValue > currentBid) {
            currentBid = bidAmountValue;
        }
        if (valueCheck!) {
            currentBid = valueCheck;
        }
        const incrementObject = bidIncrementValues.map((data) => {
            return data.from;
        });
        let distance = Math.abs(incrementObject[0] - currentBid);
        let idx = 0;
        for (let i = 0; i < incrementObject.length; i += 1) {
            const iDistance = Math.abs(incrementObject[i] - currentBid);
            if (iDistance < distance) {
                idx = i;
                distance = iDistance;
            }
        }
        if (incrementObject[idx] > currentBid) {
            idx -= 1;
            if (idx < 0) {
                idx = 0;
            }
        }
        const bidIncrement = bidIncrementValues.filter((obj) => {
            return obj.from === incrementObject[idx];
        });

        return bidIncrement[0];
    };

    /**
     * Decreases bidding value in textfield by increment on minus button press
     */
    const bidDecrease = () => {
        const currentMinAmount = getMinBidAmount();
        const currentIncrement = getCurrentBidIncrement();
        let newBidAmount = bidAmountValue;
        if (newBidAmount > currentMinAmount) {
            newBidAmount -= currentIncrement.increment;
            if (newBidAmount < currentMinAmount) {
                newBidAmount = currentMinAmount;
            }
            if (newBidAmount <= currentMinAmount) {
                setBidDecreaseState(false);
            }
        }
        setBidIncreaseState(true);
        setBidAmountValue(newBidAmount);
    };

    // increases bidding value in textfield by increment on plus button press
    const bidIncrease = () => {
        const currentIncrement = getCurrentBidIncrement();
        const userFundsAvailable =
            userBiddingLimit.credit_limit -
            userBiddingLimit.commited -
            userBiddingLimit.spent;
        let newBidAmount = bidAmountValue;
        if (newBidAmount < userFundsAvailable) {
            newBidAmount += currentIncrement.increment;
            if (newBidAmount > userFundsAvailable) {
                newBidAmount = userFundsAvailable;
                setConfirmButtonState(false);
            }
            if (newBidAmount >= userFundsAvailable) {
                setBidIncreaseState(false);
            }
        }
        setBidDecreaseState(true);
        setBidAmountValue(newBidAmount);
    };

    // gets the most minimum value that can be bidded
    const getMinBidAmount = () => {
        const MIN_BID_AMOUNT = 600;
        let currentBid = 0;
        if (horseData.length > 0) {
            currentBid = horseData[0].lot_current_bid;
        }
        const currentBidIncrement = getCurrentBidIncrement(currentBid);
        const currentBidWithIncrement =
            currentBid + currentBidIncrement.increment;
        const minimumAmount =
            currentBidWithIncrement < MIN_BID_AMOUNT
                ? MIN_BID_AMOUNT
                : currentBidWithIncrement;
        return minimumAmount;
    };

    const latestBidAmountValue = () => {
        const latestBidVal = getMinBidAmount();
        setBidAmountValue(latestBidVal);
    };

    const [bidNowMutation] = useMutation(
        placeBidMutation(bidAmountValue, saleId, saleCode),
        {
            onError: (error) => {
                alert(error.message);
            },
            onCompleted: (response) => {
                if (response) {
                    handleDataMutation(response);
                    setBidCompleted(true);
                }
            }
        }
    );

    const placeBid = () => {
        bidNowMutation();
    };

    return (
        <div>
            {bidCompleted ? (
                <div className={classes.popContentZone}>
                    <div className={classes.popSuccessTop}>
                        <Typography
                            variant="body2"
                            className={classes.successLabel}
                        >
                            Success
                        </Typography>
                        <CloseIcon
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                closePop();
                            }}
                        />
                    </div>
                    <div className={classes.popAreaInput}>
                        <Typography variant="body1">
                            You&apos;ve placed a max bid of ${bidAmountValue} on
                            Lot {saleId}, {currentHorseName}
                        </Typography>
                    </div>
                    <div className={classes.popSuccessBottom}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                props.goToDashboard();
                            }}
                        >
                            Go to dashboard
                        </Button>
                    </div>
                </div>
            ) : (
                <div className={classes.popContentZone}>
                    <div className={classes.popAreaTop}>
                        <Typography variant="body2">Your Bid</Typography>
                        <CloseIcon
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                props.handlePopClose();
                            }}
                        />
                    </div>
                    <div className={classes.popAreaInput}>
                        <RemoveCircleOutlineIcon
                            color={bidDecreaseState ? 'primary' : 'disabled'}
                            onClick={() => bidDecrease()}
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                        <TextField
                            className={classes.numField}
                            label="Max. Bid Amount"
                            variant="outlined"
                            InputProps={{
                                startAdornment: '$'
                            }}
                            size="small"
                            value={bidAmountValue}
                            onChange={setBidValue}
                            onBlur={onTextFieldBlur}
                            type="number"
                        />
                        <AddCircleOutlineIcon
                            color={bidIncreaseState ? 'primary' : 'disabled'}
                            onClick={() => bidIncrease()}
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                    <div className={classes.popAreaBottom}>
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={() => placeBid()}
                            disabled={confirmButtonState}
                        >
                            Confirm max bid {ausDollarFormatter(bidAmountValue)}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
