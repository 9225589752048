import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core/';

const useStyles = makeStyles(() =>
    createStyles({
        ppList: {
            textAlign: 'left',
            paddingLeft: '40px'
        }
    })
);

export default function PrivacyPolicy() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid xs={10} item>
                <div className={classes.ppList}>
                    <Typography variant="h2">Privacy Policy</Typography>
                    <Typography variant="h3">1. Privacy Policy</Typography>
                    <Typography variant="h4">
                        1.1 Our commitment to your privacy
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            William Inglis &amp; Son Ltd (&quot;us&quot;,
                            &quot;we&quot;, or &quot;Inglis&quot;) complies with
                            privacy laws that apply to our business, and seeks
                            to adopt best practice for the management of
                            personal information.
                        </p>
                        <p>
                            This Privacy Policy sets out how we manage personal
                            information practises, including:
                        </p>
                        <ol>
                            <li>
                                The types of personal information we collect and
                                hold;
                            </li>
                            <li>
                                The purposes for our collection, and our use and
                                disclosure of personal information;
                            </li>
                            <li>
                                Your right to access and seek correction of your
                                personal information;
                            </li>
                            <li>
                                How you may complain about privacy matters to
                                us;
                            </li>
                        </ol>
                    </Typography>
                    <Typography variant="h4">
                        1.2 What personal information do we collect and hold?
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            We collect and hold personal information which you
                            have either consented to us receiving or that which
                            is reasonably necessary to fulfil our activities and
                            functions as a business. We will only collect
                            personal information from you directly unless it is
                            both reasonable and fair to receive this from a
                            secondary source.
                        </p>
                        <p>
                            We collect and hold the following kinds of
                            information:
                        </p>
                        <ul>
                            <li>
                                Personal details - such as your name, postal
                                and/or email addresses, and telephone numbers.
                                In specific circumstances we may also collect a
                                person&quot;s age.
                            </li>
                            <li>
                                Details of horses currently owned, bred, raised,
                                sold, purchased, including the horse&quot;s
                                pedigree.
                            </li>
                            <li>
                                Details of your participation in any of our
                                auctions or sales.
                            </li>
                            <li>
                                Any prizes you have received or won in
                                connection with any of our activities, including
                                social media competitions or events sponsored or
                                hosted by us.
                            </li>
                            <li>
                                Your stated or likely preferences or any
                                feedback or comments. for example whether you
                                may be interested in participating in events,
                                activities or promotions hosted or sponsored by
                                us.
                            </li>
                        </ul>
                        <p>
                            Inglis is a member of, or signatory to, various
                            bodies and codes of conduct which impose rules on
                            the welfare and sale of bloodstock. This includes
                            Racing New South Wales&quot; code of practice for
                            Thoroughbred Bloodstock Auctions.
                        </p>
                        <p>
                            From time to time, these rules may require us to
                            collect additional personal information to those
                            listed above, and may require us to verify your
                            identity by collecting copies of your identity
                            documents. In the event such identity documents
                            contain &quot;sensitive information&quot; (for
                            example race, religion, or medical information),
                            this sensitive information will only be used to
                            fulfil our obligations under such rules and no other
                            purpose. Similarly where personal information
                            provided to us includes a &quot;government
                            identifier&quot; (such as a licence number on a
                            driver&quot;s licence), we will only use or disclose
                            such identifiers where it is reasonably necessary to
                            verify your identity, or as required or permitted by
                            law.
                        </p>
                        <p>
                            Where possible, sensitive information will not be
                            collected and will otherwise be deleted where
                            practicable once any obligations under any rules
                            have been fulfilled.
                        </p>
                        <p>
                            Depending on your dealings with us, we may collect
                            and hold other information. For example, payment or
                            account information that you may use when purchasing
                            goods from us.
                        </p>
                        <p>
                            Generally you can choose to remain anonymous when
                            dealing with us but not where this is impracticable
                            or any rules, a law, or a Court provide otherwise.
                        </p>
                    </Typography>
                    <Typography variant="h4">
                        1.3 How do we collect and hold your information?
                    </Typography>
                    <Typography variant="body1">
                        <p>We collect personal information from you when:</p>
                        <ul>
                            <li>
                                You provide your details to us. For example by
                                filling in a form to participate in an auction
                                or sale of a thoroughbred, or to verify your
                                identity;
                            </li>
                            <li>You communicate with us online;</li>
                            <li>
                                You participate in our competitions, promotions,
                                testimonial, surveys or other offerings;
                            </li>
                            <li>
                                You deal with us in another way involving a need
                                for personal information to be provided such as
                                when you make a purchase, inquiry, or bring a
                                claim against us.
                            </li>
                        </ul>
                        <p>
                            We may also collect personal information about you
                            by using data from other sources and analysing this
                            data together with information we already hold about
                            you to learn more about your likely preferences and
                            interests. When you visit our websites, social media
                            pages or our other online presence, we may collect
                            information about you using technology, for example
                            &quot;cookies&quot;.
                        </p>
                        <p>
                            Personal information we hold is generally stored in
                            computer systems. These may be operated by us or by
                            our service providers. In all cases, we have
                            rigorous information security requirements aimed at
                            eliminating risks of unauthorised access to, and
                            loss, misuse or wrongful alteration of, personal
                            information.
                        </p>
                    </Typography>
                    <Typography variant="h4">
                        1.4 Why we collect, use, hold and disclose your personal
                        information
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            We primary collect, use and hold your personal
                            information to facilitate our business activities in
                            relation to the sale, purchase, auction of
                            thoroughbred horses, services related to these
                            activities, and to improve on the services and
                            delivery of services we offer.
                        </p>
                        <p>For example:</p>
                        <ul>
                            <li>
                                From time to time your personal details may need
                                to be passed on to third party companies and
                                consultants who perform parts of our service
                                delivery, such as couriers, technical partners
                                (providing us services such as email, web
                                hosting, data storage and backup), email
                                marketing companies and legal or other
                                professional consultants, some of whom may be
                                located outside of Australia.
                            </li>
                            <li>
                                To assist in investigating your complaints or
                                enquiries.
                            </li>
                            <li>
                                To announce winners of competitions or prizes,
                                and previous winners of the same;
                            </li>
                            <li>
                                To report any information to a relevant body
                                when required to do so under a relevant rule
                                (such as reporting the details of a buyer and
                                seller to Racing NSW under their Code of
                                Practice).
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant="h4">
                        1.5 Security and destruction of data
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            We take all reasonable steps to store your personal
                            information securely. Personal information is
                            destroyed securely when no longer required by us.
                        </p>
                    </Typography>
                    <Typography variant="h4">
                        1.6 Sharing of your personal information overseas
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            When holding your personal information, we store our
                            data with backup and hosting services located within
                            Australia. In the event the services we use change
                            so that your personal information would no longer be
                            held in Australia we will seek your consent except
                            where the recipient country has equivalent (or
                            greater) privacy laws and protections to that of
                            Australia and that would apply to your personal
                            information.
                        </p>
                    </Typography>
                    <Typography variant="h4">
                        1.7 Access and correction of your personal information
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            If you wish to access or have an enquiry about your
                            personal information, or how we handle privacy
                            matters generally, please contact our Privacy
                            Officer by:
                        </p>
                        <p>
                            <strong>
                                <strong>
                                    Email:[privacyofficer@inglis.com.au]
                                </strong>
                            </strong>
                        </p>
                        <p>
                            <strong>
                                <strong>Post:</strong>
                            </strong>
                            Attn: Deane Jacobs
                        </p>
                        <p>Riverside Stables - Sydney</p>
                        <p>155 Governor Macquarie Drive,</p>
                        <p>Warwick Farm NSW 2170</p>
                        <p>
                            We may require proof of identity before giving you
                            access to your personal information. We may charge a
                            reasonable fee for giving access to your personal
                            information if your request requires substantial
                            effort on our part.
                        </p>
                        <p>
                            Should you need to correct any personal information
                            please contact our Privacy Officer on the above
                            details.
                        </p>
                    </Typography>
                    <Typography variant="h4">1.8 Complaints</Typography>
                    <Typography variant="body1">
                        <p>
                            If you wish to complain about the privacy rules that
                            bind us, please contact our Privacy Officer in
                            writing using the above details. We may request
                            further details from you so we may adequately
                            understand or address your concerns. We may also
                            discuss your complaint with our staff, our service
                            providers or our professional advisors as
                            appropriate.
                        </p>
                        <p>
                            Our Privacy Officer will investigate the matter and
                            attempt to resolve it in a timely way. Our Privacy
                            Officer will inform you in writing about the outcome
                            of the investigation. If our Privacy Officer does
                            not resolve your complaint to your satisfaction and
                            no other complaint resolution procedures are agreed
                            or required by law, our Privacy Officer will inform
                            you that your complaint may be referred to the
                            Privacy Commissioner for further investigation and
                            will provide you with the Commissioner&quot;s
                            contact details.
                        </p>
                    </Typography>
                    <Typography variant="h4">1.9 Updates</Typography>
                    <Typography variant="body1">
                        <p>
                            This privacy policy is current at the date below. We
                            may change it periodically and provide notice of the
                            changes on our website:
                            <a href="http://www.inglis.com.au/">
                                www.inglis.com.au{' '}
                            </a>
                        </p>
                    </Typography>
                    <Typography variant="h4">
                        1.10 Other privacy terms and limits of this policy
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            Depending on your dealings with us, there may be
                            additional privacy notices and terms relevant to
                            you. Such additional terms are contained within our
                            various contractual documents, such as our auction
                            terms.
                        </p>
                    </Typography>
                    <Typography variant="h4">1.11 More information</Typography>
                    <Typography variant="body1">
                        <p>
                            More information about privacy law and privacy
                            principles is available from the Office of the
                            Australian Information Commissioner (OAIC) at
                            www.oaic.gov.au.
                            <br />
                            <br />
                        </p>

                        <p>Last update of this policy: [January] 2020.</p>
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
}
