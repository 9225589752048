import { Auth } from 'aws-amplify';

const getUser = async () => {
    const currentUser = await Auth.currentUserInfo();
    return currentUser;
};

export const getUserDebtorId = async () => {
    let userDebtId = null;
    const userData = await getUser();
    if (userData) {
        userDebtId = userData.attributes['custom:active_debtor_id'];
    }
    return userDebtId;
};
