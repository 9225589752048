import React, { useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import { API, Auth } from 'aws-amplify';
import { Grid, Typography, Toolbar } from '@material-ui/core/';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ApolloProvider, useQuery, useSubscription } from '@apollo/client';
import * as CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';
import {
    LotBiddingInfo,
    LotBiddingResult,
    BiddingLimit
} from '../../types/inglisTypes';
import { ReactComponent as HorsePlaceHolder } from '../../icons/horsePlaceholder.svg';
import { ARDEXCLOUD_IMAGE_BASEURL } from '../../utls/constants';
import {
    getSaleLotStatusesQuery,
    onBidPlacedQuery,
    getBiddingLimit
} from '../../queries/gqlQueries';
import { epochToDate, dateFormat } from '../../utls/formatHelper';
import { CreateAppSyncApolloClient } from '../../services/AppSyncApolloClient';
import { globalConfig } from '../../globalConfig';
import { getUserDebtorId } from '../../utls/userHelpers';
import dummyData from '../../utls/dummyDashboardData.json';
import { OpenForEntryDashboardComponent } from '../helperComponents/openForEntryDashboard';
import { BiddingLimitDashboardComponent } from '../helperComponents/biddingLimitDashboard';
import { mapLotStatus } from '../../utls/mapper';
import { UserBidsOnCurrentSaleDashboardComponent } from '../helperComponents/userBidsOnCurrentSaleDashboard';
import { BidNowPopOver } from '../helperComponents/bidNowPopOver';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingRight: '15px',
            '& .MuiDataGrid-root': {
                border: 'none',
                borderTop: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer',
                backgroundColor: 'white',
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            '& .MuiDataGrid-columnsContainer': {
                borderBottom: `1px solid ${theme.palette.grey[600]}`
            },
            '& .catalogue-header': {
                borderBottom: `1px solid ${theme.palette.grey[600]}`,
                fontSize: '14px',
                padding: '0px',
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontFamily: 'MetropolisRegular',
                    fontWeight: 700,
                    color: theme.palette.secondary.main
                }
            },
            '& .catalogue-table-rows': {
                fontSize: '14px',
                color: theme.palette.grey[900],
                '& .MuiDataGrid-cell': {
                    border: 'none',
                    textAlign: 'left',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 400
                }
            },
            [theme.breakpoints.down('sm')]: {
                order: '2',
                paddingRight: '0px'
            }
        },
        dashHeaderBanner: {
            backgroundColor: theme.palette.grey[100],
            height: '104px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '0',
            padding: '0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                height: '104px'
            }
        },
        headerHeading: {
            color: theme.palette.secondary.main,
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px'
            }
        },
        subHeaderHeading: {
            color: theme.palette.secondary.main,
            marginBottom: '10px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '2px',
                paddingLeft: theme.spacing(2)
            }
        },
        headerDivs: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        dashHeaderTextZone: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '1920px',
            '& h2': {
                padding: theme.spacing(0, 3)
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                '& h2': {
                    paddingLeft: theme.spacing(0, 3)
                }
            }
        },
        borderContent: {
            border: '1px solid #C2C2BA',
            width: '25px',
            height: '20px',
            textAlign: 'center'
        },
        headerSecondaryTextZone: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[700]
        },
        catalogueContainer: {
            padding: theme.spacing(13, 3, 0, 3),
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                padding: '104px 0px 0px 0px'
            }
        },
        mobileLotBidsZone: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                backgroundColor: 'white',
                justifyContent: 'space-around',
                flexDirection: 'column',
                paddingBottom: '15px',
                borderBottom: `1px solid ${theme.palette.grey[200]}`
            }
        },
        mobileLotBidsList: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                padding: '25px 0px 25px 0px',
                justifyContent: 'space-evenly',
                fontSize: '14px',
                '& div': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }
            }
        },
        mobilePaginator: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'space-evenly',
                height: '56px',
                alignItems: 'center',
                position: 'sticky',
                bottom: '0',
                'z-index': '999',
                backgroundColor: 'white',
                width: '100%',
                '& div': {
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            }
        },
        mobilePaginatorShadow: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100%',
                bottom: '58px',
                position: 'sticky',
                boxShadow: '0px 0.5px 15px 1px rgb(0 0 0 / 50%)'
            }
        },
        mobileLotBiddedPaginatorCover: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100%',
                height: '20px',
                position: 'absolute',
                background: 'white',
                bottom: '930px',
                zIndex: 10
            }
        },

        additionalEntriesBar: {
            marginTop: '30px'
        },
        incompleteEntriesBar: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.grey[50],
            height: '60px',
            padding: '0px 20px',
            alignItems: 'center',
            borderLeft: `2px solid ${theme.palette.warning.main}`,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                padding: '15px 20px',
                alignItems: 'flex-start'
            }
        },
        incompleteEntriesTitleText: {
            display: 'flex',
            width: '200px',
            justifyContent: 'space-between'
        },
        biddingLimitZone: {
            paddingLeft: '15px',
            [theme.breakpoints.down('sm')]: {
                order: '1',
                paddingLeft: '0px'
            }
        },
        bidderText: {
            fontSize: '12px !important',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            height: 'fit-content',
            padding: '2px 8px 2px 8px',
            width: '120px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            lineHeight: '15px'
        },
        highestBidder: {
            color: `${theme.palette.success.main} !important`,
            backgroundColor: theme.palette.success.light
        },
        mediumBidder: {
            color: `${theme.palette.warning.main} !important`,
            backgroundColor: theme.palette.warning.light
        },
        lowestBidder: {
            color: `${theme.palette.error.main} !important`,
            backgroundColor: theme.palette.error.light
        },
        mobileBidButtonZone: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: '15%',
            padding: '0px 20px',
            alignItems: 'center'
        },
        mobileBidderText: {
            fontSize: '12px !important',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            height: 'fit-content',
            padding: '2px 8px 2px 8px',
            width: '165px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            lineHeight: '15px'
        }
    })
);

interface CatalogueProps {
    catalogueResults: any;
    saleCode: string;
    saleId: string;
    saleName: string;
    saleOpenForEntries: any;
    goToBiddingRequestApproval: () => void;
}

interface MobileCatalogueProps {
    catalogueResults: any;
    saleCode: string;
    // eslint-disable-next-line react/no-unused-prop-types
    saleId: string;
    handleMutatedData: (data: any) => void;
}

interface SaleOpenForEntry {
    name: string;
    startDate: Date;
    endDate: Date;
    closingDate: Date;
}

interface CurrentSale {
    id: string;
    code: string;
    name: string;
}

export const MainDashboardPage = () => {
    const [currentSale, setCurrentSale] = useState<CurrentSale>({
        id: '',
        code: '',
        name: ''
    });
    const [saleCode, setSaleCode] = useState<string>('');
    const [saleName, setSaleName] = useState<string>('');
    const [saleId, setSaleId] = useState<string>('');
    const [horseResponse, setHorseResponse] = useState<any>({});
    const [salesOpenForEntries, setSalesOpenForEntries] =
        useState<SaleOpenForEntry[]>();
    const [fullHorseLotsCollection, setHorseLots] = useState<any>([]);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        getCurrentSale();
    }, []);

    useEffect(() => {
        if (!saleCode) return;
        getCatalogue(saleCode);
    }, [saleCode]);

    useEffect(() => {
        sessionStorage.setItem(
            'catalogueLength',
            fullHorseLotsCollection.length
        );
    }, [fullHorseLotsCollection]);

    const goToBiddingRequestApproval = () => {
        history.push('/account-details/biddingapproval');
    };

    const getCurrentSale = () => {
        API.get('rest-api', `catalogue/current`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                const sales = response.data;
                const sale = sales.find(
                    (result: any) => result.stage === 'bidding'
                );
                if (sale) {
                    setSaleCode(sale.saleCode);
                    setSaleName(sale.saleName);
                    setSaleId(sale.saleId.toString());
                }
                const entries = sales
                    .filter((result: any) => result.stage === 'entries')
                    .map((result: any) => {
                        const saleEndDate =
                            result.saleVenue === 'Inglis Digital'
                                ? result.biddingClosesUtc
                                : result.saleEndDate;
                        return {
                            name: result.saleName,
                            startDate: new Date(result.saleStartDate),
                            endDate: new Date(saleEndDate),
                            entriesClosingDate: new Date(
                                result.entriesCloseUtc
                            ),
                            saleVenue: result.saleVenue
                        };
                    });
                setSalesOpenForEntries(entries);
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    // alert('Cannot find data. Please try again.');
                }
            });
    };

    const getCatalogue = (saleValue: string) => {
        API.get('rest-api', `catalogue/${saleValue}/lots`, {
            response: true,
            responseType: 'text'
        })
            .then((response) => {
                const horseLots = response.data.lots.map((item: any) => ({
                    id: item.lotNumber,
                    images: item.images,
                    sex: item.horse.sex,
                    category: item.category,
                    name: item.horse.name,
                    sire: item.horse.sireName,
                    dam: item.horse.damName,
                    inFoalTo: '',
                    accountOf: item.vendor.catalogueName,
                    location: ''
                }));
                setSaleCode(response.data.saleCode);
                setHorseResponse(response);
                setHorseLots(horseLots);
            })
            .catch((error) => {
                if (error.message.toString().indexOf('404')) {
                    // alert('Cannot find data. Please try again.');
                }
            });
    };

    const { data, error } = useAsync({ promiseFn: getCurrentUser });
    if (error) return null;
    if (data !== undefined) {
        const apolloClient = CreateAppSyncApolloClient({
            endpoint: globalConfig.graphql_endpoint,
            authenticationType:
                data === true ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        });
        return (
            <div>
                <Toolbar className={classes.dashHeaderBanner}>
                    <div className={classes.dashHeaderTextZone}>
                        <Typography
                            variant="h2"
                            className={classes.headerHeading}
                        >
                            Dashboard
                        </Typography>
                    </div>
                </Toolbar>
                <ApolloProvider client={apolloClient}>
                    <DashBoardPage
                        catalogueResults={fullHorseLotsCollection}
                        saleOpenForEntries={salesOpenForEntries}
                        saleCode={saleCode}
                        saleId={saleId}
                        saleName={saleName}
                        goToBiddingRequestApproval={goToBiddingRequestApproval}
                    />
                </ApolloProvider>
            </div>
        );
    }
    return null;
};

const getCurrentUser = async () => {
    const user = await Auth.currentUserInfo();
    const isAuthenticated = !!user;
    return isAuthenticated;
};

const DashBoardPage = (props: CatalogueProps) => {
    const [updatedHorseCollection, updateHorseCollection] = useState<any>([]);
    const [horseCollection, setHorseCollection] = useState<any>([]);
    const {
        catalogueResults,
        saleCode,
        saleId,
        saleName,
        saleOpenForEntries,
        goToBiddingRequestApproval
    } = props;
    const [userBiddingLimit, setBiddingLimit] = useState<BiddingLimit>({
        commited: 0,
        spent: 0,
        credit_limit: 0
    } as BiddingLimit);
    const [horseBidResultData, setHorseBidResultData] = useState<
        LotBiddingResult[]
    >([]);
    const [bidLimitBar, setBiddingLimitBar] = useState<number>(0);
    const [currentUserId, setCurrentUserId] = useState<string>('');
    const [currentBidderStatus, setCurrentBidderStatus] = useState<string>('');
    const [currentFundsAvailable, setCurrentFundsAvailable] =
        useState<number>(0);

    const classes = useStyles();

    useEffect(() => {
        let userDebtId = null;
        (async () => {
            const getUserDebtorValue = async () => {
                userDebtId = await getUserDebtorId();
            };
            await getUserDebtorValue();
            if (!userDebtId) return;
            setCurrentUserId(
                CryptoJS.enc.Base64.stringify(
                    CryptoJS.MD5(`${saleCode}:${userDebtId}`)
                )
            );
        })();
    }, []);

    useEffect(() => {
        updateHorseCollection(horseCollection);
    }, [horseCollection]);

    useEffect(() => {
        if (horseBidResultData.length > 0) {
            setCurrentBidderStatus('fail');
            if (horseBidResultData[0].lot_reserve_met) {
                if (
                    horseBidResultData[0].lot_leading_bidder === currentUserId
                ) {
                    setCurrentBidderStatus('success');
                } else if (
                    horseBidResultData[0].lot_bidders.includes(currentUserId) &&
                    horseBidResultData[0].lot_leading_bidder !== currentUserId
                ) {
                    setCurrentBidderStatus('medium');
                }
            }
        }
        console.log(currentBidderStatus);
    }, [horseBidResultData]);

    useQuery(getBiddingLimit(saleCode), {
        onCompleted: (data) => {
            const result = data?.getBiddingLimit;
            if (result) {
                const fundsAvailable =
                    parseFloat(result.credit_limit) -
                    parseFloat(result.commited) -
                    parseFloat(result.spent);
                const limitBar = Math.floor(
                    (fundsAvailable / parseFloat(result.credit_limit)) * 100
                );
                setCurrentFundsAvailable(fundsAvailable);
                setBiddingLimit(result);
                setBiddingLimitBar(limitBar);
            }
        }
    });

    useQuery(getSaleLotStatusesQuery(saleCode), {
        onCompleted: (data) => {
            const result = data?.getBiddingInfo?.map((status: LotBiddingInfo) =>
                mapLotStatus(status)
            );
            if (result && catalogueResults?.length > 0) {
                const currentHorseCollection = catalogueResults;
                result.forEach((el: any, index: any) => {
                    Object.assign(currentHorseCollection[index], el);
                });
                setHorseCollection(currentHorseCollection);
            } else {
                setHorseCollection(catalogueResults);
            }
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useSubscription(onBidPlacedQuery(saleCode), {
        onSubscriptionData: (data) => {
            const newStatus = mapLotStatus(
                data.subscriptionData?.data?.onBidPlaced
            );

            if (newStatus) {
                setHorseCollection((prevState: any) => {
                    const result = [...prevState];
                    const index = result.findIndex(
                        (s) =>
                            s.sale_code === newStatus.sale_code &&
                            s.lot_no === newStatus.lot_no
                    );
                    if (index > -1) {
                        result[index] = { ...result[index], ...newStatus };
                    }
                    setHorseBidResultData(result);
                    return result;
                });
            }
        }
    });

    // const rowClickAction = (params: any) => {
    //     history.push(`/lots/${saleId}/${saleCode}/${params.row.id}`, {
    //         catalogueLength: catalogueResults.length
    //     });
    // };

    return (
        <Grid container className={classes.catalogueContainer}>
            <Grid
                item
                xs={12}
                md={8}
                style={{ width: '100%', marginTop: '30px' }}
                className={classes.root}
            >
                <UserBidsOnCurrentSaleDashboardComponent
                    {...{
                        catalogueResults: updatedHorseCollection,
                        saleName,
                        saleCode
                    }}
                />
                {Object.keys(dummyData).length > 0 ? (
                    dummyData.map((item: any) => {
                        return (
                            <MobileCatalogueList
                                catalogueResults={item}
                                saleCode={saleCode}
                                saleId={saleId}
                                handleMutatedData={mapLotStatus}
                            />
                        );
                    })
                ) : (
                    <div />
                )}
                {/* <div className={classes.additionalEntriesBar}>
                    <Typography
                        variant="h3"
                        className={classes.subHeaderHeading}
                    >
                        Incomplete Entries
                    </Typography>
                    <div className={classes.incompleteEntriesBar}>
                        <div className={classes.incompleteEntriesTitleText}>
                            <Typography variant="body2">Ready2Race</Typography>
                            <Typography variant="body1">2 Horses</Typography>
                        </div>
                        <Typography variant="body2" color="primary">
                            Delete
                        </Typography>
                    </div>
                </div> */}
                <div className={classes.additionalEntriesBar}>
                    <Typography
                        variant="h3"
                        className={classes.subHeaderHeading}
                    >
                        Open for Entries
                    </Typography>
                    {saleOpenForEntries &&
                        saleOpenForEntries.map(
                            (saleOpenForEntry: SaleOpenForEntry) => (
                                <OpenForEntryDashboardComponent
                                    {...saleOpenForEntry}
                                />
                            )
                        )}
                </div>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                style={{ width: '100%', marginTop: '30px' }}
                className={classes.biddingLimitZone}
            >
                <BiddingLimitDashboardComponent
                    {...{
                        userBiddingLimit,
                        bidLimitBar,
                        currentFundsAvailable,
                        goToBiddingRequestApproval
                    }}
                />
            </Grid>
        </Grid>
    );
};

const MobileCatalogueList = (props: MobileCatalogueProps) => {
    const classes = useStyles();
    const { catalogueResults, saleCode, handleMutatedData } = props;

    const updateLotDetails = (data: any) => {
        handleMutatedData(data);
    };

    return (
        <div className={classes.mobileLotBidsZone}>
            <div className={classes.mobileLotBidsList}>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 600
                        }}
                    >
                        {catalogueResults.id}
                    </Typography>
                    {catalogueResults.images.length > 0 ? (
                        <div
                            style={{
                                height: '48px',
                                width: '48px',
                                backgroundImage: `url("${ARDEXCLOUD_IMAGE_BASEURL}${catalogueResults.images[0].objectName}")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                height: '48px',
                                width: '48px',
                                margin: 'auto'
                            }}
                        >
                            <HorsePlaceHolder />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        minWidth: '220px',
                        maxWidth: '220px'
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 600
                        }}
                    >
                        {catalogueResults.name}
                    </Typography>
                    <div>
                        <Typography variant="body1">
                            <div>Sire: {catalogueResults.sire}</div>
                            <div>
                                Bid:
                                {Intl.NumberFormat('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                    maximumFractionDigits: 0
                                }).format(catalogueResults.lot_current_bid)}
                            </div>
                            <div>
                                Time Left:{' '}
                                {dateFormat(
                                    epochToDate(
                                        catalogueResults.lot_closing_time
                                    )
                                )}
                            </div>
                        </Typography>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        alignItems: 'flex-end',
                        flex: '0.5'
                    }}
                >
                    <FavoriteBorderIcon color="primary" />
                    <div>
                        <div className={classes.borderContent}>
                            {catalogueResults.sex}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mobileBidButtonZone}>
                <BidNowPopOver
                    saleId={catalogueResults.id}
                    saleCode={saleCode}
                    horseName={catalogueResults.name}
                    handleMutatedData={updateLotDetails}
                />
                <Typography
                    variant="body2"
                    className={`${classes.mobileBidderText} ${classes.highestBidder}`}
                >
                    {catalogueResults.bidStatus}
                </Typography>
            </div>
        </div>
    );
};
