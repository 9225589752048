export const globalConfig = {
    amplify: {
        mandatorySignIn: false,
        region: process.env.REACT_APP_PROJECT_REGION,
        Auth: {
            region: process.env.REACT_APP_PROJECT_REGION,
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        },
        // temp: sample api urls.
        // TODO: use urls from config
        API: {
            endpoints: [
                {
                    name: 'test-api',
                    endpoint:
                        'https://lf5m39bi14.execute-api.ap-southeast-2.amazonaws.com/Test'
                },
                {
                    name: 'rest-api',
                    endpoint: process.env.REACT_APP_REST_API_URL
                },
                {
                    name: 'phys-api',
                    endpoint: process.env.REACT_APP_HTTP_API_PIC_URL
                },
                {
                    name: 'cms-api',
                    endpoint: process.env.REACT_APP_CMS_API_URL
                }
            ]
        },
        Storage: {
            AWSS3: {
                bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME, // REQUIRED -  Amazon S3 bucket name
                region: process.env.REACT_APP_PROJECT_REGION
            }
        }
    },
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    public_graphql_endpoint: process.env.REACT_APP_PUBLIC_GRAPHQL_URL,
    public_graphql_endpoint_api_key:
        process.env.REACT_APP_PUBLIC_GRAPHQL_API_KEY,
    google_maps_api_endpoint: `https://maps.googleapis.com/maps/api/geocode/json?&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
};
