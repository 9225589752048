import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CatalogueTiles } from '../contentComponents/catalogueTiles';

interface PastSalesComponentProps {
    PastSales: any[];
    GotoPastSales: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            textAlign: 'center',
            'z-index': '1'
        },
        pastSaleTileCollection: {
            display: 'flex',
            overflow: 'hidden',
            margin: theme.spacing(2, 0),
            scrollBehavior: 'smooth',
            justifyContent: 'center',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-start',
                overflow: 'auto',
                padding: theme.spacing(0, 5),
                '&::-webkit-scrollbar': {
                    display: 'block'
                }
            }
        },
        pastSales: {
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(3, 0),
            position: 'relative'
        },
        sectionLink: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            textAlign: 'center'
        }
    })
);

export const PastSalesComponent = (props: PastSalesComponentProps) => {
    const classes = useStyles();
    const { PastSales, GotoPastSales } = props;
    return (
        <Grid item xs={12} className={classes.pastSales}>
            <Typography
                className={classes.sectionTitle}
                variant="h2"
                color="secondary"
            >
                Past Sales
            </Typography>
            <div id="pastSalesArea" className={classes.pastSaleTileCollection}>
                {PastSales.slice(0, 4).map((item: any) => {
                    return (
                        <CatalogueTiles
                            saleCode={item.saleCode}
                            saleTitle={item.saleName}
                            saleStartDate={item.biddingCloses}
                            saleEndDate={item.saleEndDate}
                            saleLocation={item.saleVenue}
                            saleImage={item.catalogueImage}
                            tileType="PastSale"
                        />
                    );
                })}
            </div>
            <Typography
                className={classes.sectionLink}
                onClick={() => {
                    GotoPastSales();
                }}
                variant="body2"
            >
                  All Past Sales
            </Typography>
        </Grid>
    );
};
